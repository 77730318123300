import React, { useState, useEffect, useContext } from "react";
import { Form, Col, Row, Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import KendoDropdown from "../../form/KendoDropdown";
import { UserContext } from "core/context/UserContext";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
export default function AdminViewRestoreDeleted835Form(props) {
  const userContext = useContext(UserContext);
  const [payerDataSource, setPayerDataSource] = useState([]);
  const parentCompanyOrganizations = userContext.organizations
    ? userContext.organizations.filter((org) => org.type === "ParentCompany")
    : [];
  const location = useLocation();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedPayer, setSelectedPayer] = useState("");
  const [selectedParentCompany, setSelectedParentCompany] = useState("");
  const [checkNumber, setCheckNumber] = useState("");
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    getPayer();
  }, []);

  async function getPayer() {
    setPayerDataSource([]);
    let parameters = {
      email: userContext.email,
    };
    let accessToken = props.passedProps.auth.getAccessToken();
    let currentRoute = location.pathname;
    let header = {
      Authorization: `Bearer ${accessToken}`,
    };

    let payerResponse = await axios.get(`api/UserProgram/GetAllPayers`, {
      params: parameters,
      headers: header,
    });

    let PayerList = Object.keys(payerResponse.data).map((key) => {
      return {
        label: payerResponse.data[key],
        value: key,
      };
    });

    let sortedPayers = PayerList.sort((p1, p2) =>
      p1.label > p2.label ? 1 : p1.label < p2.label ? -1 : 0
    );

    setPayerDataSource(sortedPayers);
  }

  function validateForm() {
    if (checkNumber == "" && fileName == "") {
      if (startDate == "" || endDate == "") {
        return "Please enter start and end date.";
      }
      var startDateval = new Date(startDate);
      var endDateval = new Date(endDate);
      if (startDateval > endDateval) {
        return "Start date should not greater than end date.";
      }
      var differentTime = endDateval.getTime() - startDateval.getTime();
      var differentDays = differentTime / (1000 * 60 * 60 * 24);
      if (differentDays > 365 * 2) {
        return "Date range should not be more than 2 years.";
      }
      var parentCompany = selectedParentCompany
        ? selectedParentCompany.value
        : null;
      var payer = selectedPayer ? selectedPayer.value : null;
      if (parentCompany == null && payer == null) {
        return "Please select Parent Company or Payer.";
      }
    }
  }

  function handleFormSubmit(e) {
    e.preventDefault();

    let errors = validateForm();
    errors ? alert(errors) : submitForm();
  }

  function submitForm() {
    props.handleFormSubmit({
      checkNumber: checkNumber,
      endDate: endDate == "" ? null : endDate,
      selectedParentCompany: selectedParentCompany
        ? selectedParentCompany.value
        : null,
      selectedPayers: selectedPayer ? selectedPayer.value : null,
      startDate: startDate == "" ? null : startDate,
      fileName: fileName,
    });
  }

  function resetOnClick() {
    clearform();
    props.handleFormReset();
  }

  function clearform() {
    setCheckNumber("");
    setEndDate("");
    setFileName("");
    setStartDate("");
    setSelectedParentCompany("");
    setSelectedPayer("");
  }

  return (
    <Form onSubmit={handleFormSubmit}>
      <Row className="justify-content-start">
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Start Date</Form.Label>
          <Form.Control
            name="startDate"
            as={DatePicker}
            selected={startDate}
            onChange={(e) => {
              setStartDate(e);
            }}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>End Date</Form.Label>
          <Form.Control
            name="endDate"
            as={DatePicker}
            selected={endDate}
            onChange={(e) => {
              setEndDate(e);
            }}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Parent Company</Form.Label>

          <KendoDropdown
            dataSource={parentCompanyOrganizations || []}
            textField="labelNoSuffix"
            idField="value"
            value={selectedParentCompany}
            sortBy="asc"
            onChange={(e) => {
              setSelectedParentCompany(e);
            }}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Payer</Form.Label>

          <KendoDropdown
            dataSource={payerDataSource || []}
            textField="label"
            idField="value"
            value={selectedPayer}
            onChange={(e) => {
              setSelectedPayer(e);
            }}
          />
        </Col>
      </Row>
      <Row className="justify-content-start">
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>File Name</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            onChange={(e) => {
              setFileName(e.target.value);
            }}
            value={fileName}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Check Number</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            onChange={(e) => {
              setCheckNumber(e.target.value);
            }}
            value={checkNumber}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Button className="inline-show-result-button" size="sm" type="submit">
            Search
          </Button>
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Button
            className="inline-show-result-button"
            size="sm"
            type="button"
            onClick={resetOnClick}
          >
            Reset
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
