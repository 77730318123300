import React, { useState, useEffect } from "react";
import { DetailsModal } from "../../grid/DetailsModal";
import ErrorHandler from "../../core/ErrorHandler";

import "font-awesome/css/font-awesome.min.css";

import { useFetchAdminRxDetails } from "./hooks/useFetchAdminRxDetails";
import { useFetchAdminAdditionalRxDetails } from "./hooks/useFetchAdminAdditionalRxDetails";

import RenderDataToGridLayout from "shared/components/renderData/RenderDataToGridLayout";

import DisplayNoDataAvailable from "shared/components/notifications/DisplayNoDataAvailable";

import { LoadingSmallControlSpinner } from "../../layout/LoadingSmallControlSpinner";

import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";

export default function AdminRxLookupDetailPopup(props) {
  const {
    selectedRxNumber,
    selectedClaimId,
    selectedPaymentId,
    show,
    onClose,
  } = props;

  const [error, setError] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);

  let detailsApiParameters = {
    user: "not used",
    claimId: selectedClaimId,
    paymentId: selectedPaymentId,
  };

  useEffect(() => {
    //make3 sure first tab is selected when the modal is first shown
    if (show) setSelectedTab(0);
  }, [show]);

  var { data: rxDetailsData, isLoading: rxDetailsLoading } =
    useFetchAdminRxDetails(detailsApiParameters);

  let additionalDetailsApiParameters = {
    claimId: selectedClaimId,
  };

  var {
    data: rxDetailsAdditionalData,
    isLoading: rxDetailsAdditionalDataLoading,
  } = useFetchAdminAdditionalRxDetails(additionalDetailsApiParameters);

  const handleTabSelect = (e) => {
    setSelectedTab(e.selected);
  };

  return (
    <DetailsModal
      overrideClass="grid-details-modal2"
      title={`Rx ${selectedRxNumber}`}
      show={show}
      handleClose={onClose}
    >
      {(rxDetailsLoading || rxDetailsAdditionalDataLoading) && (
        <LoadingSmallControlSpinner />
      )}
      <ErrorHandler
        error={error}
        onClose={() => {
          setError({});
        }}
      />

      <div className="fee-and-adjustment-detail-summary-wrapper">
        <br />
        {(rxDetailsData && (
          <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
            <TabStripTab title="Details">
              {rxDetailsData ? (
                <RenderDataToGridLayout data={rxDetailsData} />
              ) : (
                <DisplayNoDataAvailable />
              )}
            </TabStripTab>
            <TabStripTab title="Additional Details">
              {rxDetailsAdditionalData ? (
                <RenderDataToGridLayout data={rxDetailsAdditionalData} />
              ) : (
                <DisplayNoDataAvailable />
              )}
            </TabStripTab>
          </TabStrip>
        )) ||
          (!rxDetailsLoading && <DisplayNoDataAvailable />)}{" "}
        {/* if no rxDetailsData is available after making call, show the no data available message */}
      </div>
    </DetailsModal>
  );
}
