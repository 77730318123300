import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { IsNullOrEmpty } from "shared/helpers/NullEmptyHelpers";
import { useQuery } from "@tanstack/react-query";

export function useFetchLayoutLiveChatAccess(isAuthenticated, email) {
  const { oktaAuth } = useOktaAuth();

  const dataKey = "useFetchLayoutLiveChatAccessKey";

  async function GetLiveChatAccess() {
    const accessToken = oktaAuth.getAccessToken();

    var result = await axios
      .get("api/UserProgram/GetLiveChatAccess", {
        params: {},
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw new Error(error);
      });

    return result;
  }

  function IsEnabled() {
    if (!isAuthenticated) return false;

    if (IsNullOrEmpty(email)) return false;

    return true;
  }

  return useQuery({
    queryKey: [dataKey, [email]],
    queryFn: async () => await GetLiveChatAccess(),
    enabled: IsEnabled,
  });
}
