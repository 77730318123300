import React, { useContext } from "react";
import MultiSelectDropdown from "../MultiSelectDropdown";

import { UserContext } from "core/context/UserContext";
export default function MemberPayerDropdown(props) {
  const { onChange, customPayerList = null } = props;
  const userContext = useContext(UserContext);

  return (
    userContext.payers && (
      <MultiSelectDropdown
        options={customPayerList ? customPayerList : userContext.payers}
        onChange={onChange}
        selectAllOption
        filterable
        onLoadAllSelected
      />
    )
  );
}
