import React, { useContext } from "react";
import { setWithExpiry } from "shared/utils/localStorageAccess";

import { UserContext } from "core/context/UserContext";
import OrganizationDropdown from "./OrganizationDropdown/OrganizationDropdown";

export function Organization(props) {
  const userContext = useContext(UserContext);

  function getContent() {
    return (
      userContext.currentOrganization && (
        <React.Fragment>
          <i className="fa fa-building" />
          {userContext.currentOrganization.label}
        </React.Fragment>
      )
    );
  }

  const content = getContent();

  function getOrganizationName() {
    if (userContext.organizations && userContext.currentOrganization) {
      //NOTE: We shouldn't need to see what the userType (as defined from Okta) is,
      //we should just base this on the email address/permissions from Admin itself
      return (
        <OrganizationDropdown
          organizations={userContext.organizations}
          onChange={(organization) => {
            //set the localstorage value for the selected organization - it will expire in 1 hour
            setWithExpiry("rme-userConfig", organization, 3600000);

            userContext.onCurrentOrganizationChange(organization);
          }}
          selectedOrganization={userContext.currentOrganization}
        >
          {content}
        </OrganizationDropdown>
      );
    } else return null;
  }

  return getOrganizationName();
}
