import axios from "axios";
import React, { useState, useEffect, useContext } from "react";

import { ExportBatchHistoryForm } from "./ExportBatchHistoryForm";
import { ExportBatchHistoryGrid } from "./ExportBatchHistoryGrid";

import { UserContext } from "core/context/UserContext";

import ErrorHandler from "../../core/ErrorHandler";
import { LoadingSpinner } from "../../layout/LoadingSpinner";
import { IsNullOrWhitespace } from "shared/helpers/StringHelpers";
import { StringToDate } from "shared/helpers/DateHelpers";
import { ParentCompanyDisplayElement } from "./ParentCompanyDisplayElement";

export function ExportBatchHistory(props) {
  const { passedProps } = props;
  const user = passedProps.user.email;
  const userConfig = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});

  const [submittedFormData, setSubmittedFormData] = useState(null);
  const [mainGridData, setMainGridData] = useState([]);

  function handleFormSubmit(e) {
    setIsLoading(true);
    setSubmittedFormData(e);
  }

  useEffect(() => {
    getMainGridData();
  }, [submittedFormData]);

  function getMainGridData() {
    if (submittedFormData) {
      try {
        let accessToken = passedProps.auth.getAccessToken();

        let parameters = {
          user: user,
          fromDate: submittedFormData.fromDate
            ? submittedFormData.fromDate.toLocaleDateString()
            : "",
          toDate: submittedFormData.toDate
            ? submittedFormData.toDate.toLocaleDateString()
            : "",
          batchId: submittedFormData.batchId,
          batchStatus: submittedFormData.batchStatus,
          ParentCompanyId:
            passedProps.parentCompanySetByGroupUser &&
            passedProps.parentCompanySetByGroupUser.rpid
              ? passedProps.parentCompanySetByGroupUser.rpid
              : userConfig.currentOrganization.value,
        };

        axios
          .get("api/RemittanceExport/GetRemittanceBatchHistory", {
            params: parameters,
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then(presentGridData)
          .catch(onFailure);
      } catch (exception) {
        onFailure(exception);
      }
    }
  }

  function presentGridData(response) {
    setIsLoading(false);

    if (response.data.length > 0) {
      let dataWithCleanedDates = response.data.map((t) => {
        return {
          ...t,
          batchRequestDate: IsNullOrWhitespace(t.batchRequestDate)
            ? ""
            : StringToDate(t.batchRequestDate).toLocaleString(),
          batchCreationDate: IsNullOrWhitespace(t.batchCreationDate)
            ? ""
            : StringToDate(t.batchCreationDate).toLocaleString(),
        };
      });

      setMainGridData(dataWithCleanedDates);
    }
  }

  const onFailure = (error) => {
    setIsLoading(false);

    if (error && error.response) {
      switch (error.response.config.url) {
        case "api/RemittanceExport/Get":
          setError(error);
          break;
        case "api/RemittanceExport/CreateNewRequestForAllUnexpectedRemittances":
          setError(error);
          break;
        case "api/RemittanceExport/GetFileByBatchId":
          setError(error.response);
          break;
        default:
          break;
      }
    } else {
      setError();
    }
  };

  return (
    <div className="feature-tab" id="request-exports">
      <LoadingSpinner
        isDataLoading={isLoading}
        controlsName={"ExportBatchHistory"}
      />
      <ErrorHandler
        error={error}
        onClose={() => {
          setError({});
        }}
      />

      {passedProps.parentCompanySetByGroupUser &&
      passedProps.parentCompanySetByGroupUser.parentCompanyName
        ? ParentCompanyDisplayElement(
            passedProps.parentCompanySetByGroupUser.parentCompanyName,
            () => passedProps.resetValuesSelectedByGroupUserCallback()
          )
        : null}

      <ExportBatchHistoryForm handleFormSubmit={handleFormSubmit} />
      <ExportBatchHistoryGrid
        data={mainGridData}
        user={user}
        getAccessToken={() => passedProps.auth.getAccessToken()}
        onFailure={onFailure}
        setErrorFromParent={setError}
        setParentIsLoading={(isLoading) => setIsLoading(isLoading)}
        getMainGridDataFromParent={getMainGridData}
      />
    </div>
  );
}
