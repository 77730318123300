import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { LoadingSpinner } from "./../../layout/LoadingSpinner";
import ErrorHandler from "./../../core/ErrorHandler";
import { Row, Col, Form, Button } from "react-bootstrap";
import SingleSelectDropdown from "../../SingleSelectDropdown";
import "./ContactAdmin.css";
import SharedContactAdminGrid from "./SharedContactAdminGrid";
import { constants } from "./ContactAdminConstants";
import { GridColumn } from "@progress/kendo-react-grid";
import { UserContext } from "core/context/UserContext";
import {
  DefaultColumnWidth,
  DefaultBooleanOrShortWidthColumnWidth,
  DefaultNoFilterColumnWidth,
} from "../../grid/Kendo/KendoGridAndColumnConstants";

export default function ContactAdmin(props) {
  const { passedProps } = props;
  const userEmail = passedProps.user.email;
  const [superAdmin, setSuperAdmin] = useState(null);
  const [groupOptions, setGroupOptions] = useState([]);
  const [rpidOptions, setRpidOptions] = useState([]);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState(props.columns ? props.columns : []);
  const [featureTiers, setFeatureTiers] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [isGroupChecked, setIsGroupChecked] = useState(false);
  const [isRpidChecked, setIsRpidChecked] = useState(false);
  const DATA_ITEM_KEY = "contactID";

  const [error, setError] = useState({});
  const navigate = useNavigate();

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedRpid, setSelectedRpid] = useState(null);

  const [currentGroup, setCurrentGroup] = useState(null);
  const [currentRpid, setCurrentRpid] = useState(null);

  const CustomActionCell = (props) => (
    <CustomCellCheckBox {...props} color={"#015CAB"} />
  );

  const user = useContext(UserContext);

  useEffect(() => {
    getIsSuperAdmin();
  }, []);

  useEffect(() => {
    GetUserParentCompanies();
    GetUserGroups();
  }, [superAdmin]);

  async function GetUserGroups() {
    const accessToken = await passedProps.auth.getAccessToken();
    let userGroups = await axios.get("api/UserProgram/GetUserGroups", {
      params: {
        email: userEmail,
      },
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    setGroupOptions(formatDictionaryToArray(userGroups.data));
  }

  async function GetAdminContacts() {
    setIsLoading(true);
    var id = isRpidChecked ? selectedRpid : selectedGroup;
    if (id == "") return;
    const accessToken = await passedProps.auth.getAccessToken();

    let parameters = {
      id: isRpidChecked ? selectedRpid : selectedGroup,
      type: isRpidChecked ? "RPID" : "Group",
    };

    console.log(parameters);

    let adminContacts = await axios.get("api/UserProgram/GetAdminContacts", {
      params: parameters,
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    setData(adminContacts.data);
    setGridData(adminContacts.data);

    if (isRpidChecked) {
      setCurrentGroup(null);
      setCurrentRpid(selectedRpid);
    } else {
      setCurrentGroup(selectedGroup);
      setCurrentRpid(null);
    }

    GetRmeFeatureTiers();
    setIsLoading(false);
  }

  async function GetRmeFeatureTiers() {
    var id = isRpidChecked ? selectedRpid : selectedGroup;
    if (id == "") return;
    const accessToken = await passedProps.auth.getAccessToken();
    let featureTiers = await axios.get(
      "api/UserProgram/GetRmeTiersForGroupOrParentCompany",
      {
        params: {
          id: isRpidChecked ? selectedRpid : selectedGroup,
          type: isRpidChecked ? "ParentCompany" : "Group",
        },
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    setFeatureTiers(featureTiers.data);
  }

  async function GetUserParentCompanies() {
    if (superAdmin == null) return;

    const accessToken = await passedProps.auth.getAccessToken();
    let userGroups = await axios.get("api/UserProgram/GetUserParentCompanies", {
      params: {
        email: userEmail,
      },
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    setRpidOptions(formatDictionaryToArray(userGroups.data));
  }

  function formatDictionaryToArray(data) {
    return Object.keys(data).map((key) => {
      return {
        label: data[key],
        value: key,
      };
    });
  }
  async function getIsSuperAdmin() {
    const accessToken = await passedProps.auth.getAccessToken();
    let parameters = {
      email: userEmail,
    };
    let userResponse = await axios.get(`api/UserProgram/GetSuperUser`, {
      params: parameters,
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    setSuperAdmin(userResponse.data);
    if (!userResponse.data.isSuperUser) {
      navigate("/");
    }

    if (userResponse.data.contactType == "GCT") {
      setIsGroupChecked(true);
    }

    if (
      userResponse.data.contactType == "PCT" ||
      userResponse.data.contactType == "MCT"
    ) {
      setIsRpidChecked(true);
    }
  }

  const DropDownRender = () => {
    if (isGroupChecked) {
      return (
        <SingleSelectDropdown
          options={groupOptions}
          defaultSelected={groupOptions[1]}
          onChange={(selectedGroup) => setSelectedGroup(selectedGroup)}
        />
      );
    }

    if (isRpidChecked) {
      return (
        <SingleSelectDropdown
          options={rpidOptions}
          defaultSelected={rpidOptions[1]}
          onChange={(selectedRPID) => setSelectedRpid(selectedRPID)}
        />
      );
    }
  };

  const RenderCheckbox = () => {
    if (superAdmin == null) return;
    if (superAdmin.contactType === "GCT") {
      return (
        <Form.Check
          onChange={(e) => onTypeChange(e)}
          custom="true"
          type="radio"
          id="ReportTypeDR-1"
          name="Type"
          label="Group"
          value="group"
          inline
          checked={true}
        />
      );
    } else if (
      superAdmin.contactType === "PCT" ||
      superAdmin.contactType === "MCT"
    ) {
      return (
        <Form.Check
          onChange={(e) => onTypeChange(e)}
          custom="true"
          type="radio"
          id="ReportTypeDR-2"
          name="Type"
          label="RPID"
          value="rpid"
          inline
          checked={true}
        />
      );
    } else {
      return (
        <React.Fragment>
          <Form.Check
            onChange={(e) => onTypeChange(e)}
            custom="true"
            type="radio"
            id="ReportTypeDR-1"
            name="Type"
            label="Group"
            value="group"
            inline
          />
          <Form.Check
            onChange={(e) => onTypeChange(e)}
            custom="true"
            type="radio"
            id="ReportTypeDR-2"
            name="Type"
            label="RPID"
            value="rpid"
            inline
          />
        </React.Fragment>
      );
    }
  };

  function onTypeChange(e) {
    if (e.target.value === "rpid") {
      setIsRpidChecked(e.target.checked);
      setIsGroupChecked(!e.target.checked);
    }
    if (e.target.value === "group") {
      setIsGroupChecked(e.target.checked);
      setIsRpidChecked(!e.target.checked);
    }
  }

  function setGridData(data) {
    let rows = data;
    const columns = [];
    if (rows.length > 0) {
      const headerProps = constants.headerProps;

      Object.keys(rows[0]).map((key) => {
        return columns.push({
          accessor: key,
          title: headerProps[key].title,
          type: headerProps[key].type,
          hidden: headerProps[key].hidden,
        });
      });
      setError({});
      console.log(columns);
      setColumns(columns);
    } else {
      setError({ status: 201, Message: "No data found for given parameters" });
    }
  }

  const UpdateDataSource = (contactID, fieldName, check) => {
    // Self property update or Self object update
    let processedData = data.map((value) => {
      let newObject = value;
      if (value.contactID === contactID) {
        if (fieldName === "metric") {
          newObject.metric = check;
        }
        if (fieldName === "rec") {
          newObject.rec = check;
        }
        if (fieldName === "metricPremium") {
          newObject.metricPremium = check;
        }
      }
      return newObject;
    });
    setGridData(processedData);
  };

  const CustomCellCheckBox = (props) => {
    let columnName = props.field;
    switch (columnName) {
      case "rec":
        return (
          <td>
            <input
              type="checkbox"
              className="action-cell"
              data-testid={"action-rec-" + props.id}
              onChange={(e) => {
                UpdateDataSource(
                  props.dataItem.contactID,
                  "rec",
                  e.currentTarget.checked
                );
              }}
              checked={props.dataItem.rec}
              disabled={!featureTiers.rec}
              title={
                featureTiers.rec
                  ? ""
                  : "There are no pharmacies with a Rec 2.0 subscription."
              }
            ></input>
          </td>
        );
      case "metric":
        return (
          <td>
            <input
              type="checkbox"
              className="action-cell"
              data-testid={"action-metric" + props.id}
              onChange={(e) => {
                UpdateDataSource(
                  props.dataItem.contactID,
                  "metric",
                  e.currentTarget.checked
                );
              }}
              checked={props.dataItem.metric}
              disabled={!featureTiers.metric}
              title={
                featureTiers.metric
                  ? ""
                  : "There are no pharmacies with a Metric 2.0 subscription."
              }
            ></input>
          </td>
        );
      case "metricPremium":
        return (
          <td>
            <input
              type="checkbox"
              className="action-cell"
              data-testid={"action-metricPremium" + props.id}
              onChange={(e) => {
                UpdateDataSource(
                  props.dataItem.contactID,
                  "metricPremium",
                  e.currentTarget.checked
                );
              }}
              checked={props.dataItem.metricPremium}
              disabled={!featureTiers.metricPremium}
              title={
                featureTiers.metricPremium
                  ? ""
                  : "There are no pharmacies with a Metric Premium 2.0 subscription."
              }
            ></input>
          </td>
        );
      default:
        return (
          <td
            {...props.tdProps}
            colSpan={1}
            style={{
              color: props.color,
            }}
          ></td>
        );
    }
  };

  function CreateGridColumn(column) {
    let columnWidth = DefaultColumnWidth();

    if (column.accessor === "contactType") {
      columnWidth = DefaultBooleanOrShortWidthColumnWidth();
    }

    let filterType = "text";
    if (column.type === "money" || column.type === "number") {
      filterType = "numeric";
    }

    if (column.hidden) {
      return;
    }
    if (column.type === "custom") {
      columnWidth = DefaultNoFilterColumnWidth();
      return (
        <GridColumn
          key={column.accessor}
          field={column.accessor}
          title={column.title}
          width={columnWidth}
          filterable={false}
          cells={{
            data: CustomActionCell,
          }}
        />
      );
    } else {
      return (
        <GridColumn
          key={column.accessor + "Column"}
          filter={filterType}
          field={column.accessor}
          title={column.title}
          filterable={true}
          width={columnWidth}
        />
      );
    }
  }

  const Spacer = (prop) => {
    return <div style={{ height: prop.height }}></div>;
  };

  async function GenerateOktaAccountsForListOfContacts(contacts) {
    const accessToken = await passedProps.auth.getAccessToken();

    try {
      let parentCompanyOrGroupType = "N/A";
      let selectedParentCompanyOrGroupId = "";
      let parentCompanyOrGroupName = "";

      if (currentGroup !== null) {
        parentCompanyOrGroupType = "Group";
        let entity = groupOptions.filter((p) => p.value == currentGroup)[0];
        selectedParentCompanyOrGroupId = currentGroup;
        parentCompanyOrGroupName = entity.label;
      } else if (currentRpid !== null) {
        parentCompanyOrGroupType = "ParentCompany";
        let entity = rpidOptions.filter((p) => p.value == currentRpid)[0];
        selectedParentCompanyOrGroupId = currentRpid;
        parentCompanyOrGroupName = entity.label;
      }

      await axios.post(
        "api/UserProgram/GenerateOktaAccountsAndGrantRmeAccessForContacts",
        {
          contacts: contacts,
          superUserEmail: userEmail,
          ParentCompanyOrGroupName: parentCompanyOrGroupName,
          ParentCompanyOrGroupId: selectedParentCompanyOrGroupId,
          ParentCompanyOrGroupType: parentCompanyOrGroupType,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      setError({ status: 200, Message: "Grant access success!" });
    } catch (e) {
      console.log(e);
      setError({
        status: 201,
        Message:
          "Unable to grant access to these contacts, please notify Net-Rx team and try again later",
      });
    }

    setIsLoading(false);
  }

  function checkForPctUsers(contacts) {
    var pctUsersWithARecFeature = contacts.filter(
      (x) => x.contactType === "PCT" && (x.metric || x.metricPremium || x.rec)
    );

    if (pctUsersWithARecFeature.length > 0) {
      return window.confirm(
        "The selected PCT users will receive permissions for all pharmacies within this parent company. Would you like to proceed?"
      );
    } else {
      return true;
    }
  }

  function checkForUsersWithBothEliteAndEssential(contacts) {
    var checkForUsersWithBothEliteAndEssential = contacts.filter(
      (x) => x.metric && x.metricPremium
    );

    if (
      checkForUsersWithBothEliteAndEssential &&
      checkForUsersWithBothEliteAndEssential.length > 0
    ) {
      alert(
        "Contacts cannot be granted both Metric Essential and Metric Elite at the same time. Please review your selections and try again."
      );
      return false;
    } else {
      return true;
    }
    // var pctUsersWithARecFeature = contacts.filter(x => x.contactType === "PCT"
    //     && (x.metric || x.metricPremium || x.rec));

    // if (pctUsersWithARecFeature.length > 0) {
    //     return window.confirm("The selected PCT users will receive permissions for all pharmacies within this parent company. Would you like to proceed?");
    // }
    // else {
    //     return true;
    // }
  }

  async function handleGrantAccess() {
    let continueWithContactGenerationBecausePctUsersAreOkay =
      checkForPctUsers(data);

    let continueWithContactGenerationBecauseNoUsersHaveBothEliteAndEssential =
      checkForUsersWithBothEliteAndEssential(data);

    if (
      continueWithContactGenerationBecausePctUsersAreOkay &&
      continueWithContactGenerationBecauseNoUsersHaveBothEliteAndEssential
    ) {
      // alert("Granting access to contacts. Please wait for the success message.");

      setIsLoading(true);
      await GenerateOktaAccountsForListOfContacts(data);
    }
  }

  return (
    <div className="feature">
      <LoadingSpinner isDataLoading={isLoading} controlsName={"ContactAdmin"} />
      <ErrorHandler
        error={error}
        onClose={() => {
          setError({});
        }}
      />
      <Row className="justify-content-start">
        <Col md="auto">{RenderCheckbox()}</Col>
        <Col md="auto">{DropDownRender()}</Col>
        <Col md="auto">
          <Button
            className="show-result-button"
            size="sm"
            type="button"
            onClick={() => GetAdminContacts()}
          >
            Show Results
          </Button>
        </Col>
      </Row>
      <Spacer height="1rem" />
      <Row className="tight-grid multi-line-filter">
        <div className="justify-content-md-left">
          <SharedContactAdminGrid data={data} DATA_ITEM_KEY={DATA_ITEM_KEY}>
            {columns.length > 0
              ? columns.map((singleColumn) => CreateGridColumn(singleColumn))
              : null}
          </SharedContactAdminGrid>
        </div>
      </Row>
      <Spacer height="1rem" />
      <Row className="justify-content-md-left">
        <Col md="auto">
          <Button
            className="show-result-button"
            size="sm"
            type="button"
            onClick={async () => await handleGrantAccess()}
          >
            Grant Access
          </Button>
        </Col>
      </Row>
    </div>
  );
}
