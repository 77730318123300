import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "react-bootstrap";

import { AdminExportBatchHistoryForm } from "./AdminExportBatchHistoryForm";
import { AdminExportBatchHistoryGrid } from "./AdminExportBatchHistoryGrid";
import { AdminExportBatchDetailsHistoryGrid } from "./AdminExportBatchDetailsHistoryGrid";

import { UserContext } from "core/context/UserContext";
import Accordion from "../../Accordion";

import ErrorHandler from "../../core/ErrorHandler";
import { LoadingSpinner } from "../../layout/LoadingSpinner";
import { IsNullOrWhitespace } from "shared/helpers/StringHelpers";
import { StringToDate } from "shared/helpers/DateHelpers";

import { DetailsModal } from "../../grid/DetailsModal";
import { ZIndexContext } from "@progress/kendo-react-common";

export function AdminExportBatchHistory(props) {
  const { passedProps } = props;
  const user = passedProps.user.email;
  const userConfig = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});

  const [submittedFormData, setSubmittedFormData] = useState(null);
  const [mainGridData, setMainGridData] = useState([]);
  const [batchDetailsGridData, setBatchDetailsGridData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  function handleFormSubmit(e) {
    setIsLoading(true);
    setSubmittedFormData(e);
  }

  function handleDetailsModalClose() {
    setShowModal(false);
  }

  useEffect(() => {
    getMainGridData();
  }, [submittedFormData]);

  const sendDetailsToParent = async (detailsData) => {
    setBatchDetailsGridData(detailsData);
    setShowModal(true);
  };

  const sendSelectablestoParent = async (selectablesData) => {
    try {
      setIsLoading(true);

      let arrayOfBatchIds = selectablesData.map((t) => parseInt(t));

      let parameters = {
        user: user,
        batchIds: arrayOfBatchIds,
      };

      let accessToken = passedProps.auth.getAccessToken();

      await axios
        .post("api/RemittanceExport/DeleteBatches", parameters, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(getMainGridData())
        .catch(onFailure);
    } catch (e) {
      onFailure(e);
    }
  };

  function getMainGridData() {
    if (submittedFormData) {
      try {
        let accessToken = passedProps.auth.getAccessToken();

        let parameters = {
          user: user,
          fromDate: submittedFormData.fromDate
            ? submittedFormData.fromDate.toLocaleDateString()
            : "",
          toDate: submittedFormData.toDate
            ? submittedFormData.toDate.toLocaleDateString()
            : "",
          batchId: submittedFormData.batchId,
          batchStatus: submittedFormData.batchStatus,
          parentCompanyId:
            submittedFormData.selectedParentCompany === "" ||
            submittedFormData.selectedParentCompany === null
              ? null
              : submittedFormData.selectedParentCompany.value,
        };

        axios
          .get("api/RemittanceExport/GetRemittanceBatchHistory", {
            params: parameters,
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then(presentGridData)
          .catch(onFailure);
      } catch (exception) {
        onFailure(exception);
      }
    }
  }

  function presentGridData(response) {
    setIsLoading(false);

    if (response.data.length > 0) {
      let dataWithCleanedDates = response.data.map((t) => {
        return {
          ...t,
          batchRequestDate: IsNullOrWhitespace(t.batchRequestDate)
            ? ""
            : StringToDate(t.batchRequestDate).toLocaleString(),
          batchCreationDate: IsNullOrWhitespace(t.batchCreationDate)
            ? ""
            : StringToDate(t.batchCreationDate).toLocaleString(),
        };
      });

      setMainGridData(dataWithCleanedDates);
    } else {
      setMainGridData([]);
      setError({ status: 201, Message: "No data found for given parameters" });
    }
  }

  function onFailure(error) {
    setIsLoading(false);

    if (error.response) {
      switch (error.response.config.url) {
        case "api/RemittanceExport/Get":
          setError(error);
          break;
        case "api/RemittanceExport/CreateNewRequestForAllUnexpectedRemittances":
          setError(error);
          break;
        case "api/RemittanceExport/GetFileByBatchId":
          setError(error);
          break;
        case "api/RemittanceExport/GetRemittanceBatchHistory":
          setError(error);
          break;
        case "api/RemittanceExport/GetBatchDetails":
          setError(error);
          break;
        case "api/RemittanceExport/DeleteBatches":
          setError(error);
          break;
        default:
          break;
      }
    } else {
      console.log(error);
    }
  }

  let batchDetailsGridWrapper = (
    <Row>
      <Col className="tight-grid multi-line-filter">
        <AdminExportBatchDetailsHistoryGrid
          data={batchDetailsGridData}
          user={user}
          getAccessToken={() => passedProps.auth.getAccessToken()}
          onFailure={() => onFailure()}
          setParentIsLoading={(isLoading) => setIsLoading(isLoading)}
        />
      </Col>
    </Row>
  );

  return (
    <React.Fragment>
      <div className="feature" id="admin-request-exports">
        <LoadingSpinner
          isDataLoading={isLoading}
          controlsName={"ExportBatchHistory"}
        />
        <ErrorHandler
          error={error}
          onClose={() => {
            setError({});
          }}
        />

        <Accordion defaultExpanded label="Search & Filter">
          <Row>
            <Col className="higher-zindex-filters">
              <AdminExportBatchHistoryForm
                handleFormSubmit={handleFormSubmit}
              />
            </Col>
          </Row>
        </Accordion>

        <AdminExportBatchHistoryGrid
          data={mainGridData}
          user={user}
          getAccessToken={() => passedProps.auth.getAccessToken()}
          onFailure={() => onFailure()}
          setParentIsLoading={(isLoading) => setIsLoading(isLoading)}
          sendDetailsToParentCallback={sendDetailsToParent}
          sendSelectablesToParentCallback={sendSelectablestoParent}
        />

        {/* <Row>
          <Col className="tight-grid multi-line-filter">
            {batchDetailsGridData.length > 0 ? batchDetailsGridWrapper : ""}
          </Col>
        </Row> */}
      </div>

      <DetailsModal
        title="Batch Details"
        show={showModal}
        handleClose={() => handleDetailsModalClose()}
      >
        <ZIndexContext.Provider value={10003}>
          {batchDetailsGridWrapper}
        </ZIndexContext.Provider>
      </DetailsModal>
    </React.Fragment>
  );
}
