import React from "react";
import { useNavigate } from "react-router-dom";
import { Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";

import { UserContextWrapper } from "core/context/UserContext";
import { ConstantsContextWrapper } from "core/context/ConstantsContext";
//import { setWithExpiry } from "./utils/localStorageAccess";
import RMERouter from "./components/router/RMERouter";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

//import { IsUnrestrictedEraRoute } from "./constants/EraPaths";

//NOTE: Is this used?
function onAuthRequired({ history }) {
  history.push("/login");
}

let appInsights = null;
export default function App(props) {
  //NOTE: TO FIND these values, they are in the .env files, but really they're sourced from the Okta website
  //I don't think I have access to this for now, but for now it should be alright I think (as long as the values in .env are correct)
  let oktaIssuer = process.env.REACT_APP_OKTADomain + "/oauth2/default";
  // console.log(oktaIssuer);

  //NOTE: I think we might need to store this somewhere on the deployed environment? Figure this out eventually
  let oktaClientId = process.env.REACT_APP_OKTAClientID;
  // console.log(oktaClientId);

  const constructedOktaAuth = new OktaAuth({
    issuer: oktaIssuer,
    clientId: oktaClientId,
    // redirectUri: window.location.origin + '/login/callback'
    // redirectUri: "http://localhost:3000/testfile"
    redirectUri: window.location.origin + "/implicit/callback",
  });

  const navigate = useNavigate();

  // const location = useLocation();

  const constructedRestoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri, window.location.origin), {
      replace: true,
    });
  };

  //default option values for react query client
  //will behave as per the defaults unless overridden
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60 * 3, // 3 minutes - if the data is older than this, it will be refetched in the background
        cacheTime: 1000 * 60 * 5, // 5 minutes - making this longer that staletime so if data older than staletime it can refetch silenty in the background, while still using the cache
        retry: (failureCount, error) => {
          //console.log("error", error);

          //by default, do not retry on timeout errors as this would cause the UI to hang for long time periods
          if (error && error.code == 408) {
            return false; // Do not retry on timeout errors
          }
          return failureCount < 1; // Retry up to 2 times for other errors
        },
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <UserContextWrapper>
        <ConstantsContextWrapper>
          <Security
            oktaAuth={constructedOktaAuth}
            restoreOriginalUri={constructedRestoreOriginalUri}
            issuer={oktaIssuer}
            client_id={process.env.REACT_APP_OKTAClientID}
            onAuthRequired={onAuthRequired}
            pkce={true}
          >
            <RMERouter appInsights={appInsights} />
          </Security>
        </ConstantsContextWrapper>
      </UserContextWrapper>
    </QueryClientProvider>
  );
}
