import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";

import { useQuery } from "@tanstack/react-query";

export function useFetchAdminRxDetails(parameters) {
  const { oktaAuth } = useOktaAuth();

  const dataKey = "FetchAdminRxDetailsResult";

  async function GetAdminRxDetailsApiResult() {
    //alert("GetAdminRxDetailsApiResult");
    const accessToken = oktaAuth.getAccessToken();

    //console.log(parameters);

    var result = await axios
      .get("api/AdminRxLookup/GetRxDetailResult", {
        params: parameters,
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw new Error(error);
      });

    return result;
  }

  return useQuery({
    queryKey: [dataKey, parameters],
    queryFn: async () => await GetAdminRxDetailsApiResult(),
  });
}
