import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import "./UploadDataTab.css";

import Accordion from "../../Accordion";
import ErrorHandler from "./../../core/ErrorHandler";
import { LoadingSpinner } from "./../../layout/LoadingSpinner";
import { Row, Col } from "react-bootstrap";

import DataTable from "../../grid/DataTable";
import { UserContext } from "core/context/UserContext";

import UploadDataTabStep1Panel from "./UploadDataTabStep1Panel";
import UploadDataTabStep2Panel from "./UploadDataTabStep2Panel";
import UploadDataTabStep3Panel from "./UploadDataTabStep3Panel";
import { usePrevious } from "../../hooks/usePrevious";

export function UploadDataTab(props) {
  const { passedProps } = props;
  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});

  const [data, setData] = useState([]);
  const prevOrganization = usePrevious(userContext.currentOrganization);

  const columns = [
    {
      accessor: "dataTemplateType",
      title: "Data Template",
      showTotal: false,
      fixedLeft: false,
    },
    {
      accessor: "fileName",
      title: "File Name",
      showTotal: false,
      fixedLeft: false,
    },
    {
      accessor: "uploadDate",
      type: "date",
      title: "Date Uploaded",
      showTotal: false,
      fixedLeft: false,
    },
  ];

  //This gets the file upload history on page load OR orgnization change
  useEffect(() => {
    if (
      prevOrganization !== userContext.currentOrganization &&
      userContext.email
    ) {
      refreshFileUploadHistoryGrid();
    }
  }, [userContext]);

  function refreshFileUploadHistoryGrid() {
    setIsLoading(true);
    // console.log(auth);
    console.log(props);
    console.log(passedProps);

    try {
      let accessToken = passedProps.auth.getAccessToken();
      console.log(accessToken);
      axios
        .get(`api/BYOD/GetFileUploadHistory`, {
          params: {
            userEmailAddress: userContext.email,
            organization: userContext.currentOrganization.value,
          },
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(onSuccess)
        .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
    }

    // passedProps.auth.getAccessToken()
    //     .then(accessToken => {
    //         axios.get(`api/BYOD/GetFileUploadHistory`, {
    //             params: {
    //                 userEmailAddress: userContext.email,
    //                 organization: userContext.currentOrganization.value

    //             },
    //             headers: { Authorization: `Bearer ${accessToken}` }
    //         })
    //             .then(onSuccess)
    //             .catch(onFailure)
    //     })
    //     .catch(onFailure)
  }

  function passExceptionFromStep3PanelToTab(exception) {
    onFailure(exception);
  }

  function onSuccess(response) {
    switch (response.config.url) {
      case "api/BYOD/GetFileUploadHistory":
        setGridData(response.data);
        break;
      default:
        break;
    }
  }

  function onFailure(error) {
    setIsLoading(false);
    setError(error);
  }

  function setGridData(rows) {
    setData(rows);
    setIsLoading(false);
  }

  const popoverBody = (
    <React.Fragment>
      <p>
        <b>
          Augmented Analytics is a business intelligence tool that allows you to
          pull in our own data and combine it with your existing claims &
          payment data to generate insights specific to your business.
        </b>
      </p>
      <p>
        Simply download the template of your choice (step 1), fill in your data
        (step 2), then upload the completed template (step 3). On the following
        day, you will be able to run the analytics for that data from the Run
        Analytics tab.{" "}
      </p>
      <p>
        We currently have two templates available and welcome feedback on any
        others that would bring you value.
        <br />
        <b>Acquisition Cost Analysis:</b> Identify profit/loss impact, review
        contracts, evaluate wholesaler or supplier
        <br />
        <b>State Price List:</b> Evaluate Medicaid MAC lists/rates or Facility
        Formularies
      </p>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <div className="feature">
        <LoadingSpinner
          isDataLoading={isLoading}
          controlsName={"augmentedAnalyticsUploadDataTab"}
        />
        <ErrorHandler
          error={error}
          onClose={() => {
            setError({});
          }}
        />
        <Accordion
          defaultExpanded
          label="Select and upload a Data Template"
          popoverLabel={"About Augmented Analytics"}
          popoverBody={popoverBody}
        >
          <Row>
            <Col className="bordered-control bordered-control-left">
              <UploadDataTabStep1Panel />
            </Col>
            <Col className="bordered-control">
              <UploadDataTabStep2Panel />
            </Col>
            <Col className="bordered-control bordered-control-right">
              <UploadDataTabStep3Panel
                refreshFileUploadHistoryGrid={refreshFileUploadHistoryGrid}
                passExceptionFromStep3PanelToTab={
                  passExceptionFromStep3PanelToTab
                }
              />
            </Col>
          </Row>
        </Accordion>

        <br />
        <h4>Upload History</h4>

        <DataTable
          columns={columns}
          rows={data}
          id="upload-data-history"
          filterable
          pagination
        />
      </div>
    </React.Fragment>
  );
}
