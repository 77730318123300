import React, { useContext, useState, useEffect, useReducer } from "react";
import { Row, Col } from "react-bootstrap";
import Accordion from "../../Accordion";
import axios from "axios";
import FeeAndAdjustmentResearchForm from "./FeeAndAdjustmentResearchForm";
import { UserContext } from "core/context/UserContext";

import { LoadingSpinner } from "./../../layout/LoadingSpinner";
import ErrorHandler from "./../../core/ErrorHandler";
import ActionCell from "./ActionCell";

import { constants } from "./FeeAndAdjustmentResearchConstants";
import FeeAndAdjustmentDetailsModal from "./FeeAndAdjustmentDetailsModal";
import KendoExcelExporterDirect from "../../export/KendoExcelExporterDirect";
import CsvExportDirect from "../../export/CsvExportDirect";

import { GridColumnMenuCheckbox } from "../../grid/Kendo/ColumnMenuCheckboxFilter";

import { GridColumn } from "@progress/kendo-react-grid";

import { ApplicationInsights } from "../../../../src/components/AppInsights/TelemetryService";

import SharedMainSelectableGrid from "../../grid/Kendo/SharedMainSelectableGrid";
import {
  MoneyCell,
  AggregateMoneyFooterCell,
} from "../../grid/Kendo/CustomKendoGridCells";
import { ZIndexContext } from "@progress/kendo-react-common";

import {
  DefaultColumnWidth,
  DefaultBooleanOrShortWidthColumnWidth,
} from "../../grid/Kendo/KendoGridAndColumnConstants";

export default function FeeAndAdjustmentResearch(props) {
  const { passedProps } = props;
  const userEmail = passedProps.user.email;
  const [submittedFormData, setSubmittedFormData] = useState(null);
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [mainGridData, setMainGridData] = useState([]);
  const [mainGridAggregates, setMainGridAggregates] = useState([]);
  const [mainGridSelectedState, setMainGridSelectedState] = useState({});

  const [showDetails, setShowDetails] = useState(false);
  const [detailsGridData, setDetailsGridData] = useState([]);
  const [detailsFilterGridData, setDetailsFilterGridData] = useState([]);

  const [detailExportData, setDetailExportData] = useState(null);

  const [exportExcelDetails, setExportExcelDetails] = useState(false);

  const userContext = useContext(UserContext);

  const sendAggregatesToParent = (aggregatesFromSharedGrid) => {
    setMainGridAggregates(aggregatesFromSharedGrid);
  };

  function GetExportDetailsDataRowsAndColumns(inputData) {
    let exportColumns = [];
    let exportRows = [];
    let headerProps = constants.detailHeaderProps;

    if (inputData && inputData.length > 0) {
      Object.keys(inputData.details[0]).forEach((key) => {
        exportColumns.push({
          title: headerProps[key].title,
          type: headerProps[key].type,
          accessor: key,
          showTotal: headerProps[key].showTotal || false,
          hidden: headerProps[key].hidden || false,
        });
      });

      inputData.forEach((item) => {
        item.details.forEach((detail) => {
          exportRows.push(detail);
        });
      });
    }

    return {
      columns: exportColumns,
      rows: exportRows,
    };
  }

  function directExportReducer(directExportState, action) {
    let thisAction = action.type.toLowerCase();

    if (thisAction === "clear") {
      return null;
    }

    let thisExportData = null;
    if (action.payload.length > 0) {
      let exportColumns = [];
      let exportRows = [];
      let headerProps = constants.detailHeaderProps;

      let thisData = action.payload;

      Object.keys(thisData[0].details[0]).forEach((key) => {
        exportColumns.push({
          title: headerProps[key].title,
          type: headerProps[key].type,
          accessor: key,
          showTotal: headerProps[key].showTotal || false,
          hidden: headerProps[key].hidden || false,
        });
      });

      thisData.forEach((item) => {
        item.details.forEach((detail) => {
          exportRows.push(detail);
        });
      });

      //if no exportColumns or exportRows  return null
      if (!exportColumns || exportColumns.length === 0) return null;
      if (!exportRows || exportRows.length === 0) return null;
      exportColumns = exportColumns.filter((item) => item.hidden == false);
      thisExportData = {
        columns: exportColumns,
        rows: exportRows,
      };
    }

    return { ExportType: thisAction, ExportData: thisExportData };
  }

  const [directExportState, dispatchDirectExport] = useReducer(
    directExportReducer,
    null
  );

  const sendSelectablesToParent = (selectablesFromSharedGrid) => {
    setMainGridSelectedState(selectablesFromSharedGrid);

    //NOTE: Here we find all of the keys that are selected,
    //make a comma-separated string for those, and then run a report

    var selectableKeys = Object.keys(selectablesFromSharedGrid);

    var getOnlyTrueKeys = selectableKeys.filter(function (key) {
      return selectablesFromSharedGrid[key] === true;
    });

    if (getOnlyTrueKeys.length < 1) {
      alert("No fee codes selected.");

      return null;
    }

    //NOTE: Get all clids that are being ignored
    var allSelectedFeeCodes = "";
    getOnlyTrueKeys.forEach(function (key) {
      allSelectedFeeCodes += key + ",";
    });

    let parameters = {
      user: userEmail,
      fromDate: submittedFormData.fromDate,
      toDate: submittedFormData.toDate,
      locations: submittedFormData.locations,
      payers: submittedFormData.payers,
      checkNumber: submittedFormData.checkNumber,
      status: submittedFormData.remitStatus,
      feeCodes: allSelectedFeeCodes,
    };
    setDetailsFilterGridData(parameters);
    setShowDetails(true);

    // getReport(allSelectedFeeCodes);
  };

  //after excel report ios exported this function is called to set the export excel state back to false
  function DirectExportCompletedCallback() {
    dispatchDirectExport({ type: "clear" });
    //setExportExcelDetails(false);
  }

  const generateReportForSelectables = (
    selectablesFromSharedGrid,
    reportType
  ) => {
    setMainGridSelectedState(selectablesFromSharedGrid);

    //NOTE: Here we find all of the keys that are selected,
    //make a comma-separated string for those, and then run a report

    var selectableKeys = Object.keys(selectablesFromSharedGrid);

    var getOnlyTrueKeys = selectableKeys.filter(function (key) {
      return selectablesFromSharedGrid[key] === true;
    });

    if (getOnlyTrueKeys.length < 1) {
      alert("No reports selected.");

      return null;
    }

    //NOTE: Get all clids that are being ignored
    var allSelectedFeeCodes = "";
    getOnlyTrueKeys.forEach(function (key) {
      allSelectedFeeCodes += key + ",";
    });

    SetDirectExportData(reportType, allSelectedFeeCodes);
  };

  useEffect(() => {
    if (submittedFormData) {
      setMainGridData([]);
      setIsLoading(true);

      let accessToken = passedProps.auth.getAccessToken();

      //alert("Making call, log should start");
      ApplicationInsights.startTrackEvent("FeeAndAdjustment_GetResearch");

      try {
        axios
          .get(`api/FeeAndAdjustment/GetResearch`, {
            params: {
              user: userEmail,
              fromDate: submittedFormData.fromDate,
              toDate: submittedFormData.toDate,
              locations: submittedFormData.locations,
              payers: submittedFormData.payers,
              adjustmentType: submittedFormData.adjustmentType,
              checkNumber: submittedFormData.checkNumber,
              feeCode: submittedFormData.feeCode,
              remittanceStatus: submittedFormData.remitStatus,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then((response) => {
            ApplicationInsights.stopTrackEvent(
              "FeeAndAdjustment_GetResearch",
              null,
              {
                customProp1: "some value",
              }
            );
            //alert("Comnpleted call, log should end");
            onSuccess(response);
          })
          .catch(onFailure);
      } catch (exception) {
        onFailure(exception);
      }
    }
  }, [submittedFormData]);

  const MainGridAggregateMoneyFooterCell = (props) => {
    var options = {
      aggregateData: mainGridAggregates,
    };

    return AggregateMoneyFooterCell(props, options);
  };

  let CustomViewReportCell = (props) => {
    return (
      <td
        {...props.tdProps}
        colSpan={1}
        style={{
          color: "#015CAB",
        }}
      >
        <a
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            let parameters = {
              user: userEmail,
              fromDate: submittedFormData.fromDate,
              toDate: submittedFormData.toDate,
              locations: submittedFormData.locations,
              payers: submittedFormData.payers,
              checkNumber: submittedFormData.checkNumber,
              status: submittedFormData.remitStatus,
              feeCodes: props.dataItem["feeCode"],
            };
            setDetailsFilterGridData(parameters);
            setShowDetails(true);
          }}
        >
          View Report
        </a>
      </td>
    );
  };

  function onSuccess(response) {
    let rows = response.data;
    const columns = [];

    if (rows.length > 0) {
      columns.push({ accessor: "Action", title: "Action", type: "custom" });
      const headerProps = constants.headerProps;

      Object.keys(rows[0]).map((key) => {
        return columns.push({
          accessor: key,
          title: headerProps[key].title,
          type: headerProps[key].type,
          showTotal: headerProps[key].showTotal,
          hidden: headerProps[key].hidden,
        });
      });
      rows.map((row) => {
        row["Action"] = renderActionCell(row["feeCode"]);
      });
      setError({});
    } else {
      setError({ status: 201, Message: "No data found for given parameters" });
    }

    setMainGridData(rows);

    setIsLoading(false);
  }

  function onFailure(error) {
    setIsLoading(false);
    setError(error.response);
  }

  function renderActionCell(feeCode) {
    return <ActionCell feeCode={feeCode} />;
  }

  function getNewRowSelectedValue(rows) {
    if (rows.length > 1) {
      return !rows.every((x) => {
        return x.selected === true;
      });
    }
    return !rows[0].selected;
  }

  function GetProcessedExportDataColumns(sourceColumns) {
    let columns = [];

    if (!sourceColumns) return columns;

    if (sourceColumns.length > 0) {
      columns = sourceColumns.map((value) => {
        let newObject = {
          field: value.accessor,
          title: value.title,
        };

        return newObject;
      });
    }
    return columns;
  }

  async function SetDirectExportData(exportType, feeCodes) {
    try {
      setIsLoading(true);

      let accessToken = passedProps.auth.getAccessToken();

      let parameters = {
        user: userEmail,
        fromDate: submittedFormData.fromDate,
        toDate: submittedFormData.toDate,
        locations: submittedFormData.locations,
        payers: submittedFormData.payers,
        checkNumber: submittedFormData.checkNumber,
        status: submittedFormData.remitStatus,
        feeCodes: feeCodes,
      };

      //NOTE TODO: This is the data fetch for the details, fix this

      let response = await axios.get(`api/FeeAndAdjustment/GetDetails`, {
        params: parameters,
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (response.data.length > 0) {
        dispatchDirectExport({ type: exportType, payload: response.data });
        // setExportExcelDetails(true);
        // setDetailsGridData(response.data);
      } else {
        setError({
          status: 201,
          Message: "No data found for given parameters",
        });
      }
    } catch (e) {
      console.log(e);
      setError(e.response);
    }
    setIsLoading(false);
  }

  function CustomColumnMenu(props) {
    const customDataset = mainGridData;

    return <GridColumnMenuCheckbox {...props} data={customDataset} />;
  }

  return (
    <div className="feature">
      <LoadingSpinner
        isDataLoading={isLoading}
        controlsName={"FeeAndAdjustmentResearch"}
      />
      <ErrorHandler
        error={error}
        onClose={() => {
          setError({});
        }}
      />

      <Accordion defaultExpanded label="Search & Filter">
        <Row>
          <Col className="higher-zindex-filters">
            <FeeAndAdjustmentResearchForm
              handleFormSubmit={setSubmittedFormData}
            />
          </Col>
          {/* <Col>
          <FeatureWidget
            title="Last 30 Days Largest Impact by Type"
            iFrameId="sisense-fee-and-adjustment-frame"
            filters={{}}
            dashboard={process.env.REACT_APP_Dashboard_Adjustments}
            widget={process.env.REACT_APP_Widget_Adjustments}
            settings={{
              showToolbar: false,
              showLeftPane: false,
              showRightPane: false,
            }}
            hidden={false}
          />
        </Col> */}
        </Row>
      </Accordion>

      <Row>
        <Col md={10} className="tight-grid">
          <SharedMainSelectableGrid
            data={mainGridData}
            dataItemKey={"feeCode"}
            aggregateColumnSettings={[{ aggregate: "sum", field: "total" }]}
            sendAggregatesParentCallback={sendAggregatesToParent}
            sendSelectablesParentCallback={sendSelectablesToParent}
            selectableButtonTitle={"Get Reports For Selected Codes"}
            hasGenerateReportOption={true}
            generateReportButtonTitle={"Export Details For Selected Reports"}
            generateReportForSelectablesCallback={generateReportForSelectables}
            exportFileNamePrefix={"Fee&Adjustment"}
          >
            <GridColumn
              key={""}
              field={""}
              title={"View Report"}
              width={DefaultBooleanOrShortWidthColumnWidth()}
              filterable={false}
              sortable={false}
              cells={{
                data: CustomViewReportCell,
              }}
            />
            <GridColumn
              key={"feeCode"}
              field={"feeCode"}
              title={"Fee Code"}
              width={DefaultColumnWidth()}
            />
            <GridColumn
              key={"description"}
              field={"description"}
              title={"Description"}
            />
            <GridColumn
              key={"total"}
              field={"total"}
              title={"Total"}
              width={DefaultColumnWidth()}
              filter={"numeric"}
              cells={{
                data: MoneyCell,
                footerCell: MainGridAggregateMoneyFooterCell,
              }}
              footerCell={MainGridAggregateMoneyFooterCell}
            />
          </SharedMainSelectableGrid>
        </Col>
      </Row>

      {directExportState && directExportState.ExportType == "excel" && (
        <KendoExcelExporterDirect
          data={directExportState.ExportData.rows}
          columns={GetProcessedExportDataColumns(
            directExportState.ExportData.columns
          )}
          exportCompletedCallback={DirectExportCompletedCallback}
          exportFileNamePrefix={"Fee&AdjustmentDetails"}
        ></KendoExcelExporterDirect>
      )}

      {directExportState && directExportState.ExportType == "csv" && (
        <CsvExportDirect
          columns={[]}
          rows={[]}
          custom={directExportState.ExportData}
          exportCompletedCallback={DirectExportCompletedCallback}
          exportFileNamePrefix={"Fee&AdjustmentDetails"}
        />
      )}
      <ZIndexContext.Provider value={10003}>
        <FeeAndAdjustmentDetailsModal
          parameter={detailsFilterGridData}
          auth={props}
          show={showDetails}
          onClose={() => {
            setShowDetails(false);
          }}
        />
      </ZIndexContext.Provider>
    </div>
  );
}
