import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Form, Col, Row, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MultiSelectDropdown from "../../MultiSelectDropdown";
import MemberLocationDropdown from "../../form/MemberLocationDropdown";
import MemberPayerDropdown from "../../form/MemberPayerDropdown";

import {
  SharedFormRow,
  SharedFormColumn,
} from "../../layout/SharedFormElements/SharedFormRowAndColumns";
import FormValidation from "../../form/validation/FormValidation";
import { ProgramSubscriptions } from "../../../core/constants/ProgramSubscriptions";

export function RequestExportsForm(props) {
  const { handleFormSubmit, passedProps } = props;

  //NOTE TODO: Similar to the Date Range Aging tab, this input is intended to get some data for testing
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedPayers, setSelectedPayers] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [remittanceLookup, setRemittanceLookup] = useState("");
  const [dateType, setDateType] = useState("1");
  const [remitStatus, setRemitStatus] = useState("1");
  const [exportStatus, setExportStatus] = useState("3");
  //NOTE: These are temporary date values, remove them later once dev work completes
  // const [fromDate, setFromDate] = useState(new Date(2021, 5, 1));
  // const [toDate, setToDate] = useState(new Date(2021, 5, 15));

  let customMemberLocationList = null;
  if (passedProps.membersSetByGroupUser) {
    customMemberLocationList = passedProps.membersSetByGroupUser;
  }

  let customPayersList = null;
  if (passedProps.payersSetByGroupUser) {
    customPayersList = passedProps.payersSetByGroupUser;
  }

  useEffect(() => {
    if (!loaded && selectedLocations.length > 0) {
      setLoaded(true);
      // submitForm();
    }
  }, [selectedLocations]);

  function validateInputsAndSubmitForm() {
    let parameters = {
      fromDate: fromDate,
      toDate: toDate,
      selectedLocations: selectedLocations,
      selectedPayers: selectedPayers,
      dateType: dateType,
      remittanceLookup: remittanceLookup,
      remitStatus: remitStatus,
      exportStatus: exportStatus,
      requiredFields: ["toDate", "fromDate"],
    };

    let validationIssueMessage = null;
    if (!(remittanceLookup && remittanceLookup.length > 0)) {
      validationIssueMessage = FormValidation(parameters);
    }

    if (validationIssueMessage) {
      alert(validationIssueMessage);
    } else {
      handleFormSubmit(parameters);
    }
  }

  function requestExportsFormSubmit(e) {
    e.preventDefault();

    validateInputsAndSubmitForm();
  }

  return (
    <Form onSubmit={requestExportsFormSubmit}>
      <SharedFormRow>
        <h4>Request Export Remittances</h4>
      </SharedFormRow>
      <SharedFormRow>
        <SharedFormColumn>
          <Form.Label>Start Date</Form.Label>
          <Form.Control
            name="fromDate"
            as={DatePicker}
            selected={fromDate}
            onChange={(date) => {
              setFromDate(date);
            }}
          />
        </SharedFormColumn>
        <SharedFormColumn>
          <Form.Label>End Date</Form.Label>
          <Form.Control
            name="fromDate"
            as={DatePicker}
            selected={toDate}
            onChange={(date) => {
              setToDate(date);
            }}
          />
        </SharedFormColumn>

        <SharedFormColumn>
          <Form.Label>Date Type</Form.Label>
          <Form.Control
            as="select"
            onChange={(e) => {
              setDateType(e.target.value);
            }}
          >
            <option value="1">Check</option>
            <option value="2">Remit</option>
            <option value="3">Archive</option>
            <option value="4">Payment</option>
          </Form.Control>
        </SharedFormColumn>

        <SharedFormColumn>
          <Form.Label>Location (NCPDP)</Form.Label>
          <Form.Control
            as={MemberLocationDropdown}
            onChange={(locations) => setSelectedLocations(locations)}
            programSubscription={ProgramSubscriptions.Rec}
            customMemberLocationList={customMemberLocationList}
          />
        </SharedFormColumn>
        {/* NOTE TODO: The MemberPayerDropdown options need to be customized as well then */}
        <SharedFormColumn>
          <Form.Label>Payer</Form.Label>
          <Form.Control
            as={MemberPayerDropdown}
            onChange={(payers) => setSelectedPayers(payers)}
            customPayerList={customPayersList}
          />
        </SharedFormColumn>

        <SharedFormColumn>
          <Form.Label>Remittance Lookup</Form.Label>
          <Form.Control
            name="remittanceLookup"
            type="text"
            placeholder=""
            onChange={(e) => {
              setRemittanceLookup(e.target.value);
            }}
            value={remittanceLookup}
          />
        </SharedFormColumn>
        <SharedFormColumn>
          <Form.Label>Remit Status</Form.Label>
          <div key="remitStatus">
            <Form.Check
              custom="true"
              type="radio"
              id="remitStatus1"
              name="remitStatus"
              onChange={(event) => setRemitStatus(event.target.value)}
              label="All"
              value="1"
              defaultChecked
            />
            <Form.Check
              custom="true"
              type="radio"
              id="remitStatus2"
              name="remitStatus"
              onChange={(event) => setRemitStatus(event.target.value)}
              label="Pending Remits"
              value="2"
            />
            <Form.Check
              custom="true"
              type="radio"
              id="remitStatus3"
              name="remitStatus"
              onChange={(event) => setRemitStatus(event.target.value)}
              label="Archived Remits"
              value="3"
            />
          </div>
        </SharedFormColumn>
        <SharedFormColumn>
          <Form.Label>Export Status</Form.Label>
          <div key="exportStatus">
            <Form.Check
              custom="true"
              type="radio"
              id="exportStatus1"
              name="exportStatus"
              onChange={(event) => setExportStatus(event.target.value)}
              label="All"
              value="3"
              defaultChecked
            />
            <Form.Check
              custom="true"
              type="radio"
              id="exportStatus2"
              name="exportStatus"
              onChange={(event) => setExportStatus(event.target.value)}
              label="Not Exported"
              value="1"
            />
            <Form.Check
              custom="true"
              type="radio"
              id="exportStatus3"
              name="exportStatus"
              onChange={(event) => setExportStatus(event.target.value)}
              label="Previously Exported"
              value="2"
            />
          </div>
        </SharedFormColumn>
      </SharedFormRow>

      <SharedFormRow>
        <SharedFormColumn>
          <Button className="inline-show-result-button" size="sm" type="submit">
            Show Results
          </Button>
        </SharedFormColumn>
      </SharedFormRow>

      <SharedFormRow>
        <Col>&nbsp;</Col>
      </SharedFormRow>
    </Form>
  );
}

RequestExportsForm.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
};
