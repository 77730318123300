import { IsNullOrEmpty } from "./NullEmptyHelpers";

function GetObjectPropertyValue(input, propertyName, valueIfNotFound = "") {
  if (IsNullOrEmpty(input)) {
    return valueIfNotFound;
  }

  if (IsNullOrEmpty(propertyName)) {
    return valueIfNotFound;
  }

  if (typeof input !== "object") {
    return valueIfNotFound;
  }

  // if the object has the property, return the value, make sure the property is case insensitive
  let keys = Object.keys(input);
  let key = keys.find((k) => k.toLowerCase() === propertyName.toLowerCase());
  if (key) {
    return input[key];
  }

  return valueIfNotFound;
}

export { GetObjectPropertyValue };
