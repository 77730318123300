import { IsNullOrEmpty } from "./NullEmptyHelpers";
function GetCustomError(sourceError) {
  let err = {
    message: IsNullOrEmpty(sourceError.message) ? "" : sourceError.message,
    code: IsNullOrEmpty(sourceError.response.status)
      ? -1
      : sourceError.response.status,
    description: IsNullOrEmpty(sourceError.response.data)
      ? ""
      : sourceError.response.data,
  };

  return err;
}

export { GetCustomError };
