import DatePicker from "react-datepicker";
import React, { useContext, useState, useEffect } from "react";
import {
  Form,
  Col,
  Row,
  Button,
  OverlayTrigger,
  Popover,
  NavDropdown,
} from "react-bootstrap";
import MemberLocationDropdown from "../../form/MemberLocationDropdown";
import MemberPayerDropdown from "../../form/MemberPayerDropdown";
import { constants } from "./CopayAdjustmentManagerConstants";
import FormValidation from "../../form/validation/FormValidation";
import { UserContext } from "core/context/UserContext";

export default function CopayAdjustmentManagerForm(props) {
  const [toDate, setToDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [selectedPayers, setSelectedPayers] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const userContext = useContext(UserContext);

  useEffect(() => {
    const date = new Date();
    setFromDate(new Date(date.getFullYear(), date.getMonth() - 1, 1));
    setToDate(new Date(date.getFullYear(), date.getMonth(), 0));
  }, []);

  useEffect(() => {
    if (!loaded && selectedLocations.length > 0 && selectedPayers.length > 0) {
      setLoaded(true);
      submitForm();
    }
  }, [selectedLocations, selectedPayers, loaded]);

  useEffect(() => {
    setLoaded(false);
  }, [
    userContext.locations,
    userContext.metricLocations,
    userContext.recLocations,
    userContext.isUnrestrictedEraPath,
  ]);

  function handleFormSubmit(event) {
    event.preventDefault();

    submitForm();
  }

  function ExportExcel() {
    props.handleExportExcel();
  }

  function submitForm() {
    let parameters = {
      fromDate: fromDate,
      toDate: toDate,
      selectedLocations: selectedLocations,
      selectedPayers: selectedPayers,
      requiredFields: ["fromDate", "toDate"],
    };

    let validationIssueMessage = FormValidation(parameters);

    if (validationIssueMessage) {
      alert(validationIssueMessage);
    } else {
      // To calculate the time difference of two dates
      const differenceInTime = toDate.getTime() - fromDate.getTime();
      // To calculate the no. of days between two dates
      let differenceInDays = differenceInTime / (1000 * 3600 * 24);
      differenceInDays += 1;

      if (Math.round(differenceInDays) > 31) {
        alert("Please select a date range under 31 days.");
      } else {
        props.handleFormSubmit({
          fromDate: fromDate,
          toDate: toDate,
          payers: selectedPayers.join(","),
          locations: selectedLocations.join(","),
        });
      }
    }
  }

  const popover = <Popover id="popover-basic">{constants.featureInfo}</Popover>;

  return (
    <Form onSubmit={handleFormSubmit}>
      <Row className="justify-content-start">
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Start Date</Form.Label>
          <Form.Control
            name="fromDate"
            as={DatePicker}
            selected={fromDate}
            onChange={(date) => setFromDate(date)}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>End Date</Form.Label>
          <Form.Control
            name="toDate"
            as={DatePicker}
            selected={toDate}
            minDate={fromDate}
            maxDate={new Date()}
            onChange={(date) => setToDate(date)}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Payer</Form.Label>
          <Form.Control
            as={MemberPayerDropdown}
            onChange={(payers) => setSelectedPayers(payers)}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Location (NCPDP)</Form.Label>
          <Form.Control
            as={MemberLocationDropdown}
            onChange={(locations) => setSelectedLocations(locations)}
            programSubscription={"Rec2.0"}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <div className="mb-3 mr-2">
            <Button
              className="inline-show-result-button"
              size="sm"
              type="submit"
            >
              Show Results
            </Button>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={popover}
              rootClose
            >
              <i className="additional-info-popover-icon fa fa-info-circle" />
            </OverlayTrigger>
          </div>
        </Col>
        <Col
          className="d-flex flex-column"
          style={{ padding: `35px 20px 0px 0px` }}
        >
          <Button
            variant="link"
            style={{ "text-align": `right` }}
            type="button"
            onClick={ExportExcel}
          >
            Copay Smoothing Notice (M3P) Download
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
