import DatePicker from "react-datepicker";
import React, { useContext, useState, useEffect } from "react";
import {
  Form,
  Col,
  Row,
  Button,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import MemberLocationDropdown from "../../form/MemberLocationDropdown";
import MemberPayerDropdown from "../../form/MemberPayerDropdown";
import FormValidation from "../../form/validation/FormValidation";
import { UserContext } from "core/context/UserContext";

export default function ReversedClaimsForm(props) {
  const [toDate, setToDate] = useState(new Date());
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedPayers, setSelectedPayers] = useState([]);
  const [dateType, setDateType] = useState("dispense");
  const [loaded, setLoaded] = useState(false);
  const [checkNumber, setCheckNumber] = useState("");
  const [showArchiveRemitsOnly, setShowArchiveRemitsOnly] = useState(false);
  const [disableAll, setDisableAll] = useState(false);

  const userContext = useContext(UserContext);

  useEffect(() => {
    setLoaded(false);
  }, [
    userContext.locations,
    userContext.metricLocations,
    userContext.recLocations,
    userContext.isUnrestrictedEraPath,
  ]);

  useEffect(() => {
    if (!loaded && selectedLocations.length > 0 && selectedPayers.length > 0) {
      setLoaded(true);
      submitForm();
    }
  }, [selectedLocations, selectedPayers]);

  function handleSubmit(event) {
    event.preventDefault();

    submitForm();
  }

  function submitForm() {
    let parameters = {
      fromDate: fromDate,
      toDate: toDate,
      selectedLocations: selectedLocations,
      selectedPayers: selectedPayers,
      checkNumber: checkNumber,
      requiredFields: ["fromDate", "toDate", "checkNumberOrDateRange"],
    };

    let validationIssueMessage = FormValidation(parameters);

    if (validationIssueMessage) {
      alert(validationIssueMessage);
    } else {
      props.handleFormSubmit({
        fromDate: fromDate,
        toDate: toDate,
        locations: selectedLocations.join(","),
        payers: selectedPayers.join(","),
        dateType: dateType,
        checkNumber: checkNumber,
        showArchiveRemitsOnly: showArchiveRemitsOnly,
      });
    }
  }
  function onSuccess(data) {}

  function checkNumberChange(e) {
    setCheckNumber(e.target.value);
    if (e.target.value.length > 0 && !disableAll) {
      setDisableAll(true);
    } else if (!e.target.value || e.target.value.length < 1) {
      setDisableAll(false);
    }
  }

  const popover = (
    <Popover id="popover-basic">Claims are reversals by pharmacy</Popover>
  );
  return (
    <Form onSubmit={handleSubmit}>
      <Row className="justify-content-start">
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Start Date</Form.Label>
          <Form.Control
            name="fromDate"
            as={DatePicker}
            selected={fromDate}
            onChange={(date) => setFromDate(date)}
            disabled={disableAll}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>End Date</Form.Label>
          <Form.Control
            name="toDate"
            as={DatePicker}
            selected={toDate}
            minDate={fromDate}
            maxDate={new Date()}
            onChange={(date) => setToDate(date)}
            disabled={disableAll}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Payer</Form.Label>
          <Form.Control
            as={MemberPayerDropdown}
            onChange={(payers) => setSelectedPayers(payers)}
            disabled={disableAll}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Location (NCPDP)</Form.Label>
          <Form.Control
            as={MemberLocationDropdown}
            onChange={(locations) => setSelectedLocations(locations)}
            disabled={disableAll}
            programSubscription={"Rec2.0"}
          />
        </Col>

        <Col md="auto" className="d-flex flex-column">
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            overlay={popover}
            rootClose
          >
            <i className="inline-show-result-icon additional-info-popover-icon fa fa-info-circle" />
          </OverlayTrigger>
        </Col>

        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Date Type</Form.Label>
          <div key="dateType" className="mb-3 mr-2">
            <Form.Check
              custom="true"
              type="radio"
              id="rcDateType1"
              name="dateType"
              onChange={(event) => setDateType(event.target.value)}
              label="Claim Dispense Date"
              value="dispense"
              defaultChecked
              disabled={disableAll}
            />
            <Form.Check
              custom="true"
              type="radio"
              id="rcDateType2"
              name="dateType"
              onChange={(event) => setDateType(event.target.value)}
              label="Claim Transaction Date"
              value="transaction"
              disabled={disableAll}
            />
            <Form.Check
              custom="true"
              type="radio"
              id="rcDateType3"
              name="dateType"
              onChange={(event) => setDateType(event.target.value)}
              label="Check Date"
              value="check"
              disabled={disableAll}
            />
          </div>
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <div key="dateType" className="mb-3 mt-3 pt-3">
            <Form.Check
              custom="true"
              type="checkbox"
              id={`rcShowArchiveRemitsOnly`}
              name="setShowArchiveRemitsOnly"
              label="Show Archive Remits Only"
              onChange={(event) =>
                setShowArchiveRemitsOnly(event.target.checked)
              }
              disabled={disableAll}
            />
          </div>
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Check Number</Form.Label>
          <Form.Control
            type="text"
            maxLength="60"
            placeholder="Optional"
            onChange={(e) => {
              checkNumberChange(e);
            }}
            value={checkNumber}
          />
        </Col>

        <Col md="auto" className="d-flex flex-column">
          <Button className="inline-show-result-button" size="sm" type="submit">
            Show Results
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
