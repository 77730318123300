import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";

import { useQuery } from "@tanstack/react-query";

import { IsNullOrEmpty } from "shared/helpers/NullEmptyHelpers";

export function useFetchNavbarUserContactType(email) {
  const { oktaAuth } = useOktaAuth();

  const dataKey = "useFetchNavbarUserContactTypeValuesKey";

  async function GetUserContactType() {
    const accessToken = oktaAuth.getAccessToken();

    let params = {
      email: email,
    };

    var result = await axios
      .get("api/UserProgram/GetUserContactType", {
        params: params,
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw new Error(error);
      });

    return result;
  }

  function IsEnabled() {
    if (IsNullOrEmpty(email)) return false;

    return true;
  }

  return useQuery({
    queryKey: [dataKey, email],
    queryFn: async () => await GetUserContactType(),
    enabled: IsEnabled,
  });
}
