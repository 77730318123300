import React, { createContext, useState } from "react";

export const ConstantsContext = createContext(undefined);

export const ConstantsContextWrapper = ({ children }) => {
  const [constants, setConstants] = useState(null);

  return (
    <ConstantsContext.Provider value={{ constants, setConstants }}>
      {children}
    </ConstantsContext.Provider>
  );
};
