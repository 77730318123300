import React, { useContext, useEffect } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { SecureRoute, LoginCallback } from "@okta/okta-react";

import Home from "../pages/Home/Home";
import LoginForm from "../auth/LoginForm";

import { ContactUs } from "../pages/ContactUs";
import { PrivacyPolicy } from "../pages/PrivacyPolicy";
import { TermsAndConditions } from "../pages/TermsAndConditions";
import TotalSalesPaymentSummary from "../pages/TotalSalesPaymentSummary/TotalSalesPaymentSummary";
import TwoYearBusinessTrend from "../pages/TwoYearBusinessTrend/TwoYearBusinessTrend";
import DSOByPayerTracking from "../pages/DSOByPayerTracking/DSOByPayerTracking";
import Aging from "../pages/Aging/Aging";
import RemittanceExporting from "../pages/835RemittanceExporting/RemittanceExporting";
import PriceChange from "../pages/DrugPriceChange/PriceChange";
import Workbench from "../pages/Workbench/Workbench";
import Byod from "../pages/Byod/Byod";
import LocationComparison from "../pages/Benchmarking/LocationComparison";
import RemitReceiptVerification from "../pages/RemitReceiptVerification/RemitRcptVerification";
import ArchiveRemittanceReview from "../pages/ArchiveRemittanceReview/ArchiveRemittanceReview";
import SCCEvaluation from "../pages/SCCEvaluation/SCCEvalution";
import RemittanceLookup from "../pages/RemittanceLookup/RemittanceLookup";
import CopayAdjustmentManager from "../pages/CopayAdjustmentManager/CopayAdjustmentManager";
import FeeAndAdjustmentResearch from "../pages/FeeAndAdjustmentResearch/FeeAndAdjustmentResearch";
import PaymentException from "../pages/PaymentException/PaymentException";
import PayerGERTracker from "../pages/PayerGERTracker/PayerGERTracker";
import PayerMessagingManager from "../pages/PayerMessagingManager/PayerMessagingManager";
import MissingRemittances from "../pages/MissingRemittances/MissingRemittances";
import ManualRemittance from "../pages/ManualRemittance/ManualRemittance";
import AdminRemittanceLookup from "../pages/AdminRemittanceLookup/AdminRemittanceLookup";
import AdminViewRestoreDeleted835 from "../pages/AdminViewRestoreDeleted835/AdminViewRestoreDeleted835";

import AdminRxLookup from "../pages/AdminRxLookup/AdminRxLookup";

import RxLookup from "../pages/RxLookup/RxLookup";
import AdminExportBatchQueueMaintenance from "../pages/AdminExportBatchQueueMaintenance/AdminExportBatchQueueMaintenance";
import AdminMissingRemittance from "../pages/AdminMissingRemittance/AdminMissingRemittance";

import ContactAdmin from "../pages/ContactAdmin/ContactAdmin";

import TutorialVideo from "../pages/Tutorials/TutorialVideo";
//NOTE: This was in the dev branch
import BringYourOwnData from "../pages/Byod/BringYourOwnData";
import ExcelExportDownloads from "../pages/ExcelExportDownloads/ExcelExportDownloads";

import TestFile from "../pages/TestOne/TestFile";

import Layout from "../layout/Layout";

import { UserContext } from "core/context/UserContext";
import NoAccess from "../auth/NoAccess";

import { useParentCompanyLocationValidation } from "../hooks/useParentCompanyLocationValidation";

import { UserTypes } from "core/constants/UserTypes";

import {
  ApplicationInsights,
  SetAuthenticatedUser,
} from "../../../src/components/AppInsights/TelemetryService";

function NotFound() {
  let navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, [navigate]);

  return null;
}

export default function RMERouter(props) {
  const user = useContext(UserContext);
  const { eraOnly, is835RemittanceExportAllowed, IsAllowedRoute } =
    useParentCompanyLocationValidation();

  let navigate = useNavigate();
  let location = useLocation();
  let currentRoute = location.pathname;

  useEffect(() => {
    if (user.email) {
      SetAuthenticatedUser(user.email);
    }
  }, [user.email]);

  //Netx3344 logic for the eraOnly Route guard is here
  //Logic to determine if user is EraOnly and valid routes is in our custom hook:  useParentCompanyLocationValidation
  useEffect(() => {
    // console.log(currentRoute);
    // console.log("it's checking for ERA permissions here");

    // console.log(eraOnly);
    // console.log(is835RemittanceExportAllowed);

    if (!eraOnly) {
      //if user is not an era user and they are on the eraunauithrized route, then we navigate them to the home page
      if (currentRoute.toUpperCase() === "/UNAUTHORIZEDERAPATH") {
        navigate("/");
      }
    } else {
      let isAllowed = IsAllowedRoute(currentRoute);
      //navigate the user to the unauthorizedEraPath if they are not allowed to access the current route
      if (!isAllowed) {
        navigate("/unauthorizedEraPath");
      }
    }

    if (
      is835RemittanceExportAllowed === true &&
      currentRoute.toUpperCase() === "/UNAUTHORIZEDEXPORTPATH"
    ) {
      navigate("/");
    } else if (
      currentRoute === "/835RemittanceExporting" &&
      is835RemittanceExportAllowed === false
    ) {
      navigate("/unauthorizedExportPath");
    }
  }, [
    eraOnly,
    is835RemittanceExportAllowed,
    currentRoute,
    user.currentOrganization,
  ]);

  ApplicationInsights.trackPageView();

  return (
    <Routes>
      <Route path="/login" element={<LoginForm />} />
      <Route
        path="/implicit/callback"
        element={
          <LoginCallback loadingElement={<p>Loading...</p>}></LoginCallback>
        }
      />
      <Route
        exact
        path="/Analytics"
        element={
          <Layout selectedNavbarLabel="Dashboard" featureTitle="Dashboard">
            {user.hasMetricSubscription ? (
              <Byod />
            ) : (
              <NoAccess featureName={"Metric 2.0"}></NoAccess>
            )}
          </Layout>
        }
      />
      <Route
        exact
        path="/TestFile"
        element={
          <Layout>
            <TestFile />
          </Layout>
        }
      />
      <Route exact path="/Tutorial" element={<TutorialVideo />} />
      <Route
        exact
        path="/RxLookup"
        element={
          <Layout
            selectedNavbarLabel="Rx Lookup"
            featureTitle="Rx Lookup"
            pageTitle="Rx Lookup"
          >
            {user.hasRecSubscription ? (
              <RxLookup />
            ) : (
              <NoAccess featureName={"Rec 2.0"}></NoAccess>
            )}
          </Layout>
        }
      />
      <Route
        path="/ExcelExportDownloads"
        element={
          <Layout
            selectedNavbarLabel="Report Queue"
            featureTitle="Report Queue"
          >
            <ExcelExportDownloads />
          </Layout>
        }
      />
      <Route
        exact
        path="/"
        element={
          <Layout selectedNavbarLabel="Dashboard" featureTitle="Home">
            <Home />
          </Layout>
        }
      />

      <Route
        path="/unauthorizedEraPath"
        element={
          <Layout selectedNavbarLabel="" featureTitle="">
            {
              <NoAccess
                displayMessage={
                  "You have selected a feature that has not been enabled for your account. Please contact Net-Rx at 866-336-3879 for more information about this feature."
                }
              ></NoAccess>
            }
          </Layout>
        }
      />

      <Route
        path="/unauthorizedExportPath"
        element={
          <Layout selectedNavbarLabel="" featureTitle="">
            {
              <NoAccess
                displayMessage={
                  "You have selected a feature that has not been enabled for your account. Please contact Net-Rx at 866-336-3879 for more information about this feature."
                }
              ></NoAccess>
            }
          </Layout>
        }
      />

      <Route
        path="/LocationComparison"
        element={
          <Layout
            selectedNavbarLabel="Location Comparison"
            featureTitle="Benchmark"
          >
            {user.hasMetricSubscription ? (
              <LocationComparison />
            ) : (
              <NoAccess featureName={"Metric 2.0"}></NoAccess>
            )}
          </Layout>
        }
      />
      <Route
        path="/AugmentedAnalytics"
        element={
          <Layout
            selectedNavbarLabel="Workbench"
            featureTitle="Augmented Analytics"
          >
            {user.hasMetricSubscription ? (
              <Workbench />
            ) : (
              <NoAccess featureName={"Metric 2.0"}></NoAccess>
            )}
          </Layout>
        }
      />

      <Route
        path="/TotalSales"
        element={
          <Layout
            selectedNavbarLabel="Business Overview"
            featureTitle="Total Sales / Payment Summary"
            pageTitle="Total Sales Payment Summary"
          >
            {user.hasRecSubscription ? (
              <TotalSalesPaymentSummary />
            ) : (
              <NoAccess featureName={"Rec 2.0"}></NoAccess>
            )}
          </Layout>
        }
      />
      <Route
        path="/DSO"
        element={
          <Layout
            selectedNavbarLabel="Payer and Contract Evaluation"
            featureTitle="Day Sales Outstanding by Payer"
            pageTitle="Day Sales Outstanding by Payer"
          >
            {user.hasRecSubscription ? (
              <DSOByPayerTracking />
            ) : (
              <NoAccess featureName={"Rec 2.0"}></NoAccess>
            )}
          </Layout>
        }
      />

      <Route
        path="/Aging"
        element={
          <Layout
            selectedNavbarLabel="Reconciliation and Aging Manager"
            featureTitle="Aging (Outstanding) Payments"
            pageTitle="Aging Outstanding Payments"
          >
            {user.hasRecSubscription ? (
              <Aging />
            ) : (
              <NoAccess featureName={"Rec 2.0"}></NoAccess>
            )}
          </Layout>
        }
      />
      <Route
        path="/835RemittanceExporting"
        element={
          <Layout
            selectedNavbarLabel="835 Remittance Exporting"
            featureTitle="835 Remittance Exporting"
            pageTitle="835 Remittance Exporting"
          >
            {user.hasRecSubscription ? (
              <RemittanceExporting />
            ) : (
              <NoAccess featureName={"Rec 2.0"}></NoAccess>
            )}
          </Layout>
        }
      />
      <Route
        path="/PaymentExceptions"
        element={
          <Layout
            selectedNavbarLabel="Reconciliation and Aging Manager"
            featureTitle="Payment Exceptions"
            pageTitle="Payment Exceptions"
          >
            {user.hasRecSubscription ? (
              <PaymentException />
            ) : (
              <NoAccess featureName={"Rec 2.0"}></NoAccess>
            )}
          </Layout>
        }
      />

      <Route
        path="/PendingRemit"
        element={
          <Layout
            selectedNavbarLabel="Remittance Processing"
            featureTitle="Remittance Receipt Verification"
            pageTitle="Remittance Receipt Verification"
          >
            {user.hasRecSubscription ? (
              <RemitReceiptVerification />
            ) : (
              <NoAccess featureName={"Rec 2.0"}></NoAccess>
            )}
          </Layout>
        }
      />
      <Route
        path="/ArchivedRemit"
        element={
          <Layout
            selectedNavbarLabel="Remittance Processing"
            featureTitle="Archived Remittance Review"
            pageTitle="Archived Remittance Review"
          >
            {user.hasRecSubscription ? (
              <ArchiveRemittanceReview />
            ) : (
              <NoAccess featureName={"Rec 2.0"}></NoAccess>
            )}
          </Layout>
        }
      />
      <Route
        path="/RemitLookUp"
        element={
          <Layout
            selectedNavbarLabel="Remittance Management"
            featureTitle="Remittance Management"
            pageTitle="Remittance Management"
          >
            {user.hasRecSubscription ? (
              <RemittanceLookup />
            ) : (
              <NoAccess featureName={"Rec 2.0"}></NoAccess>
            )}
          </Layout>
        }
      />

      <Route
        path="/MissingRemittances"
        element={
          <Layout
            selectedNavbarLabel="Missing Remittances"
            featureTitle="Report a Missing Remittance"
            pageTitle="Missing Remittances"
          >
            {user.hasRecSubscription ? (
              <MissingRemittances />
            ) : (
              <NoAccess featureName={"Rec 2.0"}></NoAccess>
            )}
          </Layout>
        }
      />

      <Route
        path="/FeeAdjustment"
        element={
          <Layout
            selectedNavbarLabel="Fee and Adjustment Tracker"
            featureTitle="Fee and Adjustment Research"
            pageTitle="Fee/Adjustment Research"
          >
            {user.hasRecSubscription ? (
              <FeeAndAdjustmentResearch />
            ) : (
              <NoAccess featureName={"Rec 2.0"}></NoAccess>
            )}
          </Layout>
        }
      />
      <Route
        path="/CopayAdjustment"
        element={
          <Layout
            selectedNavbarLabel="Copay Adjustment Manager"
            featureTitle="Copay Adjustment Manager"
            pageTitle="Copay Adjustment Manager"
          >
            {user.hasRecSubscription ? (
              <CopayAdjustmentManager />
            ) : (
              <NoAccess featureName={"Rec 2.0"}></NoAccess>
            )}
          </Layout>
        }
      />

      <Route
        path="/ContactAdmin"
        element={
          <Layout
            selectedNavbarLabel="Contact Admin"
            featureTitle="Contact Admin"
          >
            <ContactAdmin />
          </Layout>
        }
      />

      <Route
        path="/AdminRemittanceLookup"
        element={
          <Layout
            selectedNavbarLabel="Remittance Lookup"
            featureTitle="Remittance Lookup"
          >
            <AdminRemittanceLookup />
          </Layout>
        }
      />

      <Route
        path="/AdminViewRestoreDeleted835"
        element={
          <Layout
            selectedNavbarLabel="View/Restore Deleted 835"
            featureTitle="View/Restore Deleted 835"
          >
            <AdminViewRestoreDeleted835 />
          </Layout>
        }
      />

      <Route
        path="/ManualRemittance"
        element={
          <Layout
            selectedNavbarLabel="Manual 835 Remittance Creation"
            featureTitle="Manual Remittance"
            pageTitle="Manual Remittance"
          >
            {user.hasRecSubscription ? (
              <ManualRemittance />
            ) : (
              <NoAccess featureName={"Rec 2.0"}></NoAccess>
            )}
          </Layout>
        }
      />

      <Route
        path="/AdminExportBatchQueueMaintenance"
        element={
          <Layout
            selectedNavbarLabel="Export Batch Queue Maintenance"
            featureTitle="Export Batch Queue Maintenance"
            pageTitle="Export Batch Queue Maintenance"
          >
            {user.contactType == UserTypes.UCT ? (
              <AdminExportBatchQueueMaintenance />
            ) : (
              <NoAccess
                errorMessage={"You do not have access to this page."}
              ></NoAccess>
            )}
          </Layout>
        }
      />

      <Route
        path="/AdminMissingRemittance"
        element={
          <Layout
            selectedNavbarLabel="Missing Remittance"
            featureTitle="Missing Remittance"
            pageTitle="Missing Remittance"
          >
            {user.contactType == UserTypes.UCT ? (
              <AdminMissingRemittance />
            ) : (
              <NoAccess
                errorMessage={"You do not have access to this page."}
              ></NoAccess>
            )}
          </Layout>
        }
      />

      <Route
        path="/AdminRxLookup"
        element={
          <Layout
            selectedNavbarLabel="Admin Rx Lookup"
            featureTitle="Admin Rx Lookup"
            pageTitle="Admin Rx Lookup"
          >
            {user.contactType == UserTypes.UCT ? (
              <AdminRxLookup />
            ) : (
              <NoAccess
                errorMessage={"You do not have access to this page."}
              ></NoAccess>
            )}
          </Layout>
        }
      />

      {/* Catch-all route */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

//NOTE: Okay I think this "SecureRoute" component really only works on react-router-dom v5, and this is on v6 now
const SecureLayout = ({
  component: Component,
  selectedNavbarLabel: selectedNavbarLabel,
  featureTitle: featureTitle,
  ...rest
}) => {
  return (
    <SecureRoute
      {...rest}
      render={(matchProps) => (
        <Layout
          selectedNavbarLabel={selectedNavbarLabel}
          featureTitle={featureTitle}
        >
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};
