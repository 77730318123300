import React, { useContext, useState, useEffect } from "react";
import { Form, Col, Row, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { UserContext } from "core/context/UserContext";
import { set } from "lodash";
import "./ManualRemittance.css";

export default function ClaimEntry(props) {
  // Property to store the form data
  const [script, setScript] = useState("");
  const [dispDate, setDispDate] = useState("");
  const [paidAmount, setPaidAmount] = useState("");
  const [fee, setFee] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [selectedNCPDP, setSelectedNCPDP] = useState("");
  const [pyId, setPyId] = useState(0);

  const { entryOptionProp, enableAddClaimEntryButton, claimDetail } = props;
  const userContext = useContext(UserContext);

  const [ncpdpFullDataSource, setNcpdpFullDataSource] = useState(
    userContext.recLocations
  );
  const [ncpdpDataSource, setNcpdpDataSource] = useState([]);

  useEffect(() => {
    if (
      entryOptionProp.selectedLocations &&
      entryOptionProp.selectedLocations.length > 0
    ) {
      if (userContext.recLocations) {
        let selectedNcpdp = userContext.recLocations.filter((item) =>
          entryOptionProp.selectedLocations.includes(item.value)
        );
        setNcpdpDataSource(selectedNcpdp);
        setSelectedNCPDP("");
      }
    }
  }, [entryOptionProp.selectedLocations]);

  useEffect(() => {
    if (userContext.recLocations) {
      setNcpdpDataSource(userContext.recLocations);
      setSelectedNCPDP("");
    }
  }, [userContext.recLocations]);

  useEffect(() => {
    if (claimDetail) {
      setScript(claimDetail.script);
      setDispDate(new Date(claimDetail.dispensedDate));
      setPaidAmount(claimDetail.paidAmount);
      setFee(claimDetail.transactionFee);
      if (claimDetail.ncpdp) {
        userContext.recLocations.forEach((element) => {
          if (element.label.indexOf(claimDetail.ncpdp) > 0) {
            setSelectedNCPDP(element);
          }
        });
      }
      //setSelectedNCPDP(claimDetail.ncpdp);

      setPyId(claimDetail.id);
      if (entryOptionProp.includePatientName) {
        setFirstName(claimDetail.firstName);
        setMiddleName(claimDetail.middleName);
        setLastName(claimDetail.lastName);
      }

      if (entryOptionProp.includePaymenttype) {
        setPaymentType(claimDetail.paymentType);
      }
    }
  }, [claimDetail]);

  useEffect(() => {
    if (props.clearAll) {
      ClearForm();
    }
  }, [props.clearAll]);

  function ClearForm() {
    setScript("");
    setDispDate("");
    setPaidAmount("");
    setFee("");
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setPaymentType(0);
    setSelectedNCPDP("");
    setPyId(0);
  }

  function validateForm() {
    if (selectedNCPDP == "") {
      return "Location (NCPDP) is a required field.";
    }

    if (script == "") {
      return "Script is a required field.";
    }

    if (paidAmount == "") {
      return "Paid amount is a required field.";
    }
    if (dispDate == "") {
      return "Dispensed Date is a required field.";
    }
  }

  function handleFormSubmit(e) {
    e.preventDefault();

    let errors = validateForm();
    errors ? alert(errors) : submitForm();
  }

  function submitForm() {
    props.handleFormSubmit({
      id: pyId,
      paidAmount: paidAmount,
      dispDate: dispDate,
      script: script,
      fee: fee == "" || fee == "NaN" ? 0 : fee,
      selectedLocations: selectedNCPDP.value,
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      paymentType: paymentType == "" || paymentType == "0" ? null : paymentType,
    });
  }

  return (
    <Form onSubmit={handleFormSubmit}>
      <Row className="justify-content-start">
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Script</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            onChange={(e) => {
              setScript(e.target.value);
            }}
            value={script}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Paid Amount</Form.Label>
          <Form.Control
            type="number"
            onBlur={(e) => {
              setPaidAmount(parseFloat(paidAmount).toFixed(2));
            }}
            onChange={(e) => {
              setPaidAmount(e.target.value);
            }}
            value={paidAmount}
          />
        </Col>

        <Col md="auto" className="d-flex flex-column z-Index-10">
          <Form.Label>Disp Dt</Form.Label>
          <Form.Control
            name="dispDate"
            as={DatePicker}
            selected={dispDate}
            onChange={(e) => {
              setDispDate(e);
            }}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>NCPDP</Form.Label>
          <Form.Control
            as={ComboBox}
            data={ncpdpDataSource}
            id="value"
            textField="label"
            allowCustom={false}
            style={{
              //width: "30em",
              textSizeAdjust: "small",
            }}
            value={selectedNCPDP}
            onChange={(e) => {
              if (e.target.value) {
                setSelectedNCPDP(e.target.value);
              } else setSelectedNCPDP(null);
            }}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Fee</Form.Label>
          <Form.Control
            type="number"
            onBlur={(e) => {
              setFee(parseFloat(fee).toFixed(2));
            }}
            onChange={(e) => {
              setFee(e.target.value);
            }}
            value={fee}
          />
        </Col>
      </Row>
      <Row className="justify-content-start">
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Pt.First Name</Form.Label>
          <Form.Control
            disabled={!entryOptionProp.includePatientName}
            type="text"
            placeholder=""
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
            value={firstName}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Pt.Middle Name</Form.Label>
          <Form.Control
            disabled={!entryOptionProp.includePatientName}
            type="text"
            placeholder=""
            onChange={(e) => {
              setMiddleName(e.target.value);
            }}
            value={middleName}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Pt.Last Name</Form.Label>
          <Form.Control
            disabled={!entryOptionProp.includePatientName}
            type="text"
            placeholder=""
            onChange={(e) => {
              setLastName(e.target.value);
            }}
            value={lastName}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Payment Type</Form.Label>
          <Form.Select
            area-label="Select"
            disabled={!entryOptionProp.includePaymenttype}
            value={paymentType}
            onChange={(e) => {
              setPaymentType(e.target.value);
            }}
          >
            <option value="0"></option>

            <option value="1">1</option>
            <option value="2">2</option>
            <option value="4">4</option>
            <option value="19">19</option>
            <option value="22">22</option>
          </Form.Select>
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Button
            className="inline-show-result-button"
            size="sm"
            type="submit"
            disabled={!enableAddClaimEntryButton}
          >
            {pyId > 0 ? "Submit" : "Add"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
