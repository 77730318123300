import "./RemittanceLookup.css";
import axios from "axios";
import Accordion from "../../Accordion";
import React, { useState, useEffect, useContext, useReducer } from "react";
import ErrorHandler from "./../../core/ErrorHandler";
import RemittanceLookupForm from "./RemittanceLookupForm";

import { LoadingSpinner } from "./../../layout/LoadingSpinner";
import { Row, Col } from "react-bootstrap";

import { constants } from "./RemittanceLookupConstants";

import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Calendar } from "@progress/kendo-react-dateinputs";
import { Popover } from "@progress/kendo-react-tooltip";
import { process } from "@progress/kendo-data-query";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";

import { GetDataWithCleanedDates } from "shared/helpers/DateHelpers.js";

import SharedMainSelectableGridNewImplementation, {
  AddGridButton,
} from "../../grid/Kendo/SharedMainSelectableGridNewImplementation";

import SingleCheckExcelExportWrapper from "../../export/SingleCheckExcelExportWrapper";
import SingleCheckPdfExportWrapper from "../../export/SingleCheckPdfExportWrapper";
import {
  MoneyCell,
  AggregateMoneyFooterCell,
} from "../../grid/Kendo/CustomKendoGridCells";
import { UserContext } from "core/context/UserContext";

import { HistoryModal, EditConfirmationModal } from "./HistoryModal.js";
import { ZIndexContext } from "@progress/kendo-react-common";

import {
  DefaultColumnWidth,
  DefaultWideColumnWidth,
  DefaultBooleanOrShortWidthColumnWidth,
} from "../../grid/Kendo/KendoGridAndColumnConstants";

import "./RemittanceLookupButtonsReducedHeight.css";
import KendoExcelExporterDirect from "../../export/KendoExcelExporterDirect";
import CsvExportDirect from "../../export/CsvExportDirect";

import { useNavigate } from "react-router-dom";

export default function RemittanceLookup(props) {
  const { passedProps } = props;
  const user = passedProps.user.email;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});

  const [submittedFormData, setSubmittedFormData] = useState(null);
  const [mainGridData, setMainGridData] = useState([]);
  const [
    mainGridMoneyColumnAggregateSettings,
    setMainGridMoneyColumnAggregateSettings,
  ] = useState([]);
  const [mainGridAggregates, setMainGridAggregates] = useState([]);
  const [mainGridSelectedState, setMainGridSelectedState] = useState([]);

  const INITIAL_HISTORY_GRID_DATA_STATE = { skip: 0, take: 50 };
  const [checkHistoryGridData, setCheckHistoryGridData] = useState([]);
  const [checkHistoryGridColumns, setCheckHistoryGridColumns] = useState([]);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [currentPMID, setCurrentPMID] = useState(null);
  const [checkHistoryGridDataState, setCheckHistoryGridDataState] = useState(
    INITIAL_HISTORY_GRID_DATA_STATE
  );
  const [checkHistoryGridResultState, setCheckHistoryGridResultState] =
    useState(checkHistoryGridData);
  const [expanded, setExpanded] = useState(false);
  const [showUpdateEditConfirmation, setShowUpdateEditConfirmation] =
    useState(false);
  const [currentEditPmid, setCurrentEditPmid] = useState(null);
  const [uriPmid, setUriPmid] = useState(null);

  const [
    isThereAnExistingIncompleteManualRemittance,
    setIsThereAnExistingIncompleteManualRemittance,
  ] = useState(false);

  const userContext = useContext(UserContext);
  const _container = React.useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (uriPmid === null) {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let pmid = params.get("pmid");
      setUriPmid(pmid);
    }
  }, []);

  //NOTE: This will work because there's no "load data on pageload" action for the Remittance Management page
  useEffect(() => {
    if (
      uriPmid !== null &&
      userContext.currentOrganization.value !== null &&
      userContext.currentOrganization.type !== null &&
      userContext.recLocations !== null
    ) {
      getDataWithExplicitPmid(uriPmid);
    }
  }, [uriPmid, userContext.recLocations]);

  const sendAggregatesToParent = (aggregatesFromSharedMainAgingGrid) => {
    setMainGridAggregates(aggregatesFromSharedMainAgingGrid);
  };

  const archivedSelectedChecks = async (selectablesFromSharedGrid) => {
    setMainGridSelectedState(selectablesFromSharedGrid);

    if (
      selectablesFromSharedGrid.length >= 15 ||
      selectablesFromSharedGrid.length == mainGridData.length
    ) {
      let userWantsToContinue = window.confirm(
        "You have selected " +
          selectablesFromSharedGrid.length +
          " remittances to archive. Do you wish to continue?"
      );

      if (userWantsToContinue) {
        await archiveForPmids(selectablesFromSharedGrid);
      }
    } else {
      await archiveForPmids(selectablesFromSharedGrid);
    }
  };

  const restorePendingSelectedChecks = async (selectablesFromSharedGrid) => {
    setMainGridSelectedState(selectablesFromSharedGrid);

    if (
      selectablesFromSharedGrid.length >= 15 ||
      selectablesFromSharedGrid.length == mainGridData.length
    ) {
      let userWantsToContinue = window.confirm(
        "You have selected " +
          selectablesFromSharedGrid.length +
          " remittances to restore to pending status. Do you wish to continue?"
      );

      if (userWantsToContinue) {
        await restorePendingForPmids(selectablesFromSharedGrid);
      }
    } else {
      await restorePendingForPmids(selectablesFromSharedGrid);
    }
  };

  function GetGridButtons() {
    let buttonsArray = [];

    buttonsArray = AddGridButton(
      buttonsArray,
      "Archive Selected",
      archivedSelectedChecks
    );

    buttonsArray = AddGridButton(
      buttonsArray,
      "Restore Pending Selected",
      restorePendingSelectedChecks
    );

    return buttonsArray;
  }

  const gridButtons = GetGridButtons();

  // Export Payer List - CSV || EXCLE Dispathcer
  const [directExportState, dispatchDirectExport] = useReducer(
    directExportReducer,
    null
  );

  function directExportReducer(directExportState, action) {
    let thisAction = action.type.toLowerCase();

    if (thisAction === "clear") {
      return null;
    }

    let thisExportData = null;
    if (action.payload.length > 0) {
      let exportColumns = [];
      let exportRows = [];
      let headerProps = constants.payerListExport;

      let thisData = action.payload;

      Object.keys(thisData[0]).forEach((key) => {
        exportColumns.push({
          title: headerProps[key].title,
          type: headerProps[key].type,
          accessor: key,
          hidden: false,
        });
      });

      thisData.forEach((item) => {
        exportRows.push(item);
      });

      //if no exportColumns or exportRows  return null
      if (!exportColumns || exportColumns.length === 0) return null;
      if (!exportRows || exportRows.length === 0) return null;
      exportColumns = exportColumns.filter((item) => item.hidden == false);
      thisExportData = {
        columns: exportColumns,
        rows: exportRows,
      };
    }

    return { ExportType: thisAction, ExportData: thisExportData };
  }

  function DirectExportCompletedCallback() {
    dispatchDirectExport({ type: "clear" });
  }

  function GetProcessedExportDataColumns(sourceColumns) {
    let columns = [];

    if (!sourceColumns) return columns;

    if (sourceColumns.length > 0) {
      columns = sourceColumns.map((value) => {
        let newObject = {
          field: value.accessor,
          title: value.title,
        };

        return newObject;
      });
    }
    return columns;
  }

  const sendAccordionParent = (props) => {
    setExpanded(props);
  };

  function handlePayerExcelExport() {
    var payerData = userContext.locations.map((payer) => payer.value).join(",");
    setIsLoading(true);
    try {
      let accessToken = passedProps.auth.getAccessToken();

      axios
        .get(`api/RemittanceLookup/GetRemittancePayerReport`, {
          params: {
            userName: user,
            MMIDS: payerData,
          },
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(onPayerExcelExportSuccess)
        .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
    }
  }

  async function handlePayerCSVExport() {
    var payerData = userContext.locations.map((payer) => payer.value).join(",");
    setIsLoading(true);
    try {
      let accessToken = passedProps.auth.getAccessToken();

      axios
        .get(`api/RemittanceLookup/GetRemittancePayerReport`, {
          params: {
            userName: user,
            MMIDS: payerData,
          },
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(onPayerCSVExportSuccess)
        .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
    }
  }

  function onPayerCSVExportSuccess(response) {
    setIsLoading(false);
    dispatchDirectExport({ type: "csv", payload: response.data });
  }

  function onPayerExcelExportSuccess(response) {
    setIsLoading(false);
    dispatchDirectExport({ type: "excel", payload: response.data });
  }

  function handleFormSubmit(formInput) {
    setIsLoading(true);
    setSubmittedFormData(formInput);
  }

  useEffect(() => {
    getData();
  }, [submittedFormData]);

  function getDataWithExplicitPmid(pmid) {
    setIsLoading(true);

    try {
      let accessToken = passedProps.auth.getAccessToken();
      console.log(userContext.recLocations);

      let locationsAsString = userContext.recLocations
        .map((obj) => obj["value"])
        .join(",");
      console.log(locationsAsString);

      axios
        .get(`api/RemittanceLookup/GetWithExplicitPmid`, {
          params: {
            user: user,
            pmid: pmid,
            locations: locationsAsString,
          },
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(onSuccess)
        .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
    }
  }

  function getData() {
    if (submittedFormData) {
      try {
        let accessToken = passedProps.auth.getAccessToken();

        axios
          .get(`api/RemittanceLookup/Get`, {
            params: {
              user: user,
              Payers: submittedFormData.payers,
              Locations: submittedFormData.locations,
              Status: submittedFormData.status,
              FromDate: submittedFormData.fromDate,
              ToDate: submittedFormData.toDate,
              DateType: submittedFormData.dateType,
              CheckAmount: submittedFormData.amount,
              CheckNumber: submittedFormData.checkNumber,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then(onSuccess)
          .catch(onFailure);
      } catch (exception) {
        onFailure(exception);
      }
    }
  }

  function getArchiveHistory(pmid) {
    if (pmid != "") {
      try {
        let accessToken = passedProps.auth.getAccessToken();

        axios
          .get(`api/RemittanceLookup/GetArchiveHistory`, {
            params: {
              user: user,
              pmid: pmid,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then(onSuccess)
          .catch(onFailure);
      } catch (exception) {
        onFailure(exception);
      }
    }
    setIsLoading(false);
  }

  function setArchiveHistoryData(rows) {
    if (rows.length > 0) {
      let headerProps = constants.archiveHistoryHeaderProps;

      const columns = [];
      Object.keys(rows[0]).map((key) => {
        columns.push({
          accessor: key,
          title: headerProps[key].title,
          type: headerProps[key].type,
          hidden: headerProps[key].hidden,
        });
      });

      // let dataWithCleanedDates = rows.map((t) => {
      //   return {
      //     ...t,
      //     addDate: IsNullOrWhitespace(t.addDate)
      //       ? ""
      //       : new Date(Date.parse(t.addDate)),
      //     checkDate: IsNullOrWhitespace(t.checkDate)
      //       ? ""
      //       : new Date(Date.parse(t.checkDate)),
      //   };
      // });

      let dataWithCleanedDates = GetDataWithCleanedDates(rows);

      setCheckHistoryGridData(dataWithCleanedDates);
      setCheckHistoryGridColumns(columns);
      setShowArchiveModal(true);
    } else {
      setShowArchiveModal(true);
      setCheckHistoryGridData([]);
    }
  }

  useEffect(() => {
    let processedData = process(
      checkHistoryGridData,
      INITIAL_HISTORY_GRID_DATA_STATE
    );
    setCheckHistoryGridDataState(INITIAL_HISTORY_GRID_DATA_STATE);
    setCheckHistoryGridResultState(processedData);
  }, [checkHistoryGridData]);

  function onSuccess(response) {
    console.log(response);

    switch (response.config.url) {
      case "api/RemittanceLookup/Get":
        presentGridData(response.data);
        break;
      case "api/RemittanceLookup/GetWithExplicitPmid":
        presentGridData(response.data);
        break;
      case "api/RemittanceLookup/GetArchiveHistory":
        setArchiveHistoryData(response.data);
        setShowArchiveModal(true);
        break;
      case "api/RemittanceLookup/RestorePending":
        updateGridAfterRestorePendingAction(response);
        break;
      case "api/RemittanceLookup/ArchiveRemittance":
        updateGridAfterArchiveAction(response);
        break;
      case "api/RemittanceLookup/UpdatePaymentDate":
        updateGridAfterPaymentDateUpdate(response);
        break;
      default:
        break;
    }
  }

  function presentGridData(data) {
    // let columns = [];

    if (data.length > 0) {
      let headerProps = constants.mainGridHeaderProps;
      headerProps.archivedDate = {
        title: "Archived Date",
        type: "date",
        hidden: false,
      };

      if (uriPmid === null) {
        if (
          submittedFormData.status &&
          !submittedFormData.status.includes("Archive")
        ) {
          headerProps.archivedDate.hidden = true;
        }
      }

      // let dataWithCleanedDates = data.map((t) => {
      //   return {
      //     ...t,
      //     remittanceDate: IsNullOrWhitespace(t.remittanceDate)
      //       ? ""
      //       : new Date(Date.parse(t.remittanceDate)),
      //     archivedDate: IsNullOrWhitespace(t.archivedDate)
      //       ? ""
      //       : new Date(Date.parse(t.archivedDate)),
      //     checkDate: IsNullOrWhitespace(t.checkDate)
      //       ? ""
      //       : new Date(Date.parse(t.checkDate)),
      //     paymentDate: IsNullOrWhitespace(t.paymentDate)
      //       ? ""
      //       : new Date(Date.parse(t.paymentDate)),
      //   };
      // });

      let dataWithCleanedDates = GetDataWithCleanedDates(data);

      setError({});
      setMainGridData(dataWithCleanedDates);

      let aggregateSettings = [
        { aggregate: "sum", field: "adjusted" },
        { aggregate: "sum", field: "paid" },
        { aggregate: "sum", field: "checkAmount" },
      ];

      setMainGridMoneyColumnAggregateSettings(aggregateSettings);
    } else {
      setError({ status: 201, Message: "No data found for given parameters" });
      setMainGridData([]);
    }

    setIsLoading(false);
  }

  function onFailure(error) {
    console.log(error);

    setIsLoading(false);
    setError(error.response);
    switch (error.response.config.url) {
      case "api/RemittanceLookup/Get":
        presentGridData([]);
        break;
      case "api/RemittanceLookup/GetArchiveHistory":
        setArchiveHistoryData([]);
        setCheckHistoryGridColumns([]);
        break;
      case "api/RemittanceLookup/RestorePending":
        break;
      case "api/RemittanceLookup/ArchiveRemittance":
        break;
      case "api/RemittanceLookup/ManualRemittanceEdit":
        alert("Manual Remittance failed to be created.");
        setIsLoading(false);
        break;
      default:
        break;
    }

    return;
  }

  const MainGridAggregateMoneyFooterCell = (props) => {
    var options = {
      aggregateData: mainGridAggregates,
    };
    return AggregateMoneyFooterCell(props, options);
  };

  const PaymentDateCell = (props) => {
    const [showCalendar, setShowCalendar] = useState(false);
    const [calendarValue, setCalendarValue] = useState(new Date());
    const anchor = React.useRef(null);

    function selectedCalendarCallback(event) {
      setCalendarValue(event.value);
    }

    async function updatePaymentDate(date = null) {
      setShowCalendar(false);

      setIsLoading(true);

      const accessToken = await passedProps.auth.getAccessToken();
      await axios
        .post(
          "api/RemittanceLookup/UpdatePaymentDate",
          {
            user: user,
            // PaymentDate: calendarValue.toLocaleDateString(),
            PaymentDate: date,
            PMID: props.dataItem.pmid,
          },
          { headers: { Authorization: `Bearer ${accessToken}` } }
        )
        .then(onSuccess)
        .catch(onFailure);
    }

    return (
      <td className="noPadding" {...props.style}>
        <span>{props.children}</span>
        <span>
          <Button onClick={() => setShowCalendar(!showCalendar)} ref={anchor}>
            <i className={"fa fa-calendar"} />
          </Button>
          <Popover
            show={showCalendar}
            anchor={anchor.current && anchor.current.element}
            position={"bottom"}
          >
            <div
              style={{
                width: "375px",
              }}
            >
              <Calendar onChange={selectedCalendarCallback} />
              <Button
                onClick={() =>
                  updatePaymentDate(calendarValue.toLocaleDateString())
                }
              >
                Submit Payment Date
              </Button>
              <Button onClick={() => updatePaymentDate(null)}>
                Clear Payment Date
              </Button>
            </div>
          </Popover>
        </span>
      </td>
    );
  };

  // const viewButtonItems = [
  //   "Payment Exceptions",
  //   "Fee and Adjustment Research",
  //   "History",
  // ];

  const viewButtonItems = ["History"];

  const downloadButtonItems = ["Excel", "PDF"];
  const actionButtonItemsForArchivedChecks = ["Restore Pending"];
  const actionButtonItemsForPendingChecks = ["Archive this Record"];
  const actionButtonEdit = ["Edit"];

  const RemittanceLookupActionCell = (props) => {
    async function viewClick(event) {
      if (event.item === "History") {
        setCurrentPMID(props.dataItem.pmid);
        setIsLoading(true);
        getArchiveHistory(props.dataItem.pmid);
      }
      //NOTE: I'm comment this out for now, but this will be restored in the future
      // else if (event.item === "Payment Exceptions") {
      //   navigate("/PaymentExceptions", {
      //     state: null
      //   });
      // }
      // else if (event.item === "Fee and Adjustment Research") {
      //   navigate("/FeeAdjustment", {
      //     state: null
      //   });
      // }
    }

    async function downloadClick(event) {
      const accessToken = await passedProps.auth.getAccessToken();

      if (event.item === "Excel") {
        await SingleCheckExcelExportWrapper(
          props.dataItem.pmid,
          accessToken,
          setIsLoading
        );
      } else if (event.item === "PDF") {
        await SingleCheckPdfExportWrapper(
          props.dataItem.pmid,
          accessToken,
          setIsLoading
        );
      }
    }

    async function CheckForExistingIncompleteManualRemittanceForUser(
      inputPmid
    ) {
      const accessToken = await passedProps.auth.getAccessToken();

      axios
        .get("api/ManualRemittance/GetIncompleteManualRemittanceForUserEmail", {
          params: {
            email: userContext.email,
            organizationId: userContext.currentOrganization.value,
            organizationType: userContext.currentOrganization.type,
          },
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(function (response) {
          if (response.data && response.data.mpmPmid) {
            setIsThereAnExistingIncompleteManualRemittance(true);
            EditManualRemittanceByPmid(inputPmid);
          } else {
            setIsThereAnExistingIncompleteManualRemittance(false);
            EditManualRemittanceByPmid(inputPmid);
          }
        })
        .catch(onFailure);
    }

    async function pendingOrArchiveClick(event) {
      if (event.item.includes("Restore Pending")) {
        await restorePendingForPmids([props.dataItem.pmid]);
      } else if (event.item.includes("Archive this Record")) {
        await archiveForPmids([props.dataItem.pmid]);
      } else if (event.item.includes("Edit")) {
        //NOTE: Here is where we look for an existing incomplete manual remittance
        // await EditManualRemittanceByPmid(props.dataItem.pmid);

        await CheckForExistingIncompleteManualRemittanceForUser(
          props.dataItem.pmid
        );
      }
    }

    let actionButtons = [];
    actionButtons.length = 0;
    if (props.dataItem.status === "Archived") {
      actionButtons.push(actionButtonItemsForArchivedChecks);
    } else {
      actionButtons.push(actionButtonItemsForPendingChecks);
    }

    if (props.dataItem.isManualCheck) {
      actionButtons.push("Edit");
    }

    return (
      <td className="noPadding" {...props.style}>
        <Row className="reducedHeight">
          <div className="col-sm-3">
            <DropDownButton
              text="View"
              items={viewButtonItems}
              onItemClick={viewClick}
              themeColor={"primary"}
              fillMode={"link"}
            />
          </div>

          <div className="col-sm-4">
            <DropDownButton
              text="Download"
              items={downloadButtonItems}
              onItemClick={downloadClick}
              themeColor={"primary"}
              fillMode={"link"}
            />
          </div>

          <div className="col-sm-4">
            <DropDownButton
              text="Action"
              items={actionButtons}
              onItemClick={pendingOrArchiveClick}
              themeColor={"primary"}
              fillMode={"link"}
            />
          </div>
        </Row>
      </td>
    );
  };

  async function restorePendingForPmids(pmids) {
    if (pmids.length > 0) {
      setIsLoading(true);
      let accessToken = passedProps.auth.getAccessToken();

      try {
        await axios
          .get("api/RemittanceLookup/RestorePending", {
            params: {
              user: user,
              rpid: userContext.currentOrganization.value,
              pmids: pmids.join(","),
            },
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then(onSuccess)
          .catch(onFailure);
      } catch (exception) {
        onFailure(exception);
      }
    }
  }

  async function archiveForPmids(pmids) {
    if (pmids.length > 0) {
      setIsLoading(true);

      let accessToken = passedProps.auth.getAccessToken();

      try {
        await axios
          .get("api/RemittanceLookup/ArchiveRemittance", {
            params: {
              user: user,
              rpid: userContext.currentOrganization.value,
              pmids: pmids.join(","),
            },
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then(onSuccess)
          .catch(onFailure);
      } catch (exception) {
        onFailure(exception);
      }
    }
  }

  async function EditManualRemittanceByPmid(pmid) {
    if (pmid) {
      setShowUpdateEditConfirmation(true);
      setCurrentEditPmid(pmid);
    }
  }

  function handleEditConfirmation() {
    if (isThereAnExistingIncompleteManualRemittance) {
      setIsThereAnExistingIncompleteManualRemittance(false);
      setShowUpdateEditConfirmation(true);
    }
    if (currentEditPmid && !isThereAnExistingIncompleteManualRemittance) {
      setShowUpdateEditConfirmation(false);
      EditPmidCallBack(currentEditPmid);
    }
  }

  function handleCloseEditConfirmation() {
    setShowUpdateEditConfirmation(false);
  }

  async function EditPmidCallBack(pmids) {
    setIsLoading(true);
    let accessToken = passedProps.auth.getAccessToken();
    console.log(userContext);

    try {
      await axios
        .post(
          "api/RemittanceLookup/ManualRemittanceEdit",
          {
            user: user,
            pMID: pmids,
            organizationId: userContext.currentOrganization.value,
            organizationType: userContext.currentOrganization.type,
          },
          { headers: { Authorization: `Bearer ${accessToken}` } }
        )
        .then(function (response) {
          setIsLoading(false);
          var pmid = response.data;
          alert("Manual Remittance is ready for editing...");
          // navigate("/ManualRemittance?pmid=" + pmid, {});
          navigate("/ManualRemittance", {});
        })
        .catch(onFailure);
    } catch (exception) {
      alert("Manual Remittance failed to be created;");
      onFailure(exception);
    }
  }

  function CreateGridColumn(column) {
    let filterType = "text";
    if (column.hidden) {
      return null;
    }
    if (column.type == "date") {
      return (
        <GridColumn
          key={column.accessor + "Column"}
          field={column.accessor}
          title={column.title}
          filter="date"
          format="{0:d}"
        />
      );
    } else if (column.type == "money") {
      return (
        <GridColumn
          key={column.accessor}
          filter={"numeric"}
          field={column.accessor}
          title={column.title}
          cells={{
            data: MoneyCell,
          }}
        />
      );
    } else {
      return (
        <GridColumn
          key={column.accessor + "Column"}
          filter={filterType}
          field={column.accessor}
          title={column.title}
        />
      );
    }
  }

  function updateGridAfterPaymentDateUpdate(response) {
    let resetDetailData = [...mainGridData];
    let responseInputParameters = JSON.parse(response.config.data);
    let pmid = responseInputParameters.PMID;

    let updatedPaymentDate = new Date(responseInputParameters.PaymentDate);

    var foundIndex = resetDetailData.findIndex((x) => x.pmid == pmid);
    let checkByPmid = resetDetailData[foundIndex];

    if (responseInputParameters.PaymentDate) {
      checkByPmid.paymentDate = updatedPaymentDate;
    } else {
      checkByPmid.paymentDate = null;
    }

    resetDetailData[foundIndex] = checkByPmid;

    setMainGridData(resetDetailData);

    setIsLoading(false);
  }

  function updateGridAfterArchiveAction(response) {
    setIsLoading(true);
    getData();
  }

  function updateGridAfterRestorePendingAction(response) {
    setIsLoading(true);
    getData();
  }

  function onDataStateChange(e) {
    let processedData = process(checkHistoryGridData, e.dataState);
    setCheckHistoryGridDataState(e.dataState);
    setCheckHistoryGridResultState(processedData);
  }

  return (
    <React.Fragment>
      <div className="feature">
        <canvas
          id="hiddenCanvasForSingleCheckPdf"
          style={{ display: "none" }}
          width={185}
          height={70}
        />
        <LoadingSpinner
          isDataLoading={isLoading}
          controlsName={"RemittanceLookup"}
        />
        <ErrorHandler
          error={error}
          onClose={() => {
            setError({});
          }}
        />
        <Accordion
          defaultExpanded
          label="Search & Filter"
          sendAccordionParentCallback={sendAccordionParent}
        >
          <Row>
            <Col md="auto" className="d-flex flex-column higher-zindex-filters">
              <RemittanceLookupForm
                handleFormSubmit={handleFormSubmit}
                handlePayerExcelExport={handlePayerExcelExport}
                handlePayerCSVExport={handlePayerCSVExport}
              ></RemittanceLookupForm>
            </Col>
            {/* <Col md="auto" className="d-flex flex-column forceToRightForLargeWindow">
              <FeatureWidget
                title="Last 5 Remittances Received"
                iFrameId="sisense-remittance-lookup-frame"
                filters={{}}
                dashboard={process.env.REACT_APP_Dashboard_RemittanceLookup}
                widget={process.env.REACT_APP_Widget_RemittanceLookup}
                settings={{
                  showToolbar: false,
                  showLeftPane: false,
                  showRightPane: false,
                }}
              // NOTE: If size is set to "lg", it will work but it will force it to the second line on smaller screens
              // This makes the form itself look wonky because there are checkboxes on it that expect to be next to input fields
              // size="lg"
              />
            </Col> */}
          </Row>
        </Accordion>

        <div ref={_container}>
          <Row>
            <Col className="tight-grid multi-line-filter">
              <SharedMainSelectableGridNewImplementation
                data={mainGridData}
                dataItemKey={"pmid"}
                aggregateColumnSettings={mainGridMoneyColumnAggregateSettings}
                sendAggregatesParentCallback={sendAggregatesToParent}
                sendSelectablesParentCallback={archivedSelectedChecks}
                gridButtons={gridButtons}
                exportFileNamePrefix={"RemittanceManagement"}
              >
                <GridColumn
                  field=""
                  title="Action"
                  filterable={false}
                  sortable={false}
                  groupable={false}
                  width={DefaultWideColumnWidth()}
                  cells={{ data: RemittanceLookupActionCell }}
                />
                <GridColumn
                  field="payer"
                  title="Payer"
                  width={DefaultColumnWidth()}
                />
                <GridColumn
                  field="checkNumber"
                  title="Check Number"
                  width={DefaultColumnWidth()}
                />
                <GridColumn
                  field="status"
                  title="Remit Status"
                  width={DefaultColumnWidth()}
                />
                <GridColumn
                  field="checkDate"
                  title="Check Date"
                  filter="date"
                  format="{0:d}"
                  width={DefaultColumnWidth()}
                />
                <GridColumn
                  field="remittanceDate"
                  title="Remit Date"
                  filter="date"
                  format="{0:d}"
                  width={DefaultColumnWidth()}
                />
                <GridColumn
                  field="paymentDate"
                  title="Payment Date"
                  filter="date"
                  format="{0:d}"
                  cells={{
                    data: PaymentDateCell,
                  }}
                  width={DefaultColumnWidth()}
                />
                <GridColumn
                  field="ncpdp"
                  title="NCPDP"
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                />
                <GridColumn
                  field="paid"
                  title="Paid"
                  format="{0:c}"
                  filter="numeric"
                  cells={{
                    data: MoneyCell,
                    footerCell: MainGridAggregateMoneyFooterCell,
                  }}
                  width={DefaultColumnWidth()}
                />
                <GridColumn
                  field="adjusted"
                  title="Adjusted"
                  format="{0:c}"
                  filter="numeric"
                  cells={{
                    data: MoneyCell,
                    footerCell: MainGridAggregateMoneyFooterCell,
                  }}
                  width={DefaultColumnWidth()}
                />
                <GridColumn
                  field="checkAmount"
                  title="Check Amount"
                  filter="numeric"
                  width={DefaultColumnWidth()}
                  cells={{
                    data: MoneyCell,
                    footerCell: MainGridAggregateMoneyFooterCell,
                  }}
                />
                <GridColumn
                  field="archivedDate"
                  title="Archive Date"
                  width={DefaultColumnWidth()}
                  filter="date"
                  format="{0:d}"
                />
              </SharedMainSelectableGridNewImplementation>
            </Col>
          </Row>
        </div>
        {directExportState && directExportState.ExportType == "excel" && (
          <KendoExcelExporterDirect
            data={directExportState.ExportData.rows}
            columns={GetProcessedExportDataColumns(
              directExportState.ExportData.columns
            )}
            exportCompletedCallback={DirectExportCompletedCallback}
            exportFileNamePrefix={"PayerList"}
          ></KendoExcelExporterDirect>
        )}

        {directExportState && directExportState.ExportType == "csv" && (
          <CsvExportDirect
            columns={[]}
            rows={[]}
            custom={directExportState.ExportData}
            exportCompletedCallback={DirectExportCompletedCallback}
            exportFileNamePrefix={"PayerList"}
          />
        )}
        <ZIndexContext.Provider value={10003}>
          <HistoryModal
            title="Check History"
            show={showArchiveModal}
            handleClose={() => {
              return setShowArchiveModal(false);
            }}
          >
            <Row>
              <Col className="tight-grid multi-line-filter">
                <Grid
                  data={checkHistoryGridResultState}
                  filterable={true}
                  sortable={true}
                  groupable={true}
                  pageable={true}
                  onDataStateChange={onDataStateChange}
                  {...checkHistoryGridDataState}
                >
                  {checkHistoryGridColumns.length > 0
                    ? checkHistoryGridColumns.map((singleColumn) =>
                        CreateGridColumn(singleColumn)
                      )
                    : ""}
                </Grid>
              </Col>
            </Row>
          </HistoryModal>
        </ZIndexContext.Provider>
        <ZIndexContext.Provider value={10005}>
          <EditConfirmationModal
            title="Edit Manual Remittance - Confirmation"
            show={showUpdateEditConfirmation}
            handleClose={() => {
              return setShowUpdateEditConfirmation(false);
            }}
            handleOk={() => {
              handleEditConfirmation();
            }}
          >
            <Row>
              <Col>
                {isThereAnExistingIncompleteManualRemittance
                  ? "WARNING: You have an INCOMPLETE manual remittance in-progress which will be ERASED if you begin editing this manual remittance. Do you want to continue?"
                  : "Editing the manual remittance will DELETE the current version and un-match all claims included. Do you want to continue?"}
              </Col>
            </Row>
          </EditConfirmationModal>
        </ZIndexContext.Provider>
      </div>
    </React.Fragment>
  );
}
