import React, { useState, useRef, useEffect } from "react";
import { Popover, Overlay, Col, Dropdown } from "react-bootstrap";

import axios from "axios";
import { useNavigate } from "react-router-dom";

export function UserAvatar(props) {
  const { user, auth } = props;
  const { firstName, lastName } = user;

  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [superAdmin, setSuperAdmin] = useState(false);
  const navigate = useNavigate();

  async function getIsSuperAdmin() {
    const userInfo = await auth.getUser();
    const accessToken = await auth.getAccessToken();
    let parameters = {
      email: userInfo.email,
    };
    let userResponse = await axios.get(`api/UserProgram/GetSuperUser`, {
      params: parameters,
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    setSuperAdmin(userResponse.data);
  }

  //NOTE: Readding the useEffect to prevent repeated API calls
  useEffect(() => {
    getIsSuperAdmin();
  }, []);

  async function logout() {
    await auth.signOut();
  }

  const popover = (
    <Popover className="popover-basic">
      {superAdmin.isSuperUser ? (
        <Dropdown.Item eventKey="3" onClick={() => navigate("/ContactAdmin")}>
          <i className="fa fa-user" />
          Contact Admin
        </Dropdown.Item>
      ) : (
        ""
      )}

      <Dropdown.Divider />
      <Dropdown.Item eventKey="4" onClick={async () => await logout()}>
        <i className="fa fa-sign-out" />
        Logout
      </Dropdown.Item>
    </Popover>
  );

  const initials = firstName[0] + lastName[0];
  const name = `${firstName} ${lastName}`;
  return (
    <span>
      <Col
        onClick={() => setShow(!show)}
        className="col-md-auto avatar-wrapper d-flex"
      >
        <i ref={target} className="fa fa-circle" id="user-avatar" />
        <span id="user-avatar-initials">{initials}</span>
        <span id="username" className="align-middle">
          {name}
        </span>
      </Col>
      <Overlay
        target={target.current}
        show={show}
        placement="bottom"
        rootClose
        onHide={() => setShow(!show)}
      >
        {popover}
      </Overlay>
    </span>
  );
}
