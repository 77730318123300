import React, { useState, useContext } from "react";
import { UserContext } from "core/context/UserContext";
import IgnoreOptions from "../SharedComponents/IgnoreOptions";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import SingleSelectDropdown from "../../SingleSelectDropdown";
import { useIgnoreClaimApi } from "./hooks/useIgnoreClaimApi";

export function IgnoreBody(props) {
  const {
    selectedNcpdpIgnoreModalText,
    selectedRxIgnoreModalText,
    selectedClidsToIgnore,
    handleCancelClick,
    isCancelButtonHidden = false,
    onSaveButtonClickCallbackFromParent = null,
    onSuccessCallbackFromParent = null,
  } = props;

  const userContext = useContext(UserContext);
  let user = userContext.email;

  //NOTE: I think we can probably simplify this by just retrieving the ignoreNote on submission?
  const [ignoreReason, setIgnoreReason] = useState(0);
  const [ignoreNote, setIgnoreNote] = useState("");
  const [ignoreApiParameters, setIgnoreApiParameters] = useState({});

  let { data: hookCall, error: hookError } = useIgnoreClaimApi(
    ignoreApiParameters,
    onSuccessCallbackFromParent
  );

  const handleSaveIgnoreClick = () => {
    if (onSaveButtonClickCallbackFromParent) {
      onSaveButtonClickCallbackFromParent();
    }

    let parameters = {
      user: user,
      IgnoreId: ignoreReason.toString(),
      IgnoreNote: ignoreNote,
      ClaimIds: selectedClidsToIgnore.toString(),
    };

    setIgnoreApiParameters(parameters);
  };

  return (
    <Container>
      <Row>
        <Col> NCPDP </Col>
        <Col> Rx #</Col>
      </Row>
      <Row>
        <Col>{selectedNcpdpIgnoreModalText}</Col>
        <Col>{selectedRxIgnoreModalText}</Col>
      </Row>
      <br />
      <Row>
        <Col> Ignore Code </Col>
      </Row>
      <Row>
        <Col>
          <SingleSelectDropdown
            options={IgnoreOptions}
            highlightSelection={true}
            onChange={(selectedIgnoreReason) => {
              setIgnoreReason(selectedIgnoreReason);
            }}
          />
        </Col>
      </Row>
      <br />
      <Row md={1}>
        <Col>
          <Form.Group controlId="txtIgnoreNote">
            <Form.Label> Comments </Form.Label>
            <Form.Control
              as="textarea"
              rows="5"
              value={ignoreNote}
              placeholder="Type your message here..."
              onChange={(event) => {
                setIgnoreNote(event.target.value);
              }}
            >
              {" "}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <br />
      <Row>
        {isCancelButtonHidden ? null : (
          <Col>
            <Button variant="secondary" onClick={handleCancelClick}>
              Cancel
            </Button>
          </Col>
        )}
        <Col>
          <Button className="primary-button" onClick={handleSaveIgnoreClick}>
            Save
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
