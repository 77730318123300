import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { Form, Row, Col, Button } from "react-bootstrap";
import MemberLocationDropdown from "../../form/MemberLocationDropdown";
import MemberPayerDropdown from "../../form/MemberPayerDropdown";
import { IsNullOrWhitespace } from "shared/helpers/StringHelpers";
import {
  GetDateNumberOfDaysFromSource,
  GetDaysBetweenDates,
} from "shared/helpers/DateHelpers";

export default function AdminRxLookupForm(props) {
  const [selectedPayers, setSelectedPayers] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);

  const [rxNumber, setRxNumber] = useState("");
  const [dateType, setDateType] = useState("dispense");

  //will default to today's date
  const today = new Date();
  const [fromDate, setFromDate] = useState(
    GetDateNumberOfDaysFromSource(today, -7)
  );

  const [toDate, setToDate] = useState(today);

  const [bin, setBin] = useState("");
  const [ndc, setNdc] = useState("");

  //This page deals with a large amount of data, so we need to limit the date range to no more than 30 days
  const maximumDateRange = 30;

  function hasDateFilters() {
    return fromDate || toDate;
  }

  function validRxNumberProvided() {
    if (IsNullOrWhitespace(rxNumber)) {
      return false;
    }

    // make sure Rx Number is at least 3 digits
    if (rxNumber.length < 3) {
      return false;
    }

    return true;
  }

  function validateForm() {
    if (selectedLocations.length <= 0) {
      return "Location (NCPDP) is a required field.";
    }

    if (!hasDateFilters()) {
      // make sure Rx Number is provided

      if (!validRxNumberProvided()) {
        return "Rx Number must be at least 3 digits when dates are not provided.";
      }
    }

    if (hasDateFilters()) {
      if (fromDate && !toDate) {
        return "A start date is provided, but an end date must also be provided.";
      }

      if (!fromDate && toDate) {
        return "An end date is provided, but a start date must also be provided.";
      }
      if (toDate < fromDate) {
        return "The end date must be after the start date.";
      }

      //Do next 2 tests only if no Rx Number is provided
      if (!validRxNumberProvided()) {
        //lemit the date range to no more than 30 days
        if (GetDaysBetweenDates(fromDate, toDate) > maximumDateRange) {
          return `The date range must be no more than ${maximumDateRange} days when No Rx Number at least 3 digits is specified.`;
        }

        //if no Rx Number then a BIN, at least 1 payer,or NDC must also be provided
        if (
          (IsNullOrWhitespace(rxNumber) || rxNumber.length < 3) &&
          IsNullOrWhitespace(bin) &&
          selectedPayers.length <= 0 &&
          IsNullOrWhitespace(ndc)
        ) {
          return "If a date range is provided but no Rx Number at least 3 digits is specified, then a BIN, at least 1 payer,or NDC must also be provided.";
        }
      }
    }

    return null;
  }

  function handleFormSubmit(e) {
    e.preventDefault();

    let errors = validateForm();
    errors ? alert(errors) : submitForm();
  }

  function submitForm() {
    props.handleFormSubmit({
      rxNumber: rxNumber,
      payers: selectedPayers.join(","),
      locations: selectedLocations.join(","),
      dateType: dateType,
      fromDate: fromDate ? fromDate.toLocaleDateString() : "",
      toDate: toDate ? toDate.toLocaleDateString() : "",
      bin: bin ? bin : "",
      ndc: ndc ? ndc : "",
    });
  }

  return (
    <Form onSubmit={handleFormSubmit}>
      <Row className="justify-content-start">
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Rx Number</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            onChange={(e) => {
              setRxNumber(e.target.value);
            }}
            value={rxNumber}
          />
        </Col>

        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Date Type</Form.Label>
          {/* <Form.Select aria-label="Default select example"
            onChange={(e) => {
              setDateType(e.target.value);
            }}>
            <option value="dispense">Dispense Date</option>
            <option value="transaction">Transaction Date</option>
          </Form.Select> */}
          <div key="DateType">
            <Form.Check
              custom="true"
              type="radio"
              id="rxLookupDateType-1"
              name="DateType"
              label="Dispense date"
              value="dispense"
              onChange={(event) => setDateType(event.target.value)}
              defaultChecked
            />
            <Form.Check
              custom="true"
              type="radio"
              id="rxLookupDateType-1"
              name="DateType"
              label="Transaction date"
              value="transaction"
              onChange={(event) => setDateType(event.target.value)}
            />
          </div>
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Start Date</Form.Label>
          <Form.Control
            name="fromDate"
            as={DatePicker}
            selected={fromDate}
            onChange={(date) => {
              setFromDate(date);
            }}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>End Date</Form.Label>
          <Form.Control
            name="fromDate"
            as={DatePicker}
            selected={toDate}
            onChange={(date) => {
              setToDate(date);
            }}
          />
        </Col>

        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Payer</Form.Label>
          <Form.Control
            as={MemberPayerDropdown}
            onChange={(payers) => setSelectedPayers(payers)}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Location (NCPDP)</Form.Label>
          <Form.Control
            as={MemberLocationDropdown}
            onChange={(locations) => setSelectedLocations(locations)}
            programSubscription={"Rec2.0"}
          />
        </Col>

        <Col md="auto" className="d-flex flex-column">
          <Form.Label>BIN</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            onChange={(e) => {
              setBin(e.target.value);
            }}
            value={bin}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>NDC</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            onChange={(e) => {
              setNdc(e.target.value);
            }}
            value={ndc}
          />
        </Col>
      </Row>
      <Row>
        <Col md="auto" className="d-flex flex-column">
          <Button className="inline-show-result-button" size="sm" type="submit">
            Show Results
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
