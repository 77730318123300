import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";

import { UserContext } from "core/context/UserContext";

import { ShortDateFormat, IsDefaultDate } from "shared/helpers/DateHelpers";
import { FormatValue } from "shared/helpers/DataFormatHelpers";

import { IsNullOrEmpty } from "shared/helpers/NullEmptyHelpers";
import "./PendingHomePageRemittances.css";

import { LoadingSmallControlSpinner } from "../../layout/LoadingSmallControlSpinner";

import { useFetchHomepageRemittanceData } from "./hooks/useFetchHomepageRemittanceData";

export default function PendingHomePageRemittances(props) {
  const userContext = useContext(UserContext);

  let argLocations = !IsNullOrEmpty(userContext.locations)
    ? userContext.locations
        .map((x) => x.value)
        .join(",")
        .toString()
    : "";

  let {
    data: summaryData,
    isLoading,
    isError,
  } = useFetchHomepageRemittanceData(argLocations);

  if (isLoading) {
    return <LoadingSmallControlSpinner />;
  }

  if (isError) {
    return (
      <div className="errorContainer">
        <p style={{ fontStyle: "italic" }}>
          Data is currently not available. Please try again later.
        </p>
      </div>
    );
  }

  let getBindingData = () => {
    if (IsNullOrEmpty(summaryData)) {
      return {
        remitsCount: 0,
        totalDollarValue: 0,
        oldestCheckDate: new Date("01/01/1000"),
      };
    }

    return {
      remitsCount: summaryData.remitsCount,
      totalDollarValue: summaryData.totalDollarValue,
      oldestCheckDate: new Date(summaryData.oldestCheckDate),
    };
  };

  const bindingData = getBindingData();

  return (
    <div className="remittanceSummaryContainer">
      <Row>
        <Col>Count of Pending Remits:</Col>
        <Col className="remittancecCountColor">
          {bindingData.remitsCount.toLocaleString()}
        </Col>
      </Row>

      <Row>
        <Col>Total Dollar Value:</Col>
        <Col className="remittancecDollarValueColor">
          {FormatValue(bindingData.totalDollarValue, "money")}
        </Col>
      </Row>

      <Row>
        <Col>Oldest Pending Check Date:</Col>
        <Col className="remittancecEarliestDateColor">
          {IsDefaultDate(bindingData.oldestCheckDate)
            ? "N/A"
            : ShortDateFormat(bindingData.oldestCheckDate)}
        </Col>
      </Row>
    </div>
  );
}
