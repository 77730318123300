import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { IsNullOrEmpty } from "shared/helpers/NullEmptyHelpers";
import { useQuery } from "@tanstack/react-query";

export function useFetchLayoutUserSubscription(isAuthenticated, email) {
  const { oktaAuth } = useOktaAuth();

  const dataKey = "useFetchLayoutUserSubscriptionAccessKey";

  async function getUserSubscription() {
    const accessToken = oktaAuth.getAccessToken();

    let param = {
      email: email,
    };

    var result = await axios
      .get("api/UserProgram/GetUserSubscription", {
        params: param,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        return response.data;
        // onHasMetricSubscriptionChange(response.data.isMetricSubscription);
        // onHasRecSubscriptionChange(response.data.isRecSubscription);
        // onContactTypeChange(response.data.contactType);

        // //we don't need to return anything from this function as a callback event is being called for the data, HOWEVER we need to return something so react query doesn't complain
        // return "success";
      })
      .catch((error) => {
        throw new Error(error);
      });

    return result;
  }

  function IsEnabled() {
    if (!isAuthenticated) return false;

    if (IsNullOrEmpty(email)) return false;

    return true;
  }

  return useQuery({
    queryKey: [dataKey, [email]],
    queryFn: async () => await getUserSubscription(),
    enabled: IsEnabled,
  });
}
