import React, { useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";

// import video from './Remit Admin Tutorial.mp4';
import video from './Remit Admin take 1.mp4';

export default function TutorialVideo(props) {
    const videoRef = useRef(null);

    const handleSeek = (seconds) => {
        if (videoRef.current) {
            videoRef.current.currentTime = seconds;
        }
    };

    function TutorialVideoChapter(props) {
        return (
            <div>
                <span class={"hyperlinkStyle"} onClick={() => handleSeek(props.seekTime)}>
                    {props.chapterTitle}
                </span>
            </div>
        )
    }

    return (
        <Container>
            <Row>
                <video src={video} ref={videoRef} width="768" height="432"
                    controls controlslist="nodownload"
                    onContextMenu={e => e.preventDefault()}
                >
                </video>
            </Row>
            <Row>
                <h4>
                    Video Chapters
                </h4>
                <TutorialVideoChapter seekTime={1} chapterTitle={"Home – 0:01"} />
                <TutorialVideoChapter seekTime={181} chapterTitle={"Remittance Management – 3:01"} />
                <TutorialVideoChapter seekTime={369} chapterTitle={"Report a Missing Remittance – 6:09"} />
                <TutorialVideoChapter seekTime={501} chapterTitle={"835 Remittance Exporting – 8:21"} />
                <TutorialVideoChapter seekTime={703} chapterTitle={"Manual 835 Remittance Creation – 11:43"} />
            </Row>
        </Container>
    );
}