function IsNullOrEmpty(input) {
  // if input is null or undefined, return true

  if (!input) {
    return true;
  }

  if (typeof input === "string") {
    if (input.trim().length < 1) {
      return true;
    }
  }

  // if the item is an array and has a length of 0, return true
  if (Array.isArray(input)) {
    if (input.length < 1) {
      return true;
    }
  }

  // if the item is an object and has no keys, return true
  if (typeof input === "object") {
    if (Object.keys(input).length < 1) {
      return true;
    }
  }

  // otherwise, return false
  return false;
}

function DefaultValueIfNullOrEmpty(source, defaultValue) {
  //wrap in a try catch block and if error occurs, return the default value
  try {
    if (IsNullOrEmpty(source)) {
      return defaultValue;
    }

    return source;
  } catch (error) {
    return defaultValue;
  }
}

export { IsNullOrEmpty, DefaultValueIfNullOrEmpty };
