import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";

import { useQuery } from "@tanstack/react-query";

import { IsNullOrEmpty } from "shared/helpers/NullEmptyHelpers";

export function useFetchLayoutPayers(
  isAuthenticated,
  email,
  currentOrganization,
  currentOrganizationType,
  currentRoute
) {
  const { oktaAuth } = useOktaAuth();

  const dataKey = "useFetchLayoutPayersKey";

  async function GetPayers() {
    const accessToken = oktaAuth.getAccessToken();

    let parameters = {
      email: email,
      selectedItem: currentOrganization,
      organizationType: currentOrganizationType,
    };

    var result = await axios
      .get("api/UserProgram/GetUserLocationsPayers", {
        params: parameters,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          RequestSrc: currentRoute,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw new Error(error);
      });

    return result;
  }

  function IsEnabled() {
    if (!isAuthenticated) return false;

    if (IsNullOrEmpty(email)) return false;

    if (IsNullOrEmpty(currentOrganization)) return false;

    return true;
  }

  //alert("isAuthenticated" + isAuthenticated);

  return useQuery({
    queryKey: [dataKey, [email, currentOrganization, currentRoute]],
    queryFn: async () => await GetPayers(),
    enabled: IsEnabled,
  });
}
