import { useState, useMemo } from "react";
import axios from "axios";
import { useOktaAuth } from "@okta/okta-react";
import { IsNullOrEmpty } from "shared/helpers/NullEmptyHelpers";

export function useUpdateSisenseLocations(isAuthenticated, email, locations) {
  const { oktaAuth } = useOktaAuth();

  const [currentSetLocations, setCurrentSetLocations] = useState();

  //Sisense is an extrenal system so will do an extra check to see if the locations have changed before setting them in Sisense
  const memodLocations = useMemo(() => {
    if (!locations) return {};

    return locations;
  }, [locations]);

  async function updateSisenseLocations() {
    //do some tests to ensure we have the data we need to make the call

    if (!isAuthenticated) return false;
    if (IsNullOrEmpty(email)) return;

    //if the memodLocations are same as currentSetLocations, then return and do not update
    if (
      JSON.stringify(memodLocations) === JSON.stringify(currentSetLocations)
    ) {
      return;
    }

    //test passed make the call to update sisense and also update the currentSetLocations
    setCurrentSetLocations(memodLocations);

    const accessToken = await oktaAuth.getAccessToken();

    try {
      await axios.post(
        "api/UserProgram/UpdateSisense",
        {
          email: email,
          locations: Object.keys(locations),
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
    } catch (e) {
      console.log("Sisense server down");
    }
  }

  return { updateSisenseLocations };
}
