import React, { useState, useEffect, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { UserContext } from "core/context/UserContext";
import axios from "axios";
import { useLocation } from "react-router-dom";

export default function ParentCompanySelectionForGroupUsers(props) {
  const {
    passedProps,
    setParentCompanyCallback,
    setMembersCallback,
    setPayersCallback,
  } = props;
  const [selectedParentCompany, setSelectedParentCompany] = useState("");
  const [membersAndRpids, setMembersAndRpids] = useState([]);
  const [uniqueRpids, setUniqueRpids] = useState([]);
  const [selectedPayers, setSelectedPayers] = useState([]);

  const userContext = useContext(UserContext);
  const urlPath = useLocation();

  useEffect(() => {
    if (userContext.currentOrganization.value) {
      setSelectedParentCompany("");
      setMembersAndRpids([]);
      setUniqueRpids([]);
      setSelectedPayers([]);

      getUserLocation();
    }
  }, [userContext.currentOrganization.value]);

  async function getUserLocation() {
    let allLocationsParameters = {
      email: passedProps.user.email,
      selectedItem: userContext.currentOrganization.value,
      organizationType: userContext.currentOrganization.type,
      programSubscriptions: "Rec2.0",
    };
    let accessToken = passedProps.auth.getAccessToken();
    let currentRoute = urlPath.pathname;
    let allLocationsResponse = await axios.get(
      `api/UserProgram/GetUserLocationsDetails`,
      {
        params: allLocationsParameters,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          RequestSrc: currentRoute,
        },
      }
    );

    let formattedLocations = allLocationsResponse.data.map((location) => {
      return {
        label: location.memberName + " (" + location.memberNcpdp + ")",
        value: location.memberMmid,
        rpid: location.memberRpid,
        parentCompanyName: location.parentCompanyName,
      };
    });

    let rpids = [];

    formattedLocations.forEach((item) => {
      if (!rpids.find((x) => x.rpid === item.rpid)) {
        rpids.push({
          rpid: item.rpid,
          parentCompanyName: item.parentCompanyName,
        });
      }
    });

    setMembersAndRpids(formattedLocations);
    setUniqueRpids(rpids);
  }

  const getMemberLocationsByRpid = (rpid) => {
    return membersAndRpids.filter((item) => item.rpid === rpid);
  };

  function formatDictionaryToArray(data) {
    return Object.keys(data).map((key) => {
      return {
        label: data[key],
        value: key,
      };
    });
  }

  async function getPayers(rpid) {
    const userInfo = await passedProps.user.email;
    const accessToken = await passedProps.auth.getAccessToken();

    let currentRoute = urlPath.pathname;

    let parameters = {
      email: userInfo.email,
      selectedItem: rpid,
      organizationType: "ParentCompany",
    };

    let header = {
      Authorization: `Bearer ${accessToken}`,
      RequestSrc: currentRoute,
    };

    let payerResponse = await axios.get(
      `api/UserProgram/GetUserLocationsPayers`,
      {
        params: parameters,
        headers: header,
      }
    );

    let payers = formatDictionaryToArray(payerResponse.data);

    let sortedPayers = payers.sort((p1, p2) =>
      p1.label > p2.label ? 1 : p1.label < p2.label ? -1 : 0
    );

    setSelectedPayers(sortedPayers);
  }

  const clickAction = (props) => {
    console.log(selectedParentCompany);
    const selectedMembers = getMemberLocationsByRpid(
      selectedParentCompany.rpid
    );
    console.log(selectedMembers);
    console.log(selectedPayers);

    setParentCompanyCallback(selectedParentCompany);
    setMembersCallback(selectedMembers);
    setPayersCallback(selectedPayers);
  };

  const setSelectedParentCompanyAndGetPayers = async (parentCompany) => {
    setSelectedParentCompany(parentCompany);
    await getPayers(parentCompany.rpid);
  };

  const membersDisplayElement = () => {
    if (selectedParentCompany) {
      return (
        <div>
          <h6>Members for {selectedParentCompany.parentCompanyName}</h6>
          <ul>
            {getMemberLocationsByRpid(selectedParentCompany.rpid).map(
              (item, index) => {
                return <li key={index}>{item.label}</li>;
              }
            )}
          </ul>
        </div>
      );
    } else {
      return <div>No parent company selected</div>;
    }
  };

  return (
    <div>
      <br />
      <div className="bordered-control">
        <h4>Specify a Parent Company</h4>
        <hr />
        <Form.Control
          as={ComboBox}
          data={uniqueRpids || []}
          id="rpid"
          textField="parentCompanyName"
          allowCustom={false}
          value={selectedParentCompany}
          onChange={async (e) => {
            if (e.target.value) {
              // setSelectedParentCompany(e.target.value);
              await setSelectedParentCompanyAndGetPayers(e.target.value);
            } else setSelectedParentCompany(null);
          }}
        />
        <hr />

        {membersDisplayElement()}
        <hr />

        <Button
          className="inline-show-result-button"
          size="sm"
          type="submit"
          onClick={clickAction}
        >
          Click Here to Select Parent Company
        </Button>
      </div>
    </div>
  );
}
