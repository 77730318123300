import React, { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { IsUnrestrictedEraRoute } from "../constants/EraPaths";

export const UserContext = createContext(null);

export const UserContextWrapper = ({ children }) => {
  //a function to determine if the user is logged in or not

  // function isUserAuthenticated() {
  //   return oktaAuth.authStateManager.getAuthState().isAuthenticated;
  // }

  //const [isAuthenticated, setIsAuthenticated] = useState(isUserAuthenticated());

  const [programs, setPrograms] = useState(null);
  const [organizations, setOrganizations] = useState(null);
  const [currentOrganization, setCurrentOrganization] = useState(null);
  const [locations, setLocations] = useState(null);
  const [recLocations, setRecLocations] = useState(null);
  const [metricLocations, setMetricLocations] = useState(null);
  const [ncpdps, setNcpdps] = useState(null);
  const [payers, setPayers] = useState(null);
  const [email, setEmail] = useState("");
  const [hasMetricSubscription, setHasMetricSubscription] = useState(null);
  const [hasRecSubscription, setHasRecSubscription] = useState(null);
  const [contactType, setContactType] = useState(null);

  const [isUnrestrictedEraPath, setIsUnrestrictedEraPath] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  // const [constants, setConstants] = useState(null);

  const location = useLocation();

  let userConfig = {
    programs: programs,
    onProgramsChange: (programs) => setPrograms(programs),
    organizations: organizations,
    onOrganizationsChange: (organizations) => setOrganizations(organizations),
    currentOrganization: currentOrganization,
    onCurrentOrganizationChange: (currentOrganization) =>
      setCurrentOrganization(currentOrganization),
    locations: locations,
    onLocationsChange: (locations) => {
      //alert("locations changed");
      setLocations(locations);
    },
    recLocations: recLocations,
    onRecLocationsChange: (recLocations) => setRecLocations(recLocations),
    metricLocations: metricLocations,
    onMetricLocationsChange: (metricLocations) =>
      setMetricLocations(metricLocations),
    //NOTE TODO: Are we actually using the "ncpdps" value anywhere? Or is it just "locations" that we're using? check this
    ncpdps: ncpdps,
    onNcpdpsChange: (ncpdps) => setNcpdps(ncpdps),
    payers: payers,
    onPayersChange: (payers) => setPayers(payers),
    email: email,
    onEmailChange: (authenticatedEmail) => {
      if (email == authenticatedEmail) return;

      //alert("email changed" + authenticatedEmail);

      setIsAuthenticated(true);
      setEmail(authenticatedEmail);
    },
    hasMetricSubscription: hasMetricSubscription,
    onHasMetricSubscriptionChange: (hasMetricSubscription) =>
      setHasMetricSubscription(hasMetricSubscription),
    hasRecSubscription: hasRecSubscription,
    onHasRecSubscriptionChange: (hasRecSubscription) =>
      setHasRecSubscription(hasRecSubscription),
    contactType: contactType,
    onContactTypeChange: (contactType) => setContactType(contactType),
    isUnrestrictedEraPath: isUnrestrictedEraPath,
    isAuthenticated: isAuthenticated,
  };

  useEffect(() => {
    let isCurrentRouteUnrestricted = IsUnrestrictedEraRoute(location.pathname);

    //set the state if the the reswtricted/unrestricted state of the current route
    if (isCurrentRouteUnrestricted !== isUnrestrictedEraPath) {
      setIsUnrestrictedEraPath(isCurrentRouteUnrestricted);
    }
  }, [location.pathname]);

  return (
    <UserContext.Provider value={userConfig}>{children}</UserContext.Provider>
  );
};
