import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";

import { useQuery } from "@tanstack/react-query";

import { IsNullOrEmpty } from "shared/helpers/NullEmptyHelpers";

export function useFetchLayoutUserLocations(
  isAuthenticated,
  email,
  currentOrganization,
  currentOrganizationType,
  currentRoute
) {
  const { oktaAuth } = useOktaAuth();

  const dataKey = "useFetchLayoutUserLocationsAccessKey";

  async function getAllLocations() {
    const accessToken = oktaAuth.getAccessToken();

    let allLocationsParameters = {
      email: email,
      selectedItem: currentOrganization,
      organizationType: currentOrganizationType,
    };

    // alert("calling getAllLocations");

    let header = {
      Authorization: `Bearer ${accessToken}`,
      RequestSrc: currentRoute,
    };

    var result = await axios
      .get("api/UserProgram/GetUserLocations", {
        params: allLocationsParameters,
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw new Error(error);
      });

    return result;
  }

  async function getRecLocations() {
    const accessToken = oktaAuth.getAccessToken();

    let recParameters = {
      email: email,
      selectedItem: currentOrganization,
      organizationType: currentOrganizationType,
      programSubscriptions: "Rec2.0",
    };

    let header = {
      Authorization: `Bearer ${accessToken}`,
      RequestSrc: currentRoute,
    };

    var result = await axios
      .get("api/UserProgram/GetUserLocations", {
        params: recParameters,
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw new Error(error);
      });

    return result;
  }

  async function getMetricLocations() {
    const accessToken = oktaAuth.getAccessToken();

    let metricParameters = {
      email: email,
      selectedItem: currentOrganization,
      organizationType: currentOrganizationType,
      programSubscriptions: "Metric2.0,Metric2.0 Premium",
    };

    let header = {
      Authorization: `Bearer ${accessToken}`,
      RequestSrc: currentRoute,
    };

    var result = await axios
      .get("api/UserProgram/GetUserLocations", {
        params: metricParameters,
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw new Error(error);
      });

    return result;
  }

  function IsEnabled() {
    if (!isAuthenticated) return false;

    if (IsNullOrEmpty(email)) return false;

    if (IsNullOrEmpty(currentOrganization)) return false;

    if (IsNullOrEmpty(currentOrganizationType)) return false;

    return true;
  }

  let { data: allLocationsData, isLoading: isLoadingAllLocations } = useQuery({
    queryKey: [
      dataKey,
      [
        "alllocations",
        email,
        currentOrganization,
        currentOrganizationType,
        currentRoute,
      ],
    ],
    queryFn: async () => await getAllLocations(),
    enabled: IsEnabled,
  });

  let { data: recLocationsData, isLoading: isLoadingRecLocations } = useQuery({
    queryKey: [
      dataKey,
      [
        "reclocations",
        email,
        currentOrganization,
        currentOrganizationType,
        currentRoute,
      ],
    ],
    queryFn: async () => await getRecLocations(),
    enabled: IsEnabled,
  });

  let { data: metricLocationsData, isLoading: isLoadingMetricLocations } =
    useQuery({
      queryKey: [
        dataKey,
        [
          "metriclocations",
          email,
          currentOrganization,
          currentOrganizationType,
          currentRoute,
        ],
      ],
      queryFn: async () => await getMetricLocations(),
      enabled: IsEnabled,
    });

  return {
    allLocationsData,
    recLocationsData,
    metricLocationsData,
    isLoading:
      isLoadingAllLocations ||
      isLoadingRecLocations ||
      isLoadingMetricLocations,
  };
}
