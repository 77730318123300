import React from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import SharedMainSelectableGrid, {
  AddGridButton,
} from "../../grid/Kendo/SharedMainSelectableGridNewImplementation";
import "font-awesome/css/font-awesome.min.css";
import axios from "axios";
import { Row, Col } from "react-bootstrap";

import { MoneyCell } from "../../grid/Kendo/CustomKendoGridCells";
import {
  DownloadFileByBatchId,
  CreateNewRequestForExistingBatch,
  CheckBatchDateOlderThan90Days,
} from "./BatchIdDownloadHelper";
import {
  DefaultColumnWidth,
  DefaultDateColumnWidth,
  DefaultBooleanOrShortWidthColumnWidth,
} from "../../grid/Kendo/KendoGridAndColumnConstants";

import {
  Base64ToArrayBuffer,
  SaveByteArray,
} from "shared/helpers/Base64ArrayTranslator";

export function RequestExportsDetailGrid(props) {
  const {
    data,
    user,
    getAccessToken,
    onFailure,
    setParentIsLoading,
    submittedFormData,
    submittedRpid,
    setErrorFromParent,
    getMainGridDataFromParent,
  } = props;

  let requestDataKey = "pmid";
  let batchIdKey = "batchId";
  let defaultColumnWidth = DefaultColumnWidth();

  const sendSelectablesToParent = async (selectablesFromSharedGrid) => {
    var getOnlyTrueKeys = selectablesFromSharedGrid;
    if (getOnlyTrueKeys.length < 1) {
      alert("No rows selected.");
      return null;
    }
    var allSelectedpmidCodes = [];
    getOnlyTrueKeys.forEach(function (key) {
      allSelectedpmidCodes.push(key);
    });

    var selectedChecks = data.filter((item) =>
      allSelectedpmidCodes.includes(item.pmid)
    );
    var selectedChecksWithPendingBatch = selectedChecks.filter(
      (item) => item.batchRequestStatus === "P" && item.batchId > 0
    );

    if (
      selectedChecksWithPendingBatch &&
      selectedChecksWithPendingBatch.length > 0
    ) {
      alert("Selected checks are already queued for processing.");
      return null;
    } else {
      setParentIsLoading(true);

      let accessToken = getAccessToken();

      axios
        .post(
          "api/RemittanceExport/CreateNewRequestForSelectedRemittances",
          {
            user: user,
            locations: submittedFormData.selectedLocations.join(","),
            rpid: submittedRpid,
            pmidstoExport: allSelectedpmidCodes,
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        )
        .then(handleSuccessNewRequestForSelectedRemittances)
        .catch(handleError);
    }
  };

  function GetGridButtons() {
    let buttonsArray = [];

    buttonsArray = AddGridButton(
      buttonsArray,
      "Build Selected",
      sendSelectablesToParent
    );

    return buttonsArray;
  }
  const gridButtons = GetGridButtons();

  const handleSuccessNewRequestForSelectedRemittances = (response) => {
    setParentIsLoading(false);

    if (response.status === 200) {
      setErrorFromParent({
        status: response.status,
        Message: response.data.response,
      });
    }

    getMainGridDataFromParent();
  };

  const onBatchIdCellClick = (dataItem) => {
    checkDateAndBatchStatusAndRebuildOrDownloadBatch(dataItem, false);
  };

  const checkDateAndBatchStatusAndRebuildOrDownloadBatch = (
    dataItem,
    isBatchSummary = false
  ) => {
    if (
      dataItem.batchRequestStatus === "D" ||
      dataItem.batchRequestStatus === "R"
    ) {
      if (CheckBatchDateOlderThan90Days(dataItem)) {
        let requestNewBuild = window.confirm(
          "Batch does not exist after 90 days.  Do you want to rebuild selected Batch?"
        );

        if (requestNewBuild) {
          let batchId = dataItem[batchIdKey];
          let accessToken = getAccessToken();
          let parameters = {
            user: user,
            batchId: batchId,
          };

          CreateNewRequestForExistingBatch(
            setParentIsLoading,
            handleSuccessNewRequestForRebuildingBatch,
            handleError,
            parameters,
            accessToken
          );
        }
      } else {
        let batchId = dataItem[batchIdKey];
        let accessToken = getAccessToken();
        let parameters = {
          user: user,
          batchId: batchId,
          isSummaryFile: isBatchSummary,
        };

        DownloadFileByBatchId(
          setParentIsLoading,
          handleSuccessGetFileByBatchId,
          handleError,
          parameters,
          accessToken
        );
      }
    } else {
      alert(
        "This batch request is still pending, the download is not ready yet."
      );
    }
  };

  const handleError = (error) => {
    onFailure(error);
  };

  const handleSuccessNewRequestForRebuildingBatch = (response) => {
    setParentIsLoading(false);
    setErrorFromParent({
      status: response.status,
      Message: response.data.response,
    });
    getMainGridDataFromParent();
  };

  const handleSuccessGetFileByBatchId = (response) => {
    setParentIsLoading(false);

    if (response.data) {
      var dataAsArray = Base64ToArrayBuffer(response.data.fileStream);
      SaveByteArray(response.data.fileName, dataAsArray);
    } else {
      alert("No file found for this batch, please contact Net-Rx support.");
    }

    getMainGridDataFromParent();
  };

  const BatchIdCell = (props) => {
    let batchId = props.dataItem[batchIdKey];

    if (batchId) {
      return (
        <td>
          <a
            onClick={() => onBatchIdCellClick(props.dataItem)}
            style={{
              color: "#015CAB",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            {props.children}
          </a>
        </td>
      );
    } else {
      return <td>{props.children}</td>;
    }
  };

  const onBatchSummaryCellClick = (dataItem) => {
    checkDateAndBatchStatusAndRebuildOrDownloadBatch(dataItem, true);
  };

  const BatchSummaryCell = (props, optionalValue = null) => {
    let datum = props.dataItem;
    let batchId = datum[batchIdKey];

    if (batchId) {
      return (
        <td>
          <a onClick={() => onBatchSummaryCellClick(props.dataItem)}>
            {datum.batchRequestStatus === "D" ||
            datum.batchRequestStatus === "R" ? (
              <i
                style={{ color: "green" }}
                className="fa fa-file-excel-o fa-lg"
              ></i>
            ) : null}
          </a>
        </td>
      );
    } else {
      return <td>{props.children}</td>;
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col className="tight-grid multi-line-filter">
          <h4>Export Remittances Detail</h4>
          <SharedMainSelectableGrid
            data={data}
            aggregateColumnSettings={null}
            sendAggregatesParentCallback={() => {}}
            dataItemKey={requestDataKey}
            styleOverride={{ height: "600px" }}
            gridButtons={gridButtons}
          >
            <GridColumn
              field={batchIdKey}
              title="Batch ID"
              width={DefaultBooleanOrShortWidthColumnWidth()}
              cells={{
                data: BatchIdCell,
              }}
            />
            <GridColumn
              field={null}
              title="Batch Summary"
              width={DefaultDateColumnWidth()}
              cells={{
                data: BatchSummaryCell,
              }}
            />
            <GridColumn
              field={"batchRequestStatusDescription"}
              title="Batch Status"
              width={defaultColumnWidth}
            />
            <GridColumn
              field="batchRequestDate"
              title="Batch Request Date"
              width={DefaultDateColumnWidth()}
              filter={"date"}
              format="{0:d}"
            />
            <GridColumn
              field="payerName"
              title="Payer Name"
              width={defaultColumnWidth}
            />
            <GridColumn
              field="checkNumber"
              title="Check Number"
              width={defaultColumnWidth}
            />
            <GridColumn
              field="checkDate"
              title="Check Date"
              width={DefaultDateColumnWidth()}
              filter={"date"}
              format="{0:d}"
            />
            <GridColumn
              field="remitDate"
              title="Remit Date"
              width={DefaultDateColumnWidth()}
              filter={"date"}
              format="{0:d}"
            />
            <GridColumn
              field="paymentDate"
              title="Payment Date"
              width={DefaultDateColumnWidth()}
              filter={"date"}
              format="{0:d}"
            />
            <GridColumn
              field="archiveDate"
              title="Archive Date"
              width={DefaultDateColumnWidth()}
              filter={"date"}
              format="{0:d}"
            />
            <GridColumn
              field="ncpdp"
              title="NCPDP"
              width={DefaultBooleanOrShortWidthColumnWidth()}
            />
            <GridColumn
              field="amount"
              title="Amount"
              width={defaultColumnWidth}
              cells={{ data: MoneyCell }}
            />
          </SharedMainSelectableGrid>
        </Col>
      </Row>
    </React.Fragment>
  );
}
