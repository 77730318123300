import React, { useContext, useEffect, useState, useRef } from "react";
import { withOktaAuth as useOktaAuth } from "@okta/okta-react";
import { useNavigate, useLocation } from "react-router-dom";

import { Container, Row, Col } from "react-bootstrap";

import { ScreenHeader } from "./ScreenHeader/ScreenHeader";
import { ContentHeader } from "./ContentHeader";
import { Footer } from "./Footer";
import { NavBar } from "./NavBar";

import { UserContext } from "core/context/UserContext";
import { AuthContext } from "core/context/AuthContext";
import { ConstantsContext } from "core/context/ConstantsContext";
import { getWithExpiry } from "shared/utils/localStorageAccess";
import { LiveChat } from "./LiveChat";
import { UserTypes } from "core/constants/UserTypes";
import { OrganizationTypes } from "core/constants/OrganizationTypes";
import { LoadingSpinner } from "./LoadingSpinner";
import { IsNullOrEmpty } from "shared/helpers/NullEmptyHelpers";

import { GetObjectPropertyValue } from "shared/helpers/DataHelpers";

import { useFetchLayoutConstants } from "./hooks/useFetchLayoutConstants";
import { useFetchLayoutMemberConfig } from "./hooks/useFetchLayoutMemberConfig";
import { useFetchLayoutUserLocations } from "./hooks/useFetchLayoutUserLocations";
import { useFetchLayoutPayers } from "./hooks/useFetchLayoutPayers";
import { useFetchLayoutUserSubscription } from "./hooks/useFetchLayoutUserSubscription";
import { useFetchLayoutLiveChatAccess } from "./hooks/useFetchLayoutLiveChatAccess";
import { useUpdateSisenseLocations } from "./hooks/useUpdateSisenseLocations";

export default useOktaAuth(function Layout(props) {
  const { oktaAuth, authState } = props;

  //This will be used to ensure we are not making too many unnecessary qauthengentication checks
  const lastAuthenticationCheckTimeRef = useRef(0);
  const limitAUthenticationCheckInterval = 10000; //10 seconds

  const userConfig = useContext(UserContext);
  //const [isAuthenticated, setIsAuthenticated] = useState(null);

  const { isAuthenticated } = useContext(UserContext);
  const [user, setUser] = useState(null);
  const [showLiveChat, setShowLiveChat] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const { setConstants } = useContext(ConstantsContext);

  const { data: constantsData } = useFetchLayoutConstants(isAuthenticated);

  const { data: memberConfigData } = useFetchLayoutMemberConfig(
    isAuthenticated,
    userConfig.email,
    userConfig.contactType
  );

  const { data: userSubscriptionData } = useFetchLayoutUserSubscription(
    isAuthenticated,
    userConfig.email
  );

  const { data: livechatAccessData } = useFetchLayoutLiveChatAccess(
    isAuthenticated,
    userConfig.email
  );

  const { data: payersData } = useFetchLayoutPayers(
    isAuthenticated,
    userConfig.email,
    GetObjectPropertyValue(userConfig.currentOrganization, "value"),
    GetObjectPropertyValue(userConfig.currentOrganization, "type"),
    location.pathname
  );

  const { allLocationsData, recLocationsData, metricLocationsData } =
    useFetchLayoutUserLocations(
      isAuthenticated,
      userConfig.email,
      GetObjectPropertyValue(userConfig.currentOrganization, "value"),
      GetObjectPropertyValue(userConfig.currentOrganization, "type"),
      location.pathname
    );

  //this will be called by the locations effect and has validations that locations data has changed before issuing the call
  const { updateSisenseLocations } = useUpdateSisenseLocations(
    isAuthenticated,
    userConfig.email,
    allLocationsData
  );

  async function checkAuthentication() {
    if (isAuthenticated) {
      //if we authenticated, we need to the last time we made a call to check authentication
      // then
      const currentTime = Date.now();
      if (
        currentTime - lastAuthenticationCheckTimeRef.current <
        limitAUthenticationCheckInterval
      ) {
        return;
      }

      lastAuthenticationCheckTimeRef.current = currentTime;
    }

    if (authState) {
      const fetchedIsAuthenticated = authState.isAuthenticated;

      if (fetchedIsAuthenticated !== isAuthenticated) {
        if (authState.accessToken) {
          const userInfo = await oktaAuth.getUser();
          userConfig.onEmailChange(userInfo.email);

          setUser(userInfo);
        } else {
          setUser(null);
        }
      }

      if (fetchedIsAuthenticated === false) {
        navigate("/Login");
      }
    }
  }

  function SetPageTitle() {
    document.title = props.pageTitle == undefined ? "RME" : props.pageTitle;
  }

  useEffect(() => {
    if (!constantsData) return;

    setConstants(constantsData);
  }, [constantsData]);

  useEffect(() => {
    if (!memberConfigData) return;

    //will wrap this in a function as there is some work being done here (formatting, setting local storage, making decision if nothing selected)
    function setMemberConfig() {
      const organizations = formatOrganizationDropdownOptions(memberConfigData);

      userConfig.onOrganizationsChange(organizations);

      if (IsNullOrEmpty(userConfig.currentOrganization)) {
        //we do not have a current organization, so we need to set one, either from the localstorage or the first one in the list

        //check if we have a cached config with the last location and if so set it
        const cachedConfig = getWithExpiry("rme-userConfig");

        if (!IsNullOrEmpty(cachedConfig)) {
          userConfig.onCurrentOrganizationChange(cachedConfig);
        } else {
          //if we have no cached config, then we need to set the first organization in the list
          userConfig.onCurrentOrganizationChange(organizations[0]);
        }
      }
    }

    setMemberConfig();
  }, [memberConfigData]);

  useEffect(() => {
    if (!payersData) return;

    let payers = formatDictionaryToArray(payersData);

    let sortedPayers = payers.sort((p1, p2) =>
      p1.label > p2.label ? 1 : p1.label < p2.label ? -1 : 0
    );

    userConfig.onPayersChange(sortedPayers);
  }, [payersData]);

  useEffect(() => {
    if (!allLocationsData) return;

    if (!IsNullOrEmpty(allLocationsData)) {
      let allLocations = formatDictionaryToArray(allLocationsData);
      let allNcpdps = formatDictionaryToNcpdps(allLocationsData);

      userConfig.onLocationsChange(allLocations);
      userConfig.onNcpdpsChange(allNcpdps);

      //make the call to update sisense
      updateSisenseLocations();
    }

    if (!IsNullOrEmpty(recLocationsData)) {
      let recLocations = formatDictionaryToArray(recLocationsData);
      userConfig.onRecLocationsChange(recLocations);
    }

    if (!IsNullOrEmpty(metricLocationsData)) {
      let metricLocations = formatDictionaryToArray(metricLocationsData);
      userConfig.onMetricLocationsChange(metricLocations);
    }
  }, [allLocationsData, recLocationsData, metricLocationsData]);

  useEffect(() => {
    if (IsNullOrEmpty(userSubscriptionData)) return;

    userConfig.onHasMetricSubscriptionChange(
      userSubscriptionData.isMetricSubscription
    );

    userConfig.onHasRecSubscriptionChange(
      userSubscriptionData.isRecSubscription
    );

    userConfig.onContactTypeChange(userSubscriptionData.contactType);
  }, [userSubscriptionData]);

  useEffect(() => {
    if (IsNullOrEmpty(livechatAccessData)) return;

    setShowLiveChat(livechatAccessData);
  }, [livechatAccessData]);

  useEffect(() => {
    checkAuthentication();
    SetPageTitle();
  });

  function formatDictionaryToArray(data) {
    return Object.keys(data).map((key) => {
      return {
        label: data[key],
        value: key,
      };
    });
  }

  function formatOrganizationDropdownOptions(data) {
    let mappedItems = [];

    if (data) {
      data.map((x) => {
        let item = {
          value: x.itemId,
          type: x.organizationType,
          labelNoSuffix: x.itemName,
        };

        if (userConfig.contactType === UserTypes.UCT) {
          if (x.organizationType === OrganizationTypes.Group) {
            item.label = x.itemName + " - G";
          } else if (x.organizationType === OrganizationTypes.ParentCompany) {
            item.label = x.itemName + " - P";
          } else {
            item.label = x.itemName;
          }
        } else {
          item.label = x.itemName;
        }

        mappedItems.push(item);
      });

      return mappedItems;
    }
  }

  //NOTE: This is for extracting the NCPDP from the label on the frontend. We know that NCPDPs are always 7 characters long, we know that the label always ends with ")"
  function formatDictionaryToNcpdps(data) {
    return Object.keys(data).map((key) => {
      return {
        label: data[key],
        value: data[key].substring(data[key].length - 8, data[key].length - 1),
      };
    });
  }

  if (oktaAuth.authenticated === null || !user) return null;

  const childrenWithProps = React.Children.map(props.children, (child) =>
    React.cloneElement(child, { passedProps: { auth: oktaAuth, user: user } })
  );

  return (
    <AuthContext.Provider value={oktaAuth}>
      <Container className="px-0 d-flex flex-column" fluid="true" id="content">
        <header>
          <ScreenHeader user={user} auth={oktaAuth} />
          <ContentHeader featureTitle={props.featureTitle} />
        </header>

        <Row className="flex-fill d-flex no-gutters flex-nowrap">
          {/* <NavBar label={props.selectedNavbarLabel} /> */}
          <NavBar auth={oktaAuth} />
          <Col className="overflow-auto">
            <LoadingSpinner
              isDataLoading={IsNullOrEmpty(userConfig.locations)}
              controlsName={"layoutSpinner"}
            />
            <Row className="d-flex no-gutters flex-column" id="main-content">
              {childrenWithProps}
            </Row>
            <Footer />
            {showLiveChat ? <LiveChat /> : ""}
          </Col>
        </Row>
      </Container>
    </AuthContext.Provider>
  );
});
