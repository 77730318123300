import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Form, Col, Row, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MemberLocationDropdown from "../../form/MemberLocationDropdown";

import FormValidation from "../../form/validation/FormValidation";
import { UserContext } from "core/context/UserContext";

export function AsOfAgingForm(props) {
  //NOTE TODO: Similar to the Date Range Aging tab, this input is intended to get some data for testing
  const [toDate, setToDate] = useState(new Date());
  // const [toDate, setToDate] = useState(new Date(2023, 5, 21))
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const userContext = useContext(UserContext);

  useEffect(() => {
    setLoaded(false);
  }, [
    userContext.locations,
    userContext.metricLocations,
    userContext.recLocations,
    userContext.isUnrestrictedEraPath,
  ]);

  useEffect(() => {
    if (!loaded && selectedLocations.length > 0) {
      setLoaded(true);
      submitForm();
    }
  }, [selectedLocations]);

  function submitForm() {
    let parameters = {
      toDate: toDate,
      selectedLocations: selectedLocations,
      requiredFields: ["toDate"],
    };

    let validationIssueMessage = FormValidation(parameters);

    if (validationIssueMessage) {
      alert(validationIssueMessage);
    } else {
      props.handleFormSubmit({
        toDate: toDate.toLocaleDateString(),
        mmids: selectedLocations.join(","),
      });
    }
  }

  function handleFormSubmit(e) {
    e.preventDefault();

    submitForm();
  }

  return (
    <Form onSubmit={handleFormSubmit}>
      <Row className="justify-content-start">
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Location (NCPDP)</Form.Label>
          <Form.Control
            as={MemberLocationDropdown}
            onChange={(locations) => setSelectedLocations(locations)}
            programSubscription={"Rec2.0"}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>End Date</Form.Label>
          <Form.Control
            name="toDate"
            as={DatePicker}
            selected={toDate}
            onChange={(date) => setToDate(date)}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          {/* <Form.Group>
          </Form.Group> */}
          <Button className="inline-show-result-button" size="sm" type="submit">
            Show Results
          </Button>
        </Col>
      </Row>

      <Row className="justify-content-start">
        <Col>&nbsp;</Col>
      </Row>
    </Form>
  );
}

AsOfAgingForm.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
};
