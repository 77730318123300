import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { redirect } from "react-router-dom";
import { Row, Card, CardGroup, Col } from "react-bootstrap";
import Dashboard from "../../SiSense/Dashboard";
import { Link } from "react-router-dom";
import { loadScript, isLoaded } from "../../SiSense/SiSenseScriptLoader";
import { UserContext } from "core/context/UserContext";

import PendingHomePageRemittances from "./PendingHomePageRemittances";
import { useParentCompanyLocationValidation } from "../../hooks/useParentCompanyLocationValidation";
import parse from "html-react-parser";

export default function Home(props) {
  const { passedProps } = props;
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const url = process.env.REACT_APP_SisenseURI + "/js/frame.js";

  const user = useContext(UserContext);
  const [filters, setFilters] = useState([]);
  const [newsData, setNewsDatasource] = useState([]);
  const [userSubscription, setUserSubscription] = useState([]);
  const [agingFilters, setAgingFilters] = useState([]);
  const [adjustmentTrackerFilters, setAdjustmentTrackerFilters] = useState([]);
  const { eraOnly, IsAllowedRoute } = useParentCompanyLocationValidation();

  useEffect(() => {
    if (isLoaded(url)) {
      setScriptLoaded(true);
    } else {
      loadScript(url)
        .then(() => {
          setScriptLoaded(true);
        })
        .catch((e) => console.log(e));
    }
  }, []);

  useEffect(() => {
    getUserSubscription();
    getNewsAndNETRxHappenings();
  }, []);

  useEffect(() => {
    setScriptLoaded(true);
    reloadDashboard();
  }, [user.metricLocations]);

  function reloadDashboard() {
    if (scriptLoaded) {
      const metricLocations =
        user.metricLocations != null && user.metricLocations.length > 0
          ? user.metricLocations
          : [{ value: -1 }];
      if (!eraOnly) {
        const agingFilters = createFilters(
          "[vwAging_Sisense.SecurityID]",
          metricLocations
        );
        setAgingFilters(agingFilters);
        // console.log("agingFilters", agingFilters);
        const adjustmentTrackerFilters = createFilters(
          "[vwFeeAdjustment_Sisense.SecurityID]",
          metricLocations
        );
        setAdjustmentTrackerFilters(adjustmentTrackerFilters);
      } else {
        setAgingFilters([]);
        setAdjustmentTrackerFilters([]);
      }
      // console.log("adjustmentTrackerFilters", adjustmentTrackerFilters);
      function createFilters(dim, metricLocations) {
        return [
          {
            jaql: {
              dim: dim,
              // datatype: "numeric",
              filter: {
                // "explicit": false,
                // "multiSelection": true,
                members: metricLocations.map((x) => x.value),
              },
            },
          },
        ];
      }
    }
  }

  function onUserSubscriptionSuccess(response) {
    setUserSubscription(response.data);
  }

  function onUserSubscriptionFailure(error) {
    setUserSubscription([]);
  }

  function onSuccess(response) {
    setNewsDatasource(response.data);
  }
  function onFailure(error) {
    setNewsDatasource([]);
  }

  function getNewsAndNETRxHappenings() {
    if (user.email == "") {
      return;
    }
    let accessToken = passedProps.auth.getAccessToken();
    try {
      let response = axios
        .get(`/api/Landing/GetNewsAndNETRxHappenings`, {
          params: {
            email: user.email,
          },
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(onSuccess)
        .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
    }
  }

  function getUserSubscription() {
    if (user.email === "") {
      return;
    }
    let accessToken = passedProps.auth.getAccessToken();
    try {
      let response = axios
        .get(`/api/UserProgram/GetUserSubscription`, {
          params: {
            email: user.email,
          },
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(onUserSubscriptionSuccess)
        .catch(onUserSubscriptionFailure);
    } catch (exception) {
      onFailure(exception);
    }
  }

  const Line = ({ sss }) => <hr style={{ height: 5 }}></hr>;

  const settings = {
    showToolbar: false,
    showLeftPane: false,
    showRightPane: false,
  };

  //NOTE: This will make it so that any time the user goes to the Home page, it will redirect to the "Byod" component
  //const redirectToByod = true;

  return (
    // redirectToByod ?
    //     <redirect to={{ pathname: 'Byod' }} /> :
    <React.Fragment>
      <div className="dashboard-padding-wrapper">
        <Row className="no-gutters dashboard-card-group h-50" as={CardGroup}>
          <Card className="dashboard-card">
            <Card.Body
              as={Link}
              to={userSubscription.isRecSubscription ? "/RemitLookUp" : "#"}
              className="remove-hyperLinkLine"
            >
              <Card.Title className="text-center add-hyperLinkLine">
                Pending Remittances
              </Card.Title>
              {userSubscription.isRecSubscription ? (
                <div className="dashboard-widget-wrapper">
                  <PendingHomePageRemittances passedProps={props} />
                  {/* <Dashboard
                    scriptLoaded={scriptLoaded}
                    iFrameId="sisense-remittance-processing-frame"
                    filters={filters}
                    dashboard={
                      process.env.REACT_APP_Dashboard_ReceiptVerification
                    }
                    widget={process.env.REACT_APP_Widget_ReceiptVerification}
                    settings={settings}
                    hidden={false}
                  /> */}
                </div>
              ) : (
                <div className="dashboard-widget-wrapper">
                  This feature is available with the purchase of RecRx, contact
                  sales@Net-Rx.com
                </div>
              )}
            </Card.Body>
          </Card>
          <Card className="dashboard-card" style={{ alignItems: "center" }}>
            <Card.Body
              as={Link}
              to={userSubscription.isRecSubscription ? "/Aging" : "#"}
              className="remove-hyperLinkLine"
            >
              <Card.Title className="text-center add-hyperLinkLine">
                Reconciliation and Aging Manager: Aging
              </Card.Title>
              {eraOnly ? (
                <div className="dashboard-widget-wrapper">
                  This feature has not been enabled for your account. Please
                  contact Net-Rx at 866-336-3879 to subscribe to this feature.
                </div>
              ) : userSubscription.isRecSubscription ? (
                <div
                  className="dashboard-widget-wrapper"
                  style={{ position: "absolute" }}
                >
                  <Dashboard
                    scriptLoaded={scriptLoaded}
                    iFrameId="sisense-aging-frame"
                    filters={agingFilters}
                    dashboard={process.env.REACT_APP_Dashboard_Aging}
                    widget={process.env.REACT_APP_Widget_Aging}
                    settings={settings}
                    hidden={false}
                    type="fullFeature"
                  />
                </div>
              ) : (
                <div className="dashboard-widget-wrapper">
                  This feature is available with the purchase of RecRx, contact
                  sales@Net-Rx.com
                </div>
              )}
            </Card.Body>
          </Card>
          <Card className="dashboard-card">
            <Card.Body
              as={Link}
              to={
                userSubscription.isRecSubscription
                  ? "/FeeAndAdjustmentResearch"
                  : "#"
              }
              className="remove-hyperLinkLine"
            >
              <Card.Title className="text-center add-hyperLinkLine">
                Fee & Adjustment Tracker - Previous Month
              </Card.Title>
              {eraOnly ? (
                <div className="dashboard-widget-wrapper">
                  This feature has not been enabled for your account. Please
                  contact Net-Rx at 866-336-3879 to subscribe to this feature.
                </div>
              ) : userSubscription.isRecSubscription ? (
                <div className="dashboard-widget-wrapper">
                  <Dashboard
                    scriptLoaded={scriptLoaded}
                    iFrameId="sisense-fee-and-adjustment-frame"
                    filters={adjustmentTrackerFilters}
                    dashboard={process.env.REACT_APP_Dashboard_Adjustments}
                    widget={process.env.REACT_APP_Widget_Adjustments}
                    settings={settings}
                    hidden={false}
                    type="fullFeature"
                  />
                </div>
              ) : (
                <div className="dashboard-widget-wrapper">
                  This feature is available with the purchase of RecRx, contact
                  sales@Net-Rx.com
                </div>
              )}
            </Card.Body>
          </Card>
        </Row>
        <Row className="no-gutters dashboard-card-group h-50" as={CardGroup}>
          <Card className="dashboard-card">
            <Card.Body>
              <Card.Title className="text-center add-hyperLinkLine">
                News and Alerts
              </Card.Title>
              <div className="newsBody">
                {newsData.map((data) => {
                  return data.NE_TYPE == "1" ? (
                    <div>
                      <Card.Title className="text-left">
                        {data.NE_Title}
                      </Card.Title>
                      <Card.Text>{parse(data.NE_Details)} </Card.Text>
                      <Line></Line>
                    </div>
                  ) : (
                    ""
                  );
                })}
              </div>
            </Card.Body>
          </Card>

          <Card className="dashboard-card">
            <Card.Body>
              <Card.Title className="text-center  add-hyperLinkLine">
                Net Rx happenings
              </Card.Title>
              <div className="newsBody">
                {newsData.map((data) => {
                  return data.NE_TYPE == "3" ? (
                    <div>
                      <Card.Title className="text-left">
                        {data.NE_Title}
                      </Card.Title>
                      <Card.Text>{parse(data.NE_Details)} </Card.Text>
                      <Line></Line>
                    </div>
                  ) : (
                    ""
                  );
                })}
              </div>
            </Card.Body>
          </Card>
        </Row>
      </div>
    </React.Fragment>
  );
}
