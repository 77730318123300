import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";

import { IsNullOrEmpty } from "shared/helpers/NullEmptyHelpers";

import { useQuery } from "@tanstack/react-query";

export function useFetchHomepageRemittanceData(selectedLocations) {
  const { oktaAuth } = useOktaAuth();

  const dataKey = "useFetchHomepageRemittanceDataResultKey";

  async function GetRemittanceSummary() {
    const accessToken = oktaAuth.getAccessToken();

    var result = await axios
      .get("api/RemittanceLookup/GetRemittanceSummary", {
        params: {
          locations: selectedLocations,
        },
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw new Error(error);
      });

    return result;
  }

  return useQuery({
    queryKey: [dataKey, selectedLocations],
    queryFn: async () => await GetRemittanceSummary(),
    enabled: !IsNullOrEmpty(selectedLocations),
  });
}
