import React from "react";
import axios from "axios";

import SharedMainSelectableGridNewImplementation, {
  AddGridButton,
} from "../../grid/Kendo/SharedMainSelectableGridNewImplementation";

import { GridColumn as Column } from "@progress/kendo-react-grid";
import "font-awesome/css/font-awesome.min.css";
import { Row, Col } from "react-bootstrap";

import {
  Base64ToArrayBuffer,
  SaveByteArray,
} from "shared/helpers/Base64ArrayTranslator";

import {
  DownloadFileByBatchId,
  CreateNewRequestForExistingBatch,
  CheckBatchDateOlderThan90Days,
} from "../835RemittanceExporting/BatchIdDownloadHelper";
import {
  DefaultColumnWidth,
  DefaultDateColumnWidth,
  DefaultBooleanOrShortWidthColumnWidth,
} from "../../grid/Kendo/KendoGridAndColumnConstants";
import { IsNullOrWhitespace } from "shared/helpers/StringHelpers";
import { StringToDate, ShortDateFormat } from "shared/helpers/DateHelpers";
import { IsExportBatchRequestReadyToDownload } from "shared/helpers/ExportBatchStatusHelper";

export function AdminExportBatchHistoryGrid(props) {
  const {
    data,
    user,
    getAccessToken,
    onFailure,
    setParentIsLoading,
    sendDetailsToParentCallback,
    sendSelectablesToParentCallback,
  } = props;

  const handleDeleteSelectedBatches = async (selectablesFromSharedGrid) => {
    sendSelectablesToParentCallback(selectablesFromSharedGrid);
  };

  function GetGridButtons() {
    let buttonsArray = [];

    buttonsArray = AddGridButton(
      buttonsArray,
      "Delete Selected Batches",
      handleDeleteSelectedBatches
    );

    return buttonsArray;
  }

  const gridButtons = GetGridButtons();

  const batchIdKey = "batchId";
  let defaultColumnWidth = DefaultColumnWidth();

  const onBatchIdCellClick = (dataItem) => {
    let batchId = dataItem[batchIdKey];

    checkDateAndBatchStatusAndRebuildOrDownloadBatch(dataItem, false);
    console.log(batchId);
  };

  const checkDateAndBatchStatusAndRebuildOrDownloadBatch = (
    dataItem,
    isBatchSummary = false
  ) => {
    if (IsExportBatchRequestReadyToDownload(dataItem.status)) {
      if (CheckBatchDateOlderThan90Days(dataItem)) {
        let requestNewBuild = window.confirm(
          "Batch does not exist after 90 days.  Do you want to rebuild selected Batch?"
        );

        if (requestNewBuild) {
          let batchId = dataItem[batchIdKey];
          let accessToken = getAccessToken();
          let parameters = {
            user: user,
            batchId: batchId,
          };

          CreateNewRequestForExistingBatch(
            setParentIsLoading,
            handleSuccessNewRequestForRebuildingBatch,
            onFailure,
            parameters,
            accessToken
          );
        }
      } else {
        let batchId = dataItem[batchIdKey];
        let accessToken = getAccessToken();
        let parameters = {
          user: user,
          batchId: batchId,
          isSummaryFile: isBatchSummary,
        };

        DownloadFileByBatchId(
          setParentIsLoading,
          handleSuccessGetFileByBatchId,
          onFailure,
          parameters,
          accessToken
        );
      }
    } else {
      alert(
        "This batch request is still pending, the download is not ready yet."
      );
    }
  };

  const handleSuccessNewRequestForRebuildingBatch = (response) => {
    setParentIsLoading(false);
    console.log(response);
  };

  const handleSuccessGetFileByBatchId = (response) => {
    setParentIsLoading(false);
    console.log(response);

    if (response.data) {
      var dataAsArray = Base64ToArrayBuffer(response.data.fileStream);
      SaveByteArray(response.data.fileName, dataAsArray);
    }
  };

  const onBatchSummaryCellClick = (dataItem) => {
    let batchId = dataItem[batchIdKey];

    checkDateAndBatchStatusAndRebuildOrDownloadBatch(dataItem, true);
    console.log(batchId);
  };

  const BatchSummaryCell = (props, optionalValue = null) => {
    let datum = props.dataItem;
    let batchId = datum[batchIdKey];

    if (batchId) {
      return (
        <td>
          <a onClick={() => onBatchSummaryCellClick(props.dataItem)}>
            {IsExportBatchRequestReadyToDownload(datum.status) ? (
              <i style={{ color: "green" }} className="fa fa-file-excel-o"></i>
            ) : null}
          </a>
        </td>
      );
    } else {
      return <td>{props.children}</td>;
    }
  };

  const BatchIdCell = (props) => {
    let batchId = props.dataItem[batchIdKey];

    if (batchId) {
      return (
        <td>
          <a
            onClick={() => onBatchIdCellClick(props.dataItem)}
            style={{
              color: "#015CAB",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            {props.children}
          </a>
        </td>
      );
    } else {
      return <td>{props.children}</td>;
    }
  };

  const onBatchViewDetailsClick = (batchRequest) => {
    let accessToken = getAccessToken();

    let apiParameters = {
      batchId: batchRequest[batchIdKey],
      user: user,
    };

    setParentIsLoading(true);

    axios
      .get("api/RemittanceExport/GetBatchDetails", {
        params: apiParameters,
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then(handleSuccessGetBatchDetails)
      .catch(onFailure);
  };

  const handleSuccessGetBatchDetails = (response) => {
    setParentIsLoading(false);

    if (response.data.length > 0) {
      let dataWithCleanedDates = response.data.map((t) => {
        return {
          ...t,
          checkDate: IsNullOrWhitespace(t.checkDate)
            ? ""
            : ShortDateFormat(t.checkDate),
          remitDate: IsNullOrWhitespace(t.remitDate)
            ? ""
            : ShortDateFormat(t.remitDate),
          paymentDate: IsNullOrWhitespace(t.paymentDate)
            ? ""
            : ShortDateFormat(t.paymentDate),
          archiveDate: IsNullOrWhitespace(t.archiveDate)
            ? ""
            : ShortDateFormat(t.archiveDate),
        };
      });

      sendDetailsToParentCallback(dataWithCleanedDates);
    } else {
      onFailure({ status: 201, Message: "No data found for given parameters" });
    }
  };

  const BatchViewDetailsCell = (props) => {
    let batchId = props.dataItem[batchIdKey];

    if (batchId) {
      return (
        <td>
          <a onClick={() => onBatchViewDetailsClick(props.dataItem)}>
            <i style={{ color: "#015CAB" }} className="fa fa-search"></i>
          </a>
        </td>
      );
    } else {
      return <td>{props.children}</td>;
    }
  };

  return (
    <Row>
      <Col className="tight-grid multi-line-filter">
        <h4>Export Batch History Detail</h4>
        <SharedMainSelectableGridNewImplementation
          data={data}
          aggregateColumnSettings={null}
          sendAggregatesParentCallback={() => {}}
          sendSelectablesParentCallback={() => {}}
          dataItemKey={batchIdKey}
          styleOverride={{ height: "600px" }}
          gridButtons={gridButtons}
        >
          <Column
            field="batchId"
            title="Batch ID"
            width={DefaultBooleanOrShortWidthColumnWidth()}
            cells={{
              data: BatchIdCell,
            }}
          />
          <Column
            field="batchSummary"
            title="Batch Summary"
            width={125}
            cells={{
              data: BatchSummaryCell,
            }}
          />
          <Column
            field="batchCheckDetails"
            title="View Details"
            width={DefaultBooleanOrShortWidthColumnWidth()}
            cells={{
              data: BatchViewDetailsCell,
            }}
          />
          <Column
            field="parentCompanyName"
            title="Parent Company"
            width={defaultColumnWidth}
          />
          <Column
            field="batchRequestDate"
            title="Batch Request Date"
            width={DefaultDateColumnWidth()}
          />
          <Column
            field="batchCreationDate"
            title="Batch Creation Date"
            width={DefaultDateColumnWidth()}
          />
          <Column field="status" title="Status" width={defaultColumnWidth} />
          <Column
            field="requestedByUser"
            title="Requested By"
            width={defaultColumnWidth}
          />
          <Column
            field="downloadedByUser"
            title="Downloaded By"
            width={defaultColumnWidth}
          />
        </SharedMainSelectableGridNewImplementation>
      </Col>
    </Row>
  );
}
