import React, { useState, useEffect, useContext } from "react";
import { DetailsModal } from "../../grid/DetailsModal";
import { UserContext } from "core/context/UserContext";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Upload } from "@progress/kendo-react-upload";
import Card from "react-bootstrap/Card";

import MissingRemittanceDownloadAttachment from "./MissingRemittanceDownloadAttachment.js";

import DatePicker from "react-datepicker";

import { Form, Row, Col, Button } from "react-bootstrap";

import { LoadingSpinner } from "../../layout/LoadingSpinner";
import ErrorHandler from "../../core/ErrorHandler";
import axios from "axios";
import "./MissingRemittanceModal.css";

import { HasSpecialCharacters } from "shared/helpers/StringHelpers";

import { ProgramSubscriptions } from "core/constants/ProgramSubscriptions";
import useUserLocations from "../../hooks/useUserLocations";

import { DateWithoutTime } from "shared/helpers/DateHelpers";
import { useNavigate } from "react-router-dom";

export default function MissingRemittanceAddEditModal(props) {
  const {
    show,
    onClose,
    auth,
    setStatusUpdated,
    missingRemittanceEditId,
    missingRemittanceEditItemData,
  } = props;

  const userContext = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  const [selectedPayer, setSelectedPayer] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);

  const { userLocations } = useUserLocations(ProgramSubscriptions.Rec);

  const [checkDate, setCheckDate] = useState(new Date());

  const [thisModalTitle, setThisModalTitle] = useState([]);

  const [uploadedAttachmentFile, setUploadedAttachmentFile] = useState();

  const [affectedFiles, setAffectedFiles] = useState([]);

  //These are API paths for the file upload and remove
  const saveUrl = "api/MissingRemittances/SaveAttachmentFile";
  const removeUrl = "api/MissingRemittances/RemoveAttachmentFile";

  const [amount, setAmount] = useState("");

  const [checkNumber, setCheckNumber] = useState("");
  const [isCentralPay, setIsCentralPay] = useState(false);

  const [allMemberPayers, setAllMemberPayers] = useState([]);
  const [userLocationPayers, setUserLocationPayers] = useState([]);

  const [error, setError] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setError({});
  }, [onClose]);

  useEffect(() => {
    GetMissingRemittancePayers();
  }, [
    userContext.currentOrganization.value,
    userContext.isUnrestrictedEraPath,
  ]);

  useEffect(() => {
    if (GetMissingRemittanceId() > 0) {
      setThisModalTitle("Edit Missing Remittance");
    } else {
      setThisModalTitle("New Missing Remittance");
    }
  }, [missingRemittanceEditId]);

  useEffect(() => {
    if (GetMissingRemittanceId() < 0 || !missingRemittanceEditItemData) {
      setSelectedLocation([]);
      setSelectedPayer([]);
      setCheckNumber("");
      setAmount("");
      setCheckDate(DateWithoutTime(new Date()));
      setIsCentralPay(false);
      setUploadedAttachmentFile(null);
      setUserLocationPayers([]);

      return;
    }

    //find the location in the list of locations where label value contains the missingRemittanceEditItemData.ncpdp number
    let location = userLocations.find((l) =>
      l.label.includes("(" + missingRemittanceEditItemData.ncpdp + ")")
    );

    if (location) {
      setSelectedLocation(location);
      SetPayersDropdownPerSelectedMember(location);

      let payer = userLocationPayers.find(
        (p) => p.label === missingRemittanceEditItemData.payer
      );

      if (payer) setSelectedPayer(payer);
    } else {
      setSelectedLocation([]);
      setSelectedPayer([]);
      setUserLocationPayers([]);
    }

    //find the payer in the list of payers where label value is equal to missingRemittanceEditItemData.payer

    setCheckNumber(missingRemittanceEditItemData.checkNumber);
    setAmount(missingRemittanceEditItemData.checkAmount);
    setCheckDate(DateWithoutTime(missingRemittanceEditItemData.checkDate));
    setIsCentralPay(missingRemittanceEditItemData.centralPay);
    setUploadedAttachmentFile(missingRemittanceEditItemData.attachedFileName);
  }, [missingRemittanceEditItemData, show]);

  useEffect(() => {
    if (!uploadedAttachmentFile) {
      RemoveFileAttachment();
    }
  }, [uploadedAttachmentFile]);

  useEffect(() => {
    if (!show) return;

    if (!allMemberPayers || allMemberPayers.length === 0) return;
    //alert("1");

    if (
      !selectedLocation ||
      !selectedLocation.value ||
      selectedLocation.value.length === 0
    ) {
      return;
    }

    if (!selectedPayer || !selectedPayer.id || selectedPayer.id.length === 0) {
      return;
    }

    //If we have a selected payer that is not in the list of payers for the selected location, then set the selected payer to null
    if (!userLocationPayers.find((x) => x.id == selectedPayer.id)) {
      setSelectedPayer(null);
    }

    // console.log("selectedPayer", selectedPayer);
    // console.log("userLocationPayers", userLocationPayers);

    // alert("proceed");
  }, [userLocationPayers]);

  //Adding this useEffect to set the selected payer because on the initial load, the payers were not beinng set even thoough missingRemittanceEditItemData.payer had a value
  useEffect(() => {
    if (!show) return;
    let payer = userLocationPayers.find(
      (p) => p.label === missingRemittanceEditItemData.payer
    );

    if (payer) setSelectedPayer(payer);
  }, [userLocationPayers, missingRemittanceEditItemData.payer]);

  function onFailure(error) {
    console.log(error);
    setIsLoading(false);

    switch (error.response.config.url) {
      case "api/MissingRemittances/RemittanceUpsert":
        if (error.response.status === 409) {
          if (
            error.response.data.message !== null &&
            error.response.data.pmid == null &&
            error.response.data.navigate !== null
          ) {
            let userWantsToViewRequest = window.confirm(
              error.response.data.message
            );
            if (userWantsToViewRequest) {
              onClose();
              navigate(
                "/MissingRemittances?tab=" + error.response.data.navigate,
                {}
              );
              break;
            } else {
              break;
            }
          }
          if (
            error.response.data.message !== null &&
            error.response.data.pmid !== null &&
            error.response.data.pmid !== undefined
          ) {
            let userWantsToViewCheck = window.confirm(
              error.response.data.message
            );
            let pmid = error.response.data.pmid;

            if (userWantsToViewCheck) {
              alert("Navigating to Remittance Management page...");
              navigate("/RemitLookUp?pmid=" + pmid, {});
            } else {
              // alert("User canceled.");
            }
          } else {
            alert("An error occurred. Unable to complete action.");
          }
        }
        break;
      default:
        alert("An error occurred. Unable to complete action.");
        break;
    }
  }

  function validateForm() {
    // location (NCPDP) is a required field
    if (
      !selectedLocation ||
      !selectedLocation.value ||
      selectedLocation.value.length === 0
    )
      return "Location (NCPDP) is a required field";

    // Payer is a required field
    if (!selectedPayer || !selectedPayer.id || selectedPayer.id.length === 0) {
      return "Payer is a required field";
    }

    //checklnumber is a required field, can have numbers and letters and a - character, and ncannot be over 50 characters in ;length
    if (checkNumber.length === 0) return "Check Number is a required field";
    if (checkNumber.length > 50)
      return "Check Number cannot be over 50 characters in length";
    if (HasSpecialCharacters(checkNumber))
      return "Check Number cannot contain special characters";
    if (checkNumber.length < 3) {
      // check if value is at least 3 characters long
      return "Check amount should be at least 3 characters long";
    }

    //amount is a required field, must be numeric
    if (amount.length === 0) return "Amount is a required field";
    if (isNaN(amount)) return "Amount must be a numeric value";

    //make sure check date is not empty, is a date, is not in the future, and is not more than a year old
    if (checkDate === null) return "Check Date is a required field";
    if (isNaN(checkDate)) return "Check Date is not a valid date";

    if (DateWithoutTime(checkDate) > DateWithoutTime(new Date()))
      return "Check Date cannot be in the future";
    if (
      checkDate < new Date(new Date().setFullYear(new Date().getFullYear() - 1))
    )
      return "We are unable to process this request as the check date is over one year old. Please contact the payer directly for remittance detail";

    return null;
  }

  function handleFormSubmit(e) {
    e.preventDefault();

    let errors = validateForm();

    if (errors) {
      alert(errors);
    } else {
      setIsLoading(true);
      postUpsert();
    }
  }

  //reset all states after update in case the user wants to add another record
  function ResetStatesAfterUpdate() {
    setIsLoading(false);

    setSelectedLocation([]);
    setSelectedPayer([]);
    setCheckDate(DateWithoutTime(new Date()));
    setAmount("");
    setCheckNumber("");
    setIsCentralPay(false);
    setError({});
    setUploadedAttachmentFile(null);
  }

  function onUserLocationPayersResponseSuccess(response) {
    let data = response.data;

    setAllMemberPayers(data);

    // console.log("onUserLocationPayersResponseSuccess", JSON.stringify(data));

    // alert(`onUserLocationPayersResponseSuccess: ${JSON.stringify(data)}`);

    // setUserLocationPayers([]);
    //setUserLocationPayers(data);
  }

  function GetMissingRemittancePayers() {
    try {
      let accessToken = auth.getAccessToken();

      let parameters = {
        user: userContext.email,
        groupOrParentCompanyId: userContext.currentOrganization.value,
        organizationType: userContext.currentOrganization.type,
      };

      axios
        .get("api/MissingRemittances/GetUserLocationsPayers", {
          params: parameters,
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(onUserLocationPayersResponseSuccess)
        .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
    }
  }

  function OnUpdateSuccess() {
    //setIsLoading(false);

    var randomString = Math.random().toString(36).substring(7);
    setStatusUpdated(randomString);

    ResetStatesAfterUpdate();

    onClose();

    //alert("Record added successfully");
    //generate a random string
  }

  function GetMissingRemittanceId() {
    if (missingRemittanceEditId && missingRemittanceEditId > 0)
      return missingRemittanceEditId;

    return -1;
  }

  const onFileUpload = (event) => {
    setAffectedFiles(event.affectedFiles);

    try {
      let accessToken = auth.getAccessToken();

      let parameters = {
        missingRemittanceId: GetMissingRemittanceId(),
      };

      axios
        .get("api/MissingRemittances/GetAttachmentDetail", {
          params: parameters,
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(() => {
          //upload succeeded, now get the filename from the event, and set the attachment file state
          let uploadedFilename = event.affectedFiles[0].name;
          setUploadedAttachmentFile(uploadedFilename);
        })
        .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
    }
  };

  //this is the API ca;ll to remove the file attachment
  async function RemoveFileAttachment() {
    let accessToken = auth.getAccessToken();

    await axios.post("api/MissingRemittances/RemoveAttachmentFile", null, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  async function postUpsert() {
    let missingRemittanceId = GetMissingRemittanceId();
    let request = {
      missingRemittanceId: missingRemittanceId,
      user: userContext.email,
      memberid: selectedLocation.value,
      payerid: selectedPayer.id,
      centralpay: isCentralPay,
      checknumber: checkNumber,
      checkdate: checkDate,
      checkamount: amount,
      attachfilename: uploadedAttachmentFile,
      //get ncpdp from the selected location label
      ncpdp: selectedLocation.label.match(/\(([0-9)]+)\)/)[1],
    };
    let accessToken = auth.getAccessToken();
    try {
      await axios
        .post("api/MissingRemittances/RemittanceUpsert", request, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(() => {
          OnUpdateSuccess();
        })
        .catch((error) => {
          onFailure(error);
        });
    } catch (error) {
      onFailure(error);
    }
  }

  function saveHeaders() {
    let accessToken = auth.getAccessToken();

    return {
      Authorization: `Bearer ${accessToken}`,
      "Access-Control-Allow-Origin": "*", // Allow all origins
    };
  }

  function onDownloadAttachmentFailure(error) {
    let msg = `Unable to download remittance attachment file {${uploadedAttachmentFile}}.`;

    alert(msg);
  }

  function handleDownloadAttachment() {
    MissingRemittanceDownloadAttachment(
      GetMissingRemittanceId(),
      uploadedAttachmentFile,
      auth
    );
  }

  function handleRemoveAttachment() {
    setUploadedAttachmentFile(null);
  }

  function SetPayersDropdownPerSelectedMember(selectedMember) {
    if (
      !selectedMember ||
      !selectedMember.value ||
      selectedMember.value.length === 0
    ) {
      setUserLocationPayers([]);
      return;
    }

    // alert(
    //   "SetPayersDropdownPerSelectedMember: " + JSON.stringify(selectedMember)
    // );

    // alert("allMemberPayers: " + JSON.stringify(allMemberPayers));

    let selectedMemberPayers = [];

    allMemberPayers.forEach((p) => {
      if (p.memberMmid == selectedMember.value) {
        //if the payer is not alreaddy in the list of payers, then add it
        if (!selectedMemberPayers.find((x) => x.id == p.payerId))
          selectedMemberPayers.push({ id: p.payerId, label: p.payerName });
      }
    });

    setUserLocationPayers(selectedMemberPayers);
  }

  return (
    <DetailsModal
      overrideClass="grid-details-modal2"
      title={thisModalTitle}
      show={show}
      handleClose={onClose}
    >
      <LoadingSpinner
        isDataLoading={isLoading}
        controlsName={"MissingRemittanceStatusHistoryModal"}
      />
      <ErrorHandler
        error={error}
        onClose={() => {
          setError({});
        }}
      />

      <div>
        <Form onSubmit={handleFormSubmit}>
          <Row className="justify-content-start">
            <Col md="auto" className="d-flex flex-column">
              <Form.Label>Location (NCPDP)</Form.Label>
              <Form.Control
                as={ComboBox}
                data={userLocations || []}
                id="value"
                textField="label"
                allowCustom={false}
                style={{
                  width: "30em",
                  textSizeAdjust: "small",
                }}
                value={selectedLocation}
                onChange={(e) => {
                  SetPayersDropdownPerSelectedMember(e.target.value);

                  if (e.target.value) {
                    // alert(
                    //   "Location Changed To: " + JSON.stringify(e.target.value)
                    // );
                    setSelectedLocation(e.target.value);
                  } else {
                    setSelectedLocation(null);
                    setSelectedPayer(null);
                  }
                }}
              />
            </Col>
          </Row>

          <Row className="justify-content-start  mt-2">
            <Col md="auto" className="d-flex flex-column">
              <span className="addNewRemittanceTextInfoSpanStyle">
                NOTE: If the Payer is not on this list, please contact Net-Rx
                support for assistance.
              </span>
              <Form.Label>Payer</Form.Label>

              <Form.Control
                as={ComboBox}
                //data={userContext.payers}

                data={userLocationPayers}
                id="id"
                textField="label"
                allowCustom={false}
                style={{
                  width: "30em",
                  textSizeAdjust: "small",
                }}
                value={selectedPayer}
                onChange={(e) => {
                  if (e.target.value) {
                    let thisSelectedPayer = e.target.value;

                    setSelectedPayer(thisSelectedPayer);
                  } else setSelectedPayer(null);
                }}
              />
            </Col>
          </Row>

          <Row className="justify-content-start  mt-2">
            <Col md="auto" className="d-flex flex-column">
              <Form.Label>Check Number</Form.Label>
              <Form.Control
                type="text"
                maxLength="20"
                onChange={(e) => {
                  setCheckNumber(e.target.value);
                }}
                value={checkNumber}
              />
            </Col>
          </Row>

          <Row className="justify-content-start mt-3">
            <Col md="auto">
              <span className="addNewRemittanceTextInfoSpanStyle">
                Check only if payment is from a Central Pay partner
              </span>

              <Form.Check
                type="checkbox"
                id="lookup3"
                label="Central Pay"
                checked={isCentralPay}
                onChange={(e) => {
                  setIsCentralPay(e.target.checked);
                }}
              />
            </Col>
          </Row>

          <Row className="justify-content-start mt-2">
            <Col md="auto" className="d-flex flex-column">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="text"
                maxLength="20"
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
                value={amount}
              />
            </Col>
          </Row>

          <Row className="justify-content-start mt-2">
            <Col md="auto" className="d-flex flex-column">
              <Form.Label>Check Date</Form.Label>
              <Form.Control
                name="checkDate"
                as={DatePicker}
                selected={checkDate}
                onChange={(date) => {
                  setCheckDate(date);
                }}
                value={checkDate}
              />
            </Col>
          </Row>

          <Row className="justify-content-start mt-4">
            <Col md="auto" className="d-flex flex-column">
              <Card className="rounded size-to-content">
                <Card.Body>
                  <Card.Title>Attachments</Card.Title>

                  <Upload
                    defaultFiles={[]}
                    multiple={false}
                    withCredentials={false}
                    saveUrl={saveUrl}
                    removeUrl={removeUrl}
                    onAdd={onFileUpload}
                    onBeforeRemove={(event) => {
                      event.headers = {
                        ...event.headers,
                        ...saveHeaders(), // Add the custom headers to the request
                      };
                    }}
                    onBeforeUpload={(event) => {
                      event.headers = {
                        ...event.headers,
                        ...saveHeaders(), // Add the custom headers to the request
                      };
                    }}
                  />

                  {uploadedAttachmentFile &&
                    uploadedAttachmentFile.length > 0 && (
                      <div>
                        <Button
                          className="btn-primary"
                          size="sm"
                          onClick={handleDownloadAttachment}
                        >
                          <i className="bi bi-download"></i> Download
                        </Button>

                        <span> </span>

                        <Button
                          className="btn-danger"
                          size="sm"
                          onClick={() => {
                            if (window.confirm("Are you sure?")) {
                              handleRemoveAttachment();
                            }
                          }}
                        >
                          <i className="bi bi-trash"></i> Remove
                        </Button>
                      </div>
                    )}
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="justify-content-start mt-5">
            <Col md="auto" className="d-flex flex-column ">
              <Button className="btn-secondary" size="sm" type="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </DetailsModal>
  );
}
